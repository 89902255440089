/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected formDefinition.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass formDefinition context to the rest of the app.
 */
import FormDefinitionByCodeContext from '../../../contexts/configuration/FormDefinitionContext';

/*
 * Used to load master groups
 */
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';
import LookupsContext from '../../../contexts/utility/LookupsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormDefinitionByCodeResolverProps {
    code: string,
    resolveBeforeLoad?: boolean | null;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormDefinitionByCodeResolver: FunctionComponent<IFormDefinitionByCodeResolverProps> = ({
    code,
    resolveBeforeLoad,
    children
}) => {
    const context = useFormDefinitionByCode(code);

    const lookupsContext = useLookupsByFormDefinitionId(context?.data?.id);

    const notLoading =
        !context?.loading &&
        !lookupsContext?.loading;

    return (
        <FormDefinitionByCodeContext.Provider value={context}>
            <LookupsContext.Provider value={lookupsContext}>
                {
                    !resolveBeforeLoad || notLoading ?
                        children :
                        <RouteLoading />
                }
            </LookupsContext.Provider>
        </FormDefinitionByCodeContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormDefinitionByCodeResolver;