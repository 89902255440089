import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { LookupCollectionResponse, LookupGetCollectionByFormDefinitionId } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';
import { Forms } from '../../Forms';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, formDefinitionId: number) => {
    const response = await forms.serviceStackClient.get(new LookupGetCollectionByFormDefinitionId({ formDefinitionId }));

    if (!response.lookups) {
        response.lookups = null as any;
    }

    return response;
}

const useLookupsByFormDefinitionId = (formDefinitionId?: number, configuration?: SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<LookupCollectionResponse, LookupCollectionResponse>(formDefinitionId ? [`${forms.baseRoute}/lookups/collection/by-form-definition/id`, forms, formDefinitionId] : null, fetcher, configuration);

    const boundMutate: BoundMutator<LookupCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/lookups/collection/by-form-definition/id`, forms, formDefinitionId], data, shouldRevalidate);
    }, [forms, formDefinitionId])

    const result = useMemo(() => {
        return {
            data: data?.lookups,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useLookupsByFormDefinitionId;