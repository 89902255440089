/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useContext, useMemo } from 'react';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { faCircle, faCommentAltLines, faMinusCircle, faQuestionCircle, faTimesCircle, faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import MedicalReviewDefinitionContext from '../../contexts/configuration/MedicalReviewDefinitionContext';
import { MedicalReviewStatus } from '../../api/dtos';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { DateTime } from 'luxon';
import { Breadcrumbs, IBreadcrumb, PatientContext } from '@ngt/forms-trials';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientBreadcrumbs component.
 */
export interface IContextMedicalReviewQueriesBreadcrumbsProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ContextMedicalReviewQueriesBreadcrumbs: React.FunctionComponent<IContextMedicalReviewQueriesBreadcrumbsProps> = () => {
    const { data: patient } = useContext(PatientContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: medicalReviewDefinition } = useContext(MedicalReviewDefinitionContext);
    const { createPatientRouteFn, createMedicalReviewRouteFn, createMedicalReviewQueriesRouteFn } = useContext(MedicalReviewExtensionContext);

    const breadcrumbs = useMemo(() => {
        const b: IBreadcrumb[] = [];

        if (patient) {
            b.push({
                icon: faUserCircle,
                label: patient.studyNumber,
                to: createPatientRouteFn(patient)
            });
        }
        else {
            b.push({
                icon: faUserCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        if (patient && medicalReview && medicalReviewDefinition) {
            let icon = faQuestionCircle;

            switch (medicalReview.status) {
                case MedicalReviewStatus.New:
                    icon = faCircle;
                    break;
                case MedicalReviewStatus.InProgress:
                    icon = faMinusCircle;
                    break;
                case MedicalReviewStatus.Complete:
                    icon = faCheckCircle;
                    break;
                case MedicalReviewStatus.Cancelled:
                    icon = faTimesCircle;
                    break;
                default:
                    icon = faQuestionCircle;
                    break;
            }

            b.push({
                icon: icon,
                label: <>{medicalReviewDefinition.name}&nbsp;<em>({DateTime.fromISO(medicalReview.enteredDate!).toFormat('dd/MM/yyyy')}{(medicalReview.status === MedicalReviewStatus.Complete ? <> &ndash; {DateTime.fromISO(medicalReview.modifiedDate!).toFormat('dd/MM/yyyy')}</> : null)})</em></>,
                to: createMedicalReviewRouteFn(patient, medicalReview)
            });
        }
        else {
            b.push({
                icon: faQuestionCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        if (patient && medicalReview) {
            b.push({
                icon: faCommentAltLines,
                label: <>Query Summary</>,
                to: createMedicalReviewQueriesRouteFn(patient, medicalReview)
            });
        }
        else {
            b.push({
                icon: faCommentAltLines,
                label: <>Query Summary</>,
                to: '#'
            });
        }

        return b;
    }, [patient, medicalReviewDefinition, medicalReview, createPatientRouteFn, createMedicalReviewRouteFn, createMedicalReviewQueriesRouteFn])

    return (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextMedicalReviewQueriesBreadcrumbs;