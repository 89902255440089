/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@mui/material/styles';

import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';

import LabelContext from '../../../contexts/form/LabelContext';
import Markdown from '../../utility/Markdown';
import FormOptionsContext from '../../../contexts/form/FormOptionsContext';
import { FormControl, FormControlProps, FormHelperText } from '@mui/material';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type CheckboxsProps = Partial<Omit<MuiCheckboxProps, 'type'>> & IInputRenderProps<boolean, Dtos.IValidationError>

export interface ICheckboxProps extends CheckboxsProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    label: React.ReactElement;
    margin?: FormControlProps['margin'];
    fullWidth?: FormControlProps['fullWidth'];
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Checkbox: React.FunctionComponent<ICheckboxProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, touch, focus: onFocus, ...restInputActions } },
    label,
    onChange,
    inputProps,
    disabled,
    hasErrors,
    errors,
    margin,
    fullWidth,
    ...rest
}) => {
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onChange) {
            onChange(event, checked);
        }

        onInputChange(checked, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
        touch();
    }, [inputProps?.onBlur, onBlur]);

    return (

        <FormControl
            error={hasErrors}
            margin={margin}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            disabled={disabled}
        >
            <FormControlLabel
                control={
                    <MuiCheckbox
                        {...rest as any}
                        inputProps={{
                            ...inputProps,
                            onBlur: onBlurCombined,
                            onFocus: onFocusCombined
                        }}
                        name={name}
                        checked={value ?? false}
                        onChange={onChangeCombined}
                        disabled={disabled}
                        color={rest?.color ? rest?.color : 'primary'}
                    />
                }
                label={label ?? <></>}
            />
            {
                errors && errors.length > 0 && (
                    <FormHelperText>
                        {errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Checkbox;
