/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */
import { IFormsExtension, IFormDefinition, IValidationResult, IUserDto, Forms } from '@ngt/forms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import MedicalReviewExtensionContext from './contexts/MedicalReviewExtensionContext';
import { GridColDef} from '@mui/x-data-grid';
import { IReviewer, IMedicalReview } from './api/dtos';
import { IMedicalReviewForm } from './api/dtos';
import { ICountry, IInstitution, IPatient } from '@ngt/forms-trials';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IMedicalReviewExtensionRouteParameterOptions {
    medicalReviewRepeat: string;
}

export interface IMedicalReviewExtensionOptions {
    routeParameters: IMedicalReviewExtensionRouteParameterOptions;

    /**
     * Columns for the patients table.
     */
    patientColumnsFn?: (user: any | undefined | null, countries: ICountry[], institution: IInstitution[], reviewers: IReviewer[]) => GridColDef[];

    createPatientsRouteFn: (search: string) => string;

    createPatientRouteFn: (patient: IPatient) => string;

    createMedicalReviewRouteFn: (patient: IPatient, medicalReview: IMedicalReview) => string;

    createMedicalReviewFormRouteFn: (patient: IPatient, medicalReview: IMedicalReview, formDefinition: IFormDefinition, formValidation?: IValidationResult, form?: IMedicalReviewForm) => string;

    createMedicalReviewQueriesRouteFn: (patient: IPatient, medicalReview: IMedicalReview) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class handles the global context used by the OPMS.
 */
export class MedicalReviewExtension implements IFormsExtension {
    public ProviderComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    public ContentComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IMedicalReviewExtensionOptions) {
        this.initialiseProviderComponent(options);
        this.initialiseContentComponent(options);
    }

    private initialiseProviderComponent(options: IMedicalReviewExtensionOptions) {

        this.ProviderComponent = ({ children }) => {
            return (
                <MedicalReviewExtensionContext.Provider value={options}>
                    {children}
                </MedicalReviewExtensionContext.Provider>
            );
        };
    }

    private initialiseContentComponent(options: IMedicalReviewExtensionOptions) {

        this.ContentComponent = ({ children }) => {
            return (
                <>
                    {children}
                </>
            );
        };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewExtension;

