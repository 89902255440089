/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme } from '@mui/material/styles';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';
import { makeStyles } from '../../../utilities/makeStyles';
import { InputAdornment, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type TEXT_FIELD_TYPE =
    | typeof TYPE_PASSWORD
    | typeof TYPE_TEXT
    | typeof TYPE_EMAIL
    | typeof TYPE_NUMBER
    | typeof TYPE_URL
    | typeof TYPE_TELEPHONE
    | typeof TYPE_DATE
    | typeof TYPE_DATETIME_LOCAL
    | typeof TYPE_MONTH
    | typeof TYPE_TIME
    | typeof TYPE_WEEK;

type PasswordFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<string, Dtos.IValidationError>

export interface IPasswordProps extends PasswordFieldsProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    showPasswordToggle?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Password: React.FunctionComponent<IPasswordProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, touch, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    inputProps,
    hasErrors,
    errors,
    showPasswordToggle,
    ...rest
}) => {

    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }

        onInputChange(event.target.value && event.target.value !== '' ? event.target.value : undefined as any, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
        touch();
    }, [inputProps?.onBlur, onBlur]);

    const handleClickShowPassword = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPasswordToggle, showPassword])

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <MuiTextField
            type={showPassword ? 'text' : 'password'}
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            name={name}
            value={value ?? ''}
            onChange={onChangeCombined}
            error={hasErrors}
            helperText={errors && errors.length > 0 && errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
            InputProps={{
                ...rest.InputProps,
                endAdornment: showPasswordToggle && (<InputAdornment position="end">
                    <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <FontAwesomeIcon fixedWidth icon={faEyeSlash} /> : <FontAwesomeIcon fixedWidth icon={faEye} />}
                    </IconButton>
                </InputAdornment>)
            }}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Password;
