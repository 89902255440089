import { Tooltip, Typography, Theme, useTheme, Box } from '@mui/material';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import PatientInformation from '../components/patient/PatientInformation';
import { FormDefinitionsContext, FormTypesContext, ProgressButton, useSnackbar } from '@ngt/forms';
import MedicalReviewSummaryList from '../components/medicalReview/MedicalReviewSummaryList';
import MedicalReviewContext from '../contexts/data/MedicalReviewContext';
import MedicalReviewValidationsContext from '../contexts/data/MedicalReviewValidationsContext';
import MedicalReviewDefinitionContext from '../contexts/configuration/MedicalReviewDefinitionContext';
import ReviewerContext from '../contexts/data/ReviewerContext';
import ContextMedicalReviewBreadcrumbs from '../components/breadcrumbs/ContextMedicalReviewBreadcrumbs';
import { MedicalReviewPermission, MedicalReviewStatus } from '../api/dtos';
import { AlertTitle } from '@mui/lab';
import CompleteMedicalReviewDialog from '../components/dialog/CompleteMedicalReviewDialog';
import UpdateMedicalReviewDialog from '../components/dialog/UpdateMedicalReviewDialog';
import { DateTime } from 'luxon';
import CancelMedicalReviewDialog from '../components/dialog/CancelMedicalReviewDialog';
import useContextPermissions from '../hooks/utility/useContextPermissions';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import { makeStyles } from '../styles/makeStyles';

interface IMedicalReviewProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(0, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    datesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center'
    },
    dateContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const MedicalReview: FunctionComponent<IMedicalReviewProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            title: {
                p: theme.spacing(2, 2, 1),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            subtitle: {
                p: theme.spacing(0, 2, 2)
            },
            patientInformation: {
                p: theme.spacing(2, 2, 0)
            },
            buttonGroup: {
                p: 2,
                textAlign: 'right'
            },
            button:{
                m: 1
            },
            datesContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center'
            },
            dateContainer: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }
        }
    }, [theme]);
    

    const [processing, setProcessing] = useState(false);
    const [closeOpen, setCloseOpen] = useState(false);
    const [completeOpen, setCompleteOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);

    const onCloseDialogOpen = useCallback(() => {
        setCloseOpen(true);
    }, [setCloseOpen]);

    const onCloseDialogClose = useCallback(() => {
        setCloseOpen(false);
    }, [setCloseOpen]);

    const onCompleteDialogOpen = useCallback(() => {
        setCompleteOpen(true);
    }, [setCompleteOpen]);

    const onCompleteDialogClose = useCallback(() => {
        setCompleteOpen(false);
    }, [setCompleteOpen]);

    const onUpdateDialogOpen = useCallback(() => {
        setUpdateOpen(true);
    }, [setUpdateOpen]);

    const onUpdateDialogClose = useCallback(() => {
        setUpdateOpen(false);
    }, [setUpdateOpen]);

    const { enqueueSnackbar } = useSnackbar();

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: medicalReview, save: saveMedicalReview, mutate: medicalReviewMutate } = useContext(MedicalReviewContext);
    const { data: formValidations, mutate: formValidationsMutate } = useContext(MedicalReviewValidationsContext);
    const { data: medicalReviewDefinition } = useContext(MedicalReviewDefinitionContext);
    const { data: reviewer } = useContext(ReviewerContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);

    const { data: [canCompleteMedicalReview, canCancelMedicalReview, canUpdateMedicalReview] } = useContextPermissions([MedicalReviewPermission.CompleteMedicalReview, MedicalReviewPermission.CancelMedicalReview, MedicalReviewPermission.UpdateMedicalReview]);

    const canMarkReviewComplete = useMemo(() => {
        if (formValidations?.some(f => f.metadata.Status !== MedicalReviewStatus.Complete)) {
            return false;
        }

        return canCompleteMedicalReview;
    }, [formValidations, canCompleteMedicalReview]);

    const canTriggerReviewUpdate = useMemo(() => {
        if (medicalReview?.status !== MedicalReviewStatus.New && medicalReview?.status !== MedicalReviewStatus.InProgress) {
            return false;
        }

        return canUpdateMedicalReview;
    }, [medicalReview, canUpdateMedicalReview]);

    const seeMarkReviewComplete = useMemo(() => {
        return medicalReview?.status !== MedicalReviewStatus.Complete && medicalReview?.status !== MedicalReviewStatus.Cancelled && canCompleteMedicalReview;
    }, [medicalReview, canCompleteMedicalReview]);

    const onFormMarkedComplete = useCallback(async () => {
        setProcessing(true);

        if (medicalReview) {
            try {
                await saveMedicalReview({ ...medicalReview, status: MedicalReviewStatus.Complete });

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Medical Review Saved
                        </AlertTitle>
                        The medical review has been successfully completed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Medical Review Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the medical review.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [medicalReview, saveMedicalReview, setProcessing, enqueueSnackbar]);


    const onFormMarkedClosed = useCallback(async () => {
        setProcessing(true);

        if (medicalReview) {
            try {
                await saveMedicalReview({ ...medicalReview, status: MedicalReviewStatus.Cancelled });
                await formValidationsMutate();

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Medical Review Saved
                        </AlertTitle>
                        The medical review has been successfully completed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Medical Review Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the medical review.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [medicalReview, saveMedicalReview, setProcessing, enqueueSnackbar, formValidationsMutate]);

    const onUpdate = useCallback(async () => {
        setProcessing(true);

        if (medicalReview) {
            await formValidationsMutate();
            await medicalReviewMutate();
        }

        setProcessing(false);
    }, [medicalReview, saveMedicalReview, setProcessing, enqueueSnackbar, formValidationsMutate, medicalReviewMutate]);

    return (
        <>
            <ContextMedicalReviewBreadcrumbs />

            <Box sx={styles.patientInformation}>
                <PatientInformation patient={patient} institution={institution} reviewer={reviewer} medicalReview={medicalReview} />
            </Box>

            <Box sx={styles.title}>
                <Typography variant="h1">
                    {medicalReviewDefinition?.name}
                </Typography>

                <Box sx={styles.datesContainer}>
                    {
                        medicalReview?.startDate && <Box sx={styles.dateContainer}>
                            <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                                Date Started:
                            </Typography>
                            <Typography variant="caption">
                                {DateTime.fromISO(medicalReview?.startDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                            </Typography>
                        </Box>
                    }
                    {
                        medicalReview?.updateDate && <Box sx={styles.dateContainer}>
                            <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                                Date Updated:
                            </Typography>
                            <Typography variant="caption">
                                {DateTime.fromISO(medicalReview?.updateDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                            </Typography>
                        </Box>
                    }
                    {
                        medicalReview?.status === MedicalReviewStatus.Complete &&
                        medicalReview?.completeDate && <Box sx={styles.dateContainer}>
                            <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                                Date Completed:
                            </Typography>
                            <Typography variant="caption">
                                {DateTime.fromISO(medicalReview?.completeDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                            </Typography>
                        </Box>
                    }
                    {
                        medicalReview?.status === MedicalReviewStatus.Cancelled &&
                        medicalReview?.cancelDate && <Box sx={styles.dateContainer}>
                            <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                                Date Cancelled:
                            </Typography>
                            <Typography variant="caption">
                                {DateTime.fromISO(medicalReview?.cancelDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                            </Typography>
                        </Box>
                    }
                </Box>
            </Box>
            
            <MedicalReviewSummaryList
                formDefinitions={formDefinitions}
                formValidations={formValidations}
                medicalReview={medicalReview ?? undefined}
                medicalReviewDefinition={medicalReviewDefinition ?? undefined}
                formTypes={formTypes}
                patient={patient ?? undefined}
                reviewer={reviewer ?? undefined}
            />
            <UpdateMedicalReviewDialog
                open={updateOpen}
                onClose={onUpdateDialogClose}
                medicalReviewDefinition={medicalReviewDefinition}
                medicalReview={medicalReview!}
                formDefinitions={formDefinitions}
                formTypes={formTypes}
                institution={institution!}
                patient={patient!}
                onFormUpdated={onUpdate}
            />
            <CompleteMedicalReviewDialog
                onClose={onCompleteDialogClose}
                onSubmit={onFormMarkedComplete}
                medicalReview={medicalReview!}
                open={completeOpen}
                institution={institution!}
                patient={patient!}
                reviewer={reviewer!}
                medicalReviewDefinition={medicalReviewDefinition!}
            />
            <CancelMedicalReviewDialog
                onClose={onCloseDialogClose}
                onSubmit={onFormMarkedClosed}
                medicalReview={medicalReview!}
                open={closeOpen}
                institution={institution!}
                patient={patient!}
                reviewer={reviewer!}
                medicalReviewDefinition={medicalReviewDefinition!}
            />
            <Box sx={styles.buttonGroup}>
                {
                    canTriggerReviewUpdate &&  (
                        <ProgressButton 
                            variant="contained" 
                            color="secondary" 
                            loading={processing} 
                            onClick={onUpdateDialogOpen}
                            sx={styles.button}
                        >
                            Update Review
                        </ProgressButton>
                    )
                }
                {
                    canCancelMedicalReview && medicalReview?.status !== MedicalReviewStatus.Complete && medicalReview?.status !== MedicalReviewStatus.Cancelled && (
                        <ProgressButton 
                            variant="contained" 
                            color="secondary" 
                            loading={processing} 
                            onClick={onCloseDialogOpen}
                            sx={styles.button}
                        >
                            Close Review
                        </ProgressButton>
                    )
                }
                {
                    seeMarkReviewComplete && !canMarkReviewComplete && (
                        <Tooltip title="All reports must be marked as complete">
                            <span>
                                <ProgressButton 
                                    variant="contained" 
                                    color="primary" 
                                    disabled={true}
                                    sx={styles.button}
                                >
                                    Mark Review Complete
                                </ProgressButton>
                            </span>
                        </Tooltip>
                    )
                }
                {
                    seeMarkReviewComplete && canMarkReviewComplete && (
                        <ProgressButton 
                            variant="contained"
                            color="primary" 
                            loading={processing} 
                            onClick={onCompleteDialogOpen}
                            sx={styles.button}
                        >
                            Mark Review Complete
                        </ProgressButton>
                    )
                }
            </Box>
        </>
    );
};

export default MedicalReview;
