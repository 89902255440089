import { createContext } from 'react';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';

interface IFormContext extends ReturnType<typeof useAuthenticatedUser> {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const AuthenticatedUserContext = createContext<IFormContext>({
    data: undefined,
    error: undefined,
    loading: true,
    mutate: async (data, shouldRevalidate) => {
        return undefined;
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default AuthenticatedUserContext;