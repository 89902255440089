/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass form context to the rest of the app.
 */
import FormByIdContext from '../../../contexts/data/FormContext';

/*
 * Used to load collaborating groups
 */
import useFormDataById from '../../../hooks/data/useFormDataById';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import LookupsContext from '../../../contexts/utility/LookupsContext';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';
import useFormDefinitionById from '../../../hooks/configuration/useFormDefinitionById';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';
import { useParams, Params, useLocation, Location } from 'react-router-dom';
import { useMemo } from 'react';
import useValueChangedLog from '../../../hooks/useValueChangedLog';
import { SWRConfiguration } from 'swr';
import { IDataSingleResponse } from '../../../api/response';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormByIdResolverProps {
    formDefinitionCode: string;
    id: number | null,
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IFormMapping>;

    formDefinitionConfiguration?: Partial<SWRConfiguration<Dtos.FormDefinitionSingleResponse, Dtos.FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<Dtos.LookupCollectionResponse, Dtos.LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<Dtos.IForm>, IDataSingleResponse<Dtos.IForm>>>;
    formTypeConfiguration?: Partial<SWRConfiguration<Dtos.FormTypeSingleResponse, Dtos.FormTypeSingleResponse>>;
    children?: ReactNode;
}

export interface IFormMapping {
    components: ReactNode;
    createData?: (params: Params<string>, location: Location) => Dtos.IForm;
    createMetadata?: (params: Params<string>, location: Location) => Record<string, any>
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormByIdResolver: FunctionComponent<IFormByIdResolverProps> = ({
    formDefinitionCode,
    id,
    resolveBeforeLoad,
    formMapping,
    children,
    formConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
    lookupConfiguration
}) => {
    const params = useParams();
    const location = useLocation();

    //useValueChangedLog(formMapping, 'formMapping');
    //useValueChangedLog(params, 'params');
    //useValueChangedLog(location, 'location');

    const createData = useMemo(() => {
        return formMapping?.[formDefinitionCode]?.createData?.(params, location);
    }, [formMapping, params, location]);

    const createMetadata = useMemo(() => {
        return formMapping?.[formDefinitionCode]?.createMetadata?.(params, location);
    }, [formMapping, params, location]);

    const formContext = useFormDataById(formDefinitionCode, id, createData, createMetadata, formConfiguration);

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode, formDefinitionConfiguration);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    const formDefCode = formDefinitionContext?.data?.code ?? '';

    const conditionalContexts: ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const notLoading =
        !formDefinitionContext?.loading &&
        !formContext?.loading &&
        !lookupsContext?.loading;

    return (
        <FormDefinitionContext.Provider value={formDefinitionContext}>
            <FormByIdContext.Provider value={formContext}>
                <LookupsContext.Provider value={lookupsContext}>
                    {
                        !resolveBeforeLoad || notLoading ?
                            conditionalContexts :
                            <RouteLoading />
                    }
                </LookupsContext.Provider>
            </FormByIdContext.Provider>
        </FormDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByIdResolver;