/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useContext, useMemo } from 'react';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { Breadcrumbs, IBreadcrumb, PatientContext } from '@ngt/forms-trials';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientBreadcrumbs component.
 */
export interface IContextPatientBreadcrumbsProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ContextPatientBreadcrumbs: React.FunctionComponent<IContextPatientBreadcrumbsProps> = () => {
    const { data: patient } = useContext(PatientContext);
    const { createPatientRouteFn } = useContext(MedicalReviewExtensionContext)

    const breadcrumbs = useMemo(() => {
        const b: IBreadcrumb[] = [];

        if (patient) {
            b.push({
                icon: faUserCircle,
                label: patient.studyNumber,
                to: createPatientRouteFn(patient)
            });
        }
        else {
            b.push({
                icon: faUserCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        return b;
    }, [patient, createPatientRouteFn])

    return (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextPatientBreadcrumbs;