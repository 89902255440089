import { QueryStatus } from "../api/dtos";

export const convertQueryStatusToString = (status?: QueryStatus) => {
    if (!status) {
        return 'Unknown';
    }

    switch (status) {
        case QueryStatus.Issued:
            return 'Open';
        case QueryStatus.Responded:
            return 'Responded';
        case QueryStatus.Closed:
            return 'Closed';
        case QueryStatus.Cancelled:
            return 'Cancelled';
        default:
            return 'Unknown';
    }
}