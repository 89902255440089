/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import { isEqual } from 'lodash';
import * as React from 'react';

import FieldContext, { IFieldContext } from '../contexts/FieldContext';
import useFieldActions from '../hooks/useFieldActions';
import useFieldState from '../hooks/useFieldState';
import Field from './Field';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IArrayFieldActions<TFieldValue = any, TFormValue extends object = any, TError = any> {
    add: (newValue: TFieldValue) => void;
    delete: (index: number) => void;
    move: (sourceIndex: number, targetIndex: number) => void;
}

export type ArrayFieldTemplateType<TFieldValue = any, TFormValue extends object = any, TError = any, T extends IArrayFieldTemplateRenderProps<TFieldValue, TFormValue, TError> = any> = React.ComponentType<T>;


export interface IArrayFieldTemplateRenderProps<TFieldValue = any, TFormValue extends object = any, TError = any> {
    arrayFieldRender: IArrayFieldTemplateRender<TFieldValue, TFormValue, TError>;
}

export interface IArrayFieldTemplateRender<TFieldValue = any, TFormValue extends object = any, TError = any> extends IFieldContext<TFieldValue[], TFormValue, TError> {
    header?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    count: number;
    arrayActions: IArrayFieldActions<TFieldValue, TFormValue, TError>;
}

export type ArrayFieldHeaderFooterType<TFieldValue = any, TFormValue extends object = any, TError = any, T extends IArrayFieldHeaderFooterRenderProps<TFieldValue, TFormValue, TError> = any> = React.ComponentType<T>;

export interface IArrayFieldHeaderFooterRenderProps<TFieldValue = any, TFormValue extends object = any, TError = any> {
    arrayFieldRender: IArrayFieldHeaderFooterRender<TFieldValue, TFormValue, TError>;
}

export interface IArrayFieldHeaderFooterRender<TFieldValue = any, TFormValue extends object = any, TError = any> extends IFieldContext<TFieldValue[], TFormValue, TError> {
    count: number;
    arrayActions: IArrayFieldActions<TFieldValue, TFormValue, TError>;
}

export type ArrayFieldNoRecordsType<TFieldValue = any, TFormValue extends object = any, TError = any, T extends IArrayFieldNoRecordsRenderProps<TFieldValue, TFormValue, TError> = any> = React.ComponentType<T>;

export interface IArrayFieldNoRecordsRenderProps<TFieldValue = any, TFormValue extends object = any, TError = any> {
    arrayFieldRender: IArrayFieldNoRecordsRender<TFieldValue, TFormValue, TError>;
}

export interface IArrayFieldNoRecordsRender<TFieldValue = any, TFormValue extends object = any, TError = any> extends IFieldContext<TFieldValue[], TFormValue, TError> {
    arrayActions: IArrayFieldActions<TFieldValue, TFormValue, TError>;
}

export type ArrayFieldComponentType<TFieldValue = any, TFormValue extends object = any, TError = any, T extends IArrayFieldComponentRenderProps<TFieldValue, TFormValue, TError> = any> = React.ComponentType<T>;


export interface IArrayFieldComponentRenderProps<TFieldValue = any, TFormValue extends object = any, TError = any> {
    arrayFieldRender: IArrayFieldComponentRender<TFieldValue, TFormValue, TError>;
}

export interface IArrayFieldComponentRender<TFieldValue = any, TFormValue extends object = any, TError = any> extends IFieldContext<TFieldValue[], TFormValue, TError> {
    index: number;
    count: number;
    arrayActions: IArrayFieldActions<TFieldValue, TFormValue, TError>;
}

/**
 * This interface defines the properties for the Field component.
 */
export interface IArrayFieldProps<TFieldValue = any, TFormValue extends object = any, TError = any, TComponent extends ArrayFieldComponentType<TFieldValue, TFormValue, TError> = any, TTemplate extends ArrayFieldTemplateType<TFieldValue, TFormValue, TError> = any, THeader extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TFooter extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TNoRecords extends ArrayFieldNoRecordsType<TFieldValue, TFormValue, TError> = any> {
    name: string;
    prependParentName?: boolean;
    template?: TTemplate;
    TemplateProps?: Omit<React.ComponentProps<TTemplate>, 'arrayFieldRender'>;
    header?: THeader;
    HeaderProps?: Omit<React.ComponentProps<THeader>, 'arrayFieldRender'>;
    footer?: TFooter;
    FooterProps?: Omit<React.ComponentProps<TFooter>, 'arrayFieldRender'>;
    noRecords?: TNoRecords;
    NoRecordsProps?: Omit<React.ComponentProps<TNoRecords>, 'arrayFieldRender'>;
    component?: TComponent;
    ComponentProps?: Omit<React.ComponentProps<TComponent>, 'arrayFieldRender'>;
    getComponentProps?: (index: number, item?: TFieldValue | null, ComponentProps?: Omit<React.ComponentProps<TComponent>, 'arrayFieldRender'>) => Omit<React.ComponentProps<TComponent>, 'arrayFieldRender'> | undefined;
    onContextUpdate?: (context: IFieldContext<TFieldValue[], TFormValue, TError> | undefined) => void;
    autoRegister?: boolean;
    getItemKey?: (index: number, item?: TFieldValue | null) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ArrayField = <TFieldValue extends any = any, TFormValue extends object = any, TError = any, TComponent extends ArrayFieldComponentType<TFieldValue, TFormValue, TError> = any, TTemplate extends ArrayFieldTemplateType<TFieldValue, TFormValue, TError> = any, THeader extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TFooter extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TNoRecords extends ArrayFieldNoRecordsType<TFieldValue, TFormValue, TError> = any>(props: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent, TTemplate, THeader, TFooter, TNoRecords>) => {
    const {
        name,
        prependParentName,
        onContextUpdate,
        autoRegister
    } = props;

    return (
        <Field 
            name={name} 
            prependParentName={prependParentName}
            onContextUpdate={onContextUpdate}
            autoRegister={autoRegister}
        >
            <ArrayFieldInternal 
                {...props}
            />
        </Field>
    );
}

interface IArrayFieldItem {
    index: number;
    key: string;
    props: any;
}

const getItems = <TFieldValue extends any = any, TFormValue extends object = any, TError = any, TComponent extends ArrayFieldComponentType<TFieldValue, TFormValue, TError> = any>(
    items: TFieldValue[] | null | undefined,
    getItemKey: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['getItemKey'], 
    getComponentProps: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['getComponentProps'], 
    ComponentProps: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['ComponentProps']
) => {
    return items?.map((x, i) => getItem<TFieldValue, TFormValue, TError, TComponent>(x, i, getItemKey, getComponentProps, ComponentProps)) ?? [];
}

const getItem = <TFieldValue extends any = any, TFormValue extends object = any, TError = any, TComponent extends ArrayFieldComponentType<TFieldValue, TFormValue, TError> = any>(
    item: TFieldValue | null | undefined, 
    index: number, 
    getItemKey: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['getItemKey'], 
    getComponentProps: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['getComponentProps'], 
    ComponentProps: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent>['ComponentProps']
) => ({
    index: index,
    key: getItemKey?.(index, item) ?? index.toString(),
    props: getComponentProps?.(index, item, ComponentProps) ?? ComponentProps
})

export const DefaultFieldArrayTemplate = <TFieldValue extends any = any, TFormValue extends object = any, TError = any>({
    arrayFieldRender: {header, footer, content}
}: IArrayFieldTemplateRenderProps<TFieldValue, TFormValue, TError>) => {
    return (
        <>
            {header}
            {content}
            {footer}
        </>
    )
};

export const DefaultFieldArrayHeaderFooter = <TFieldValue extends any = any, TFormValue extends object = any, TError = any>({
    arrayFieldRender: { name, actions },
    children
}: IArrayFieldHeaderFooterRenderProps<TFieldValue, TFormValue, TError> & { children?: React.ReactNode }) => {
    return (
        <>
            {children}
        </>
    )
}

export const DefaultFieldArrayComponent = <TFieldValue extends any = any, TFormValue extends object = any, TError = any>({
    arrayFieldRender: { name, index, actions }
}: IArrayFieldComponentRenderProps<TFieldValue, TFormValue, TError>) => {
    return (
        <Field name={`[${index}]`}>
            <DefaultFieldArrayComponentInternal />
        </Field>
    )
}

const DefaultFieldArrayComponentInternal: React.FunctionComponent = () => {
    const { value } = useFieldState({ value: true });

    return (
        <pre>
            {JSON.stringify(value, null, '\t')}
        </pre>
    )
};

export const DefaultFieldArrayNoRecords = <TFieldValue extends any = any, TFormValue extends object = any, TError = any>(
    { }: IArrayFieldNoRecordsRenderProps<TFieldValue, TFormValue, TError>
) => {
    return (
        <>No records</>
    );
};

const ArrayFieldInternal = <TFieldValue extends any = any, TFormValue extends object = any, TError = any, TComponent extends ArrayFieldComponentType<TFieldValue, TFormValue, TError> = any, TTemplate extends ArrayFieldTemplateType<TFieldValue, TFormValue, TError> = any, THeader extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TFooter extends ArrayFieldHeaderFooterType<TFieldValue, TFormValue, TError> = any, TNoRecords extends ArrayFieldNoRecordsType<TFieldValue, TFormValue, TError> = any>({
    name,
    prependParentName,
    onContextUpdate,
    autoRegister,
    getItemKey,
    component,
    ComponentProps,
    getComponentProps,
    FooterProps,
    HeaderProps,
    TemplateProps,
    NoRecordsProps,
    noRecords,
    footer,
    header,
    template
}: IArrayFieldProps<TFieldValue, TFormValue, TError, TComponent, TTemplate, THeader, TFooter, TNoRecords>) => {
    const { name: computedName } = useFieldState({});
    const actions = useFieldActions<TFieldValue[], TFormValue, TError>();

    const {
        subscribe,
        getValue,
        setValue
    } = actions;

    const [items, setItems] = React.useState<IArrayFieldItem[]>(() => getItems<TFieldValue, TFormValue, TError, TComponent>(getValue(), getItemKey, getComponentProps, ComponentProps));

    React.useEffect(() => {
        setItems(c => {
            const newItems = getItems<TFieldValue, TFormValue, TError, TComponent>(getValue(), getItemKey, getComponentProps, ComponentProps);
    
            if (!isEqual(c, newItems)) {
               return newItems ?? [];
            }

            return c;
        });

        const unsubscribe = subscribe((fieldState, formActions) => {
            setItems(c => {
                const newItems = getItems<TFieldValue, TFormValue, TError, TComponent>(getValue(), getItemKey, getComponentProps, ComponentProps);
        
                if (!isEqual(c, newItems)) {
                    return newItems ?? [];
                }

                return c;
            });
        }, { value: true });

        return () => {
            unsubscribe();
        }
    }, [subscribe, setItems, getItemKey, getComponentProps, ComponentProps]);

    const arrayActions = React.useMemo(() => {
        return {
            add: (newValue: TFieldValue) => {
                setValue(existingValue => {
                    return [...existingValue, newValue];
                });
            },
            delete: (index: number) => {
                setValue(existingValue => {
                    return existingValue?.filter((x, i) => i !== index);
                });
            },
            move: (sourceIndex: number, targetIndex: number) => {
                setValue(existingValue => {
                    const arr = [...existingValue];

                    let itemRemoved = arr.splice(sourceIndex, 1); // splice() returns the remove element as an array
                    arr.splice(targetIndex, 0, itemRemoved[0]); // Insert itemRemoved into the target index

                    return arr;
                });
            }
        }
    }, [setValue])

    const Component = component ?? DefaultFieldArrayComponent;
    const Template = template ?? DefaultFieldArrayTemplate;
    const Header = header ?? DefaultFieldArrayHeaderFooter;
    const Footer = footer ?? DefaultFieldArrayHeaderFooter;
    const NoRecords = noRecords ?? DefaultFieldArrayNoRecords;

    const content = items && items.length > 0 ?
        items?.map((x, i) => {
            return (
                <Component
                    key={x.key}
                    {...x.props}
                    arrayFieldRender={{
                        index: x.index,
                        name: computedName,
                        actions: actions,
                        arrayActions,
                        count: items?.length ?? 0
                    }}
                />
            );
        }) :
        <NoRecords 
            {...NoRecordsProps} 
            arrayFieldRender={{
                name: computedName,
                actions: actions,
                arrayActions
            }} 
        />;

    const headerContent = (
        <Header 
            {...HeaderProps} 
            arrayFieldRender={{
                name: computedName,
                actions: actions,
                arrayActions,
                count: items?.length ?? 0
            }} 
        />
    );
    

    const footerContent = (
        <Footer 
            {...FooterProps} 
            arrayFieldRender={{
                name: computedName,
                actions: actions,
                arrayActions,
                count: items?.length ?? 0
            }} 
        />
    );

    return (
        <Template
            {...TemplateProps}
            arrayFieldRender={{
                name: computedName,
                actions: actions,
                arrayActions,
                content: content,
                header: headerContent,
                footer: footerContent,
                count: items?.length ?? 0
            }}
        />
    );
}



/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ArrayField;