import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { MedicalReviewValidationCollectionResponse, MedicalReviewPostFormValidationCollectionById } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, id?: number, metadata?: Record<string, any>) => {
    const response = await forms.serviceStackClient.post(new MedicalReviewPostFormValidationCollectionById({ id, metadata }));

    return response;
}

const useMedicalReviewFormValidationByMedicalReviewId = (medicalReviewId?: number, metadata?: Record<string, any>, configuration?: SWRConfiguration<MedicalReviewValidationCollectionResponse, MedicalReviewValidationCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<MedicalReviewValidationCollectionResponse, MedicalReviewValidationCollectionResponse>(medicalReviewId ? [`${forms.baseRoute}/medical-review/collection/patient-id`, forms, medicalReviewId, metadata] : null, fetcher, configuration);

    const boundMutate: BoundMutator<MedicalReviewValidationCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/medical-review/collection/patient-id`, forms, medicalReviewId, metadata], data, shouldRevalidate);
    }, [forms, medicalReviewId, metadata])

    const result = useMemo(() => {
        return {
            data: data?.validationResult,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useMedicalReviewFormValidationByMedicalReviewId;