/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { IFormProps as IFormBaseProps, IFormValidate, IFormAllowSubmit, IFormSubmitValidationFailed, IFormSubmitFailed, IFormSubmit} from '@ngt/forms-core';

import { IDataModel, IForm, IValidationError, IValidationResult, ValidationErrorType } from '../../api/dtos';

import DataForm, { IDataFormProps } from './DataForm';
import useFormByContext from '../../hooks/form/useFormByContext';

import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';
import asyncDebounce from '../../utilities/asyncDebounce';
import titleCase from '../../utilities/titleCase';
import ISaveModel from '../../utilities/ISaveModel';
import IValidateModel from '../../utilities/IValidateModel';
import FormsContext from '../../contexts/FormsContext';
import { useCallback } from 'react';
import useSnackbar, { SnackbarVariant } from '../../hooks/useSnackbar';
import pluralize from 'pluralize';
import RouteLoading from '../route/RouteLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: ReactNode;
//}

type OmitCalculatedTypes<T> = Omit<T, 'validate' | 'save' | 'lookups' | 'labels' | 'initialValues' | 'entity'>

/**
 * This interface defines the properties for the Form component.
 */
export interface IContextFormProps<TData extends IForm = IForm, TValidationResult extends IValidationResult = IValidationResult> extends OmitCalculatedTypes<IDataFormProps<TData, TValidationResult>> {
    entity?: string
}
/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const ContextForm = <TData extends IForm = IForm, TValidationResult extends IValidationResult = IValidationResult>({
    entity,
    ...formProps
}: IContextFormProps<TData, TValidationResult>) => {
    const { form, loading, formDefinition, formName, labels, lookups, saveForm, validateForm } = useFormByContext<TData, TValidationResult>();


    if (loading) {
        return <RouteLoading />
    }

    return (
        <DataForm
            {...formProps}
            initialValues={form}
            validate={validateForm}
            save={saveForm}
            entity={entity ?? formName ?? ''}
            lookups={lookups}
            labels={labels}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextForm;