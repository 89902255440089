/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import useField from '../hooks/useField';

import FieldContext, { IFieldContext } from '../contexts/FieldContext';
import FormErrorContext from '../contexts/FormErrorContext';
import FormErrorActionContext from '../contexts/FormErrorActionContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Field component.
 */
export interface IFieldProps<TFieldValue = any, TFormValue extends object = any, TError = any> {
    name: string;
    prependParentName?: boolean;
    children?: React.ReactNode;
    onContextUpdate?: (context: IFieldContext<TFieldValue, TFormValue, TError> | undefined) => void;
    autoRegister?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Field = <TFieldValue extends any = any, TFormValue extends object = any, TError = any>({
    name,
    prependParentName,
    onContextUpdate,
    autoRegister,
    children
}: IFieldProps<TFieldValue, TFormValue, TError>) => {

    const parentContext = React.useContext(FieldContext);
    const formErrorActionContext = React.useContext(FormErrorActionContext);

    const computedName = React.useMemo(() => {
        if (parentContext?.name && prependParentName !== false) {
            if (name.startsWith('[')) {
                return `${parentContext.name}${name}`;
            }

            return `${parentContext.name}.${name}`;
        }

        return name;
    }, [parentContext?.name, name, prependParentName]);

    const fieldMethods = useField<TFieldValue, TFormValue, TError>(computedName, autoRegister);

    const [context, setContext] = React.useState<IFieldContext<TFieldValue, TFormValue, TError> | undefined>({
        name: computedName,
        actions: fieldMethods
    });

    React.useEffect(() => {
        if (autoRegister !== false) {
            formErrorActionContext?.addName(computedName);

            return () => {
                formErrorActionContext?.removeName(computedName);
            };
        }

        return () => { };
    }, [computedName, autoRegister, formErrorActionContext?.addName, formErrorActionContext?.removeName])

    React.useEffect(() => {
        const c = {
            name: computedName,
            actions: fieldMethods
        };

        if (onContextUpdate) {
            onContextUpdate(c);
        }

        setContext(c);
    }, [computedName, fieldMethods, onContextUpdate, setContext]);

    return (
        <FieldContext.Provider value={context ?? null}>
            {children}
        </FieldContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Field;