import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { Form, IFormLabel, GroupedField, IGroupedFieldStyleProps, TextDisplay } from '@ngt/forms';
import { IMedicalReviewDefinition, IReviewer, IMedicalReview } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

interface ICompleteMedicalReviewDialogProps {
    medicalReview?: IMedicalReview;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    reviewer?: IReviewer;
    institution?: IInstitution;
    patient?: IPatient;
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        padding: 0
    }
}));


const labels: IFormLabel[] = [
    {
        name: 'institution.name',
        label: 'Institution',
        detailedLabel: 'Institution'
    },
    {
        name: 'patient.studyNumber',
        label: 'Patient',
        detailedLabel: 'Patient'
    },
    {
        name: 'medicalReviewDefinition.name',
        label: 'Medical Review Type',
        detailedLabel: 'Medical Review Type'
    },
    {
        name: 'reviewer.name',
        label: 'Reviewer',
        detailedLabel: 'Reviewer'
    }
];

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 5,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const CompleteMedicalReviewDialog: FunctionComponent<ICompleteMedicalReviewDialogProps> = ({
    open,
    onClose,
    onSubmit,
    institution,
    medicalReview,
    reviewer,
    patient,
    medicalReviewDefinition
}) => {
    const { classes } = useStyles();

    const handleSubmit = useCallback(() => {
        onSubmit();
        onClose();
    }, [onClose, onSubmit])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Complete Medical Review
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }} dividers>
                <Form
                    initialValues={{
                        patient,
                        institution,
                        medicalReview,
                        reviewer,
                        medicalReviewDefinition
                    }}
                    labels={labels}
                >
                    <GroupedField
                        name="institution.name"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="patient.studyNumber"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="medicalReviewDefinition.name"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="reviewer.name"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <BasicAlert
                        title="Please Note"
                        description={
                            <>
                                <p>Completing the medical review will finalise the review making it impossible to add further queries.</p>
                                <p>Please ensure that all information has been reviewed and all necessary queries have been created before continuing.</p>
                            </>
                        }
                        icon={faInfoCircle}
                        severity="info"
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Complete Medical Review
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompleteMedicalReviewDialog;
