import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import { IQuery, QueryGetCollectionByMedicalReviewId, QueryPostSaveCollection, QueryCollectionResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, medicalReviewId?: number) => {
    if (!medicalReviewId) {
        return new QueryCollectionResponse({ data: [] });
    }

    return await get(forms, medicalReviewId)
}

const get = async (forms: Forms, medicalReviewId: number) => {
    const response = await forms.serviceStackClient.get(new QueryGetCollectionByMedicalReviewId({ medicalReviewId }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TQuery extends IQuery = IQuery>(forms: Forms, data: TQuery[], metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new QueryPostSaveCollection({ data, metadata }));

    return response;
}

//const validate = async <TQuery extends IQuery = IQuery, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, data: TQuery[], metadata: Record<string, any> | undefined) => {

//    const response = await forms.serviceStackClient.post(new QueryPostValidate({ data, metadata }));

//    return response;
//}

const useQueriesByMedicalReviewId = <TQuery extends IQuery = IQuery>(medicalReviewId?: number | null, configuration?: SWRConfiguration<QueryCollectionResponse, QueryCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!medicalReviewId) {
            return null;
        }

        return [`${forms.baseRoute}/query/collection/medical-review-id`, forms, medicalReviewId]
    }, [forms, medicalReviewId])

    const { data, error } = useSWR<QueryCollectionResponse, QueryCollectionResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<QueryCollectionResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TQuery[], metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TQuery>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    //const boundValidate = useCallback(async (validateData: TQuery[], metadata?: Record<string, any>) => {
    //    const response = await validate<TQuery, TValidationResult>(forms, validateData, metadata);

    //    return response?.validationResult;
    //}, [boundMutate, forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TQuery[] | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            //validate: boundValidate
        };
    }, [boundMutate, data, error, /*boundValidate,*/ boundSave])

    return result;
}

export default useQueriesByMedicalReviewId;