/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IInputRenderProps, IUseFormErrorBoundaryOptions, useFormErrorBoundary } from '@ngt/forms-core';

import * as Dtos from '../../../api/dtos';
import { makeStyles } from '../../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<number, Dtos.IValidationError> & { numberFormat: Omit<NumberFormatProps, 'format'> & { format?: (val: number | string | undefined) => number | string | undefined } }

export interface INumericProps extends TextFieldsProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
}

interface IInternalNumberFormatProps {
    onChange: (event: { target: { value: number | undefined } }) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const InternalNumberFormat = React.forwardRef<NumberFormat<any>, IInternalNumberFormatProps>(({
    onChange,
    ...rest
}, ref) => {
    const onValueChange = React.useCallback((values: NumberFormatValues) => {
        onChange({
            target: {
                value: values.floatValue,
            },
        })
    }, [onChange]);

    return (
        <NumberFormat
            {...rest}
            getInputRef={ref}
            onValueChange={onValueChange}
        />
    );
});

const errorSubscription = { touched: true };


const showErrorFn: IUseFormErrorBoundaryOptions['includeError'] = (name, error, { touched }) => touched[name];

const Numeric: React.FunctionComponent<INumericProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, touch, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    InputProps,
    inputProps,
    numberFormat,
    disabled,
    errors,
    hasErrors,
    ...rest
}) => {
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }

        onInputChange(event.target.value || event.target.value as any === 0 ? event.target.value : undefined as any, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
        touch();
    }, [inputProps?.onBlur, onBlur]);

    return (
        <MuiTextField
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            InputProps={{
                ...InputProps,
                inputComponent: InputProps?.inputComponent === undefined ? InternalNumberFormat : InputProps?.inputComponent
            }}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined,
                ...numberFormat
            }}
            name={name}
            value={value ?? ''}
            onChange={onChangeCombined}
            error={hasErrors}
            helperText={errors && errors.length > 0 && errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
            disabled={disabled}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Numeric;
