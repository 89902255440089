import { MedicalReviewStatus } from "../api/dtos";

export const convertMedicalReviewStatusToString = (status?: MedicalReviewStatus) => {
    if (!status) {
        return 'No Reviews';
    }

    switch (status) {
        case MedicalReviewStatus.New:
            return 'New';
        case MedicalReviewStatus.InProgress:
            return 'In Progress';
        case MedicalReviewStatus.Complete:
            return 'Complete';
        case MedicalReviewStatus.Cancelled:
            return 'Cancelled';
        default:
            return 'Unknown';
    }
}