/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';
import { RouteLoading, FormTypeCollectionResponse, FormDefinitionCollectionResponse, useFormTypes, useFormDefinitions, FormDefinitionsContext, FormTypesContext } from '@ngt/forms';
import { MedicalReviewCollectionResponse, MedicalReviewDefinitionCollectionResponse, ReviewerCollectionResponse } from '../../api/dtos';
import { Route, Navigate, Routes } from 'react-router-dom';
import useMedicalReviewsByPatientId from '../../hooks/data/useMedicalReviewsByPatientId';
import useReviewers from '../../hooks/data/useReviewers';
import useMedicalReviewDefinitions from '../../hooks/configuration/useMedicalReviewDefinitions';
import MedicalReviewsContext from '../../contexts/data/MedicalReviewsContext';
import MedicalReviewDefinitionsContext from '../../contexts/configuration/MedicalReviewDefinitionsContext';
import ReviewersContext from '../../contexts/data/ReviewersContext';
import { CoordinatingGroupContext, CoordinatingGroupSingleResponse, CountryContext, CountrySingleResponse, InstitutionContext, InstitutionSingleResponse, MasterGroupContext, MasterGroupSingleResponse, PatientContext, PatientSingleResponse, useCoordinatingGroupById, useCountryById, useInstitutionById, useMasterGroupById, usePatientByStudyNumber } from '@ngt/forms-trials';
import { SWRConfiguration } from 'swr';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMedicalReviewsByPatientStudyNumberResolverProps {
    patientStudyNumber: string | null;
    resolveBeforeLoad?: boolean | null;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewsConfiguration?: Partial<SWRConfiguration<MedicalReviewCollectionResponse, MedicalReviewCollectionResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    reviewersConfiguration?: Partial<SWRConfiguration<ReviewerCollectionResponse, ReviewerCollectionResponse>>;
    medicalReviewDefinitionsConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionCollectionResponse, MedicalReviewDefinitionCollectionResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;
    children?: ReactNode;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const MedicalReviewsByPatientStudyNumberResolver: FunctionComponent<IMedicalReviewsByPatientStudyNumberResolverProps> = ({
    patientStudyNumber,
    resolveBeforeLoad,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionsConfiguration,
    medicalReviewsConfiguration,
    medicalReviewDefinitionsConfiguration,
    reviewersConfiguration,
    formTypesConfiguration
}) => {
    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const medicalReviewsContext = useMedicalReviewsByPatientId(patientContext?.data?.id, medicalReviewsConfiguration);

    const medicalReviewDefinitionsContext = useMedicalReviewDefinitions(medicalReviewDefinitionsConfiguration);

    const formDefinitionsContext = useFormDefinitions(formDefinitionsConfiguration);
    const formTypesContext = useFormTypes(formTypesConfiguration);

    const reviewersContext = useReviewers(reviewersConfiguration);

    const loading =
        (formDefinitionsContext?.loading) ||
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && medicalReviewsContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        medicalReviewDefinitionsContext?.loading ||
        reviewersContext?.loading ||
        formTypesContext?.loading;

    const hasError = !!(
        !patientStudyNumber ||
        formDefinitionsContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        medicalReviewsContext?.error ||
        medicalReviewDefinitionsContext?.error ||
        reviewersContext?.error ||
        formTypesContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        )
    }

    return (
        <MasterGroupContext.Provider value={masterGroupContext}>
            <CoordinatingGroupContext.Provider value={coordinatingGroupContext}>
                <CountryContext.Provider value={countryContext}>
                    <InstitutionContext.Provider value={institutionContext}>
                        <PatientContext.Provider value={patientContext}>
                            <MedicalReviewsContext.Provider value={medicalReviewsContext}>
                                <MedicalReviewDefinitionsContext.Provider value={medicalReviewDefinitionsContext}>
                                    <FormTypesContext.Provider value={formTypesContext}>
                                        <ReviewersContext.Provider value={reviewersContext}>
                                            <FormDefinitionsContext.Provider value={formDefinitionsContext}>
                                                {
                                                    !resolveBeforeLoad || !loading ?
                                                        children :
                                                        <RouteLoading />
                                                }
                                            </FormDefinitionsContext.Provider>
                                        </ReviewersContext.Provider>
                                    </FormTypesContext.Provider>
                                </MedicalReviewDefinitionsContext.Provider>
                            </MedicalReviewsContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewsByPatientStudyNumberResolver;