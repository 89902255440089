
import { FieldContext, IFormState, IFormSubscription, useFieldState, useFormState } from "@ngt/forms-core";
import React, { useContext, useEffect, useMemo, ReactNode } from "react";
import * as Dtos from '../../api/dtos';
import FormLookupContext from '../../contexts/form/FormLookupContext';

export interface ILookupFilterProps<T extends object = any> {
    subscription: Partial<IFormSubscription>;
    filterFunction: (formState: IFormState<T, Dtos.IValidationError>, fieldName: string, lookup: Dtos.ILookup | undefined) => Dtos.ILookup | undefined;
    children?: ReactNode;
}

const LookupFilter = <T extends object = any>({
    subscription,
    filterFunction,
    children
}: ILookupFilterProps<T>) => {
    const lookupContext = useContext(FormLookupContext);
    const fieldContext = useContext(FieldContext);
    const fieldState = useFieldState();

    const formState = useFormState<T, Dtos.IValidationError>(subscription);

    const lookup = useMemo(() => {
        if (!filterFunction || !fieldContext?.name) {
            return undefined;
        }

        return filterFunction(formState, fieldContext?.name, lookupContext);
    }, [fieldContext?.name, lookupContext, formState]);

    // Clear child selection if parent invalid for lookup.
    useEffect(() => {
        if (!fieldState.value && !lookup?.items?.find(item => item.id === fieldState.value)) {
            fieldContext?.actions.setValue(undefined);
        }
    }, [lookup, fieldState.value, fieldContext?.actions]);

    if (!lookup) {
        return <>{children}</>;
    }

    return (
        <FormLookupContext.Provider value={lookup}>
            {children}
        </FormLookupContext.Provider>
    )
};

export default LookupFilter;