/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import { FormErrorBoundary, FormErrorBoundaryMode, FormErrorContext } from '@ngt/forms-core';
import React, { FunctionComponent, ReactNode, useContext, useMemo, useState } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ErrorContext from '../../contexts/form/ErrorContext';
import Observable from '../../utilities/Observable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormErrorHandler component.
 */
export interface IFormErrorHandlerProps {
    errorMode?: FormErrorBoundaryMode;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormErrorHandler: FunctionComponent<IFormErrorHandlerProps> = ({
    errorMode,
    children
}) => {

    const parentContext = useContext(ErrorContext);
    const [expanded, setExpanded] = useState<boolean>(true);

    const context = useMemo(() => {
        if (parentContext && errorMode !== FormErrorBoundaryMode.Contain) {
            return undefined;
        }

        return {
            expanded,
            setExpanded
        }
    }, [parentContext, errorMode, expanded, setExpanded]);

    if (!context) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <FormErrorBoundary mode={errorMode}>
            <ErrorContext.Provider value={context}>
                {children}
            </ErrorContext.Provider>
        </FormErrorBoundary>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrorHandler;