/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { FunctionComponent, useCallback, useContext } from 'react';

import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown';

import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import ErrorContext from '../../../../contexts/form/ErrorContext';

import ValidationErrorFlag from '../../../utility/ValidationErrorFlag';
import FormOptionsContext, { FieldErrorOption } from '../../../../contexts/form/FormOptionsContext';
import { ShowErrorFunction } from './GroupedErrorDisplay';
import { useFieldState, useFormState } from '@ngt/forms-core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export type ShowErrorFlagFunction = (errors: Dtos.IValidationError[], touched: boolean, focused: boolean, validating: boolean, isAlwaysHidden: boolean, isAlwaysShown: boolean) => boolean;

export interface IFieldErrorFlagProps {
    showMultiple?: boolean;
    showErrorFlag?: ShowErrorFlagFunction;
    showErrorFlagExt?: ShowErrorFunction;
    fieldErrorOption?: FieldErrorOption
}

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_SHOW_ERROR_FLAG_FUNCTION: ShowErrorFlagFunction = (errors, touched, focused, validating, isAlwaysHidden, isAlwaysShown) => {
    if (isAlwaysHidden) {
        return false;
    }

    if (!isAlwaysShown && !touched && (!validating || !focused)) {
        return false;
    }

    if (!focused && (!errors || errors.length === 0) && validating) {
        return false;
    }

    return true;
}

const FieldErrorFlag: FunctionComponent<IFieldErrorFlagProps> = ({
    showMultiple,
    showErrorFlag,
    showErrorFlagExt,
    fieldErrorOption
}) => {

    const { name, errors, touched, focused } = useFieldState<any, Dtos.IValidationError>({ errors: true, touched: true, focused: true });

    const { validating, errors: formErrors, touched: formTouched } = useFormState({ validating: true, touched: !!showErrorFlagExt, errors: !!showErrorFlagExt });

    const errorContext = useContext(ErrorContext);

    const onClick = useCallback(() => {
        errorContext?.setExpanded(!errorContext?.expanded);
    }, [errorContext?.expanded, errorContext?.setExpanded])

    const formsOptionsContext = useContext(FormOptionsContext);


    const fieldErrorOptionToUse = fieldErrorOption ?? formsOptionsContext.fieldErrors;

    const forceShow =
        fieldErrorOptionToUse === FieldErrorOption.AlwaysShowAllErrors ||
        fieldErrorOptionToUse === FieldErrorOption.AlwaysShowFirstError;

    const forceHide =
        fieldErrorOptionToUse === FieldErrorOption.AlwaysHideErrors ||
        fieldErrorOptionToUse === FieldErrorOption.AlwaysHideErrorMessages ||
        fieldErrorOptionToUse === FieldErrorOption.AutoHideErrorMessages;

    if (showErrorFlagExt) {
        if (!errors?.some(error => showErrorFlagExt(name, error, formTouched, formErrors, validating, forceShow))) {
            return null;
        }
    }
    else if (showErrorFlag) {
        if (!showErrorFlag(errors, touched, focused, validating, forceHide, forceShow)) {
            return null;
        }
    }
    else if (!DEFAULT_SHOW_ERROR_FLAG_FUNCTION(errors, touched, focused, validating, forceHide, forceShow)) {
        return null;
    }

    return (
        <ValidationErrorFlag
            validationErrors={errors}
            loading={validating}
            onClick={onClick}
            hoverIcon={errorContext?.expanded ? faChevronCircleUp : faChevronCircleDown}
            showMultiple={showMultiple}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FieldErrorFlag;
