import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormDefinitionSingleResponse, FormDefinitionGetSingleById } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';
import { Forms } from '../../Forms';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, id: number) => {
    const response = await forms.serviceStackClient.get(new FormDefinitionGetSingleById({ id }));

    return response;
}

const useFormDefinitionById = (id?: number, configuration?: SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<FormDefinitionSingleResponse, FormDefinitionSingleResponse>(id ? [`${forms.baseRoute}/forms-definition/single/id`, forms, id] : null, fetcher, configuration);

    const boundMutate: BoundMutator<FormDefinitionSingleResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/forms-definition/single/id`, forms, id], data, shouldRevalidate);
    }, [forms, id])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useFormDefinitionById;