import { FieldContext, IUseFormErrorBoundaryOptions, useFormErrorBoundary } from '@ngt/forms-core';
import { useContext, useMemo } from 'react';
import * as Dtos from '../../api/dtos';
import { FieldErrorOption } from '../../contexts/form/FormOptionsContext';
import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';

export const errorSubscription = { touched: true };


export const hideErrorFn: IUseFormErrorBoundaryOptions['includeError'] = () => false;
export const showErrorFn: IUseFormErrorBoundaryOptions['includeError'] = (name, error, { touched }) => touched[name];
export const alwaysShowErrorFn: IUseFormErrorBoundaryOptions['includeError'] = () => true;

export const useFieldErrors = (fieldErrorOption?: FieldErrorOption) => {
    const { name } = useContext(FieldContext) ?? {};

    const includeErrorFn = useMemo(() => {

        switch (fieldErrorOption) {
            case FieldErrorOption.AlwaysHideErrors:
                return hideErrorFn
            case FieldErrorOption.AlwaysHideErrorMessages:
            case FieldErrorOption.AlwaysShowAllErrors:
            case FieldErrorOption.AlwaysShowFirstError:
                return alwaysShowErrorFn;
            case FieldErrorOption.AutoHideErrorMessages:
            case FieldErrorOption.AutoShowFirstError:
            default:
                return showErrorFn;
        }
    }, [])

    const data = useFormErrorBoundary<any, Dtos.IValidationError>({
        subscription: errorSubscription,
        includeError: includeErrorFn
    });

    const output = useMemo(() => {
        if (!data || !name) {
            return {
                hasErrors: false,
                errors: null
            };
        }

        const e = data.filter(x => pascalToCameCasePropertyPath(x.property) === name);

        if (e.length === 0) {
            return {
                hasErrors: false,
                errors: null
            };
        }

        switch (fieldErrorOption) {
            case FieldErrorOption.AlwaysHideErrors:
                return {
                    hasErrors: false,
                    errors: null
                };
            case FieldErrorOption.AlwaysHideErrorMessages:
            case FieldErrorOption.AutoHideErrorMessages:
                return {
                    hasErrors: true,
                    errors: null
                };
            case FieldErrorOption.AlwaysShowFirstError:
            case FieldErrorOption.AutoShowFirstError:
                return {
                    hasErrors: true,
                    errors: e.filter((x, i) => i === 0)
                };
            case FieldErrorOption.AlwaysShowAllErrors:
            default:
                return {
                    hasErrors: true,
                    errors: e
                };
        }
    }, [data, name]);

    return output;
}