import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { IMedicalReview, IQuery, IQueryRecipient, QueryStatus } from '../../api/dtos';
import { TableHead, Table, TableBody, TableRow, TableCell, Typography, Theme, useTheme, Box } from '@mui/material';
import { IFormDefinition, IFormType } from '@ngt/forms';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import QueryGridRow from './QueryGridRow';
import QueryDialog, { DialogState } from './QueryDialog';
import QueryGridFab from './QueryGridFab';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';

interface IQueryGridProps {
    medicalReview: IMedicalReview;
    queries: IQuery[];
    queryRecipients: IQueryRecipient[];
    formTypes: IFormType[];
    formDefinitions: IFormDefinition[];
    onRespond: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onIssue: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onUpdate: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onClose: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onCancel: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    disabled?: boolean;

    defaultPropertyName?: string;

    onFailure?: (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    notifications?: boolean;
    hideSection?: boolean;
    hideReport?: boolean;
    allowInsert?: boolean;

    canView?: boolean;
    canIssue?: boolean;
    canRespond?: boolean;
    canClose?: boolean;
    canCancel?: boolean;

    headerRows?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    alert: {
        padding: theme.spacing(2)
    }
}));

const QueryGrid: FunctionComponent<IQueryGridProps> = ({
    queries,
    queryRecipients,
    formTypes,
    formDefinitions,
    disabled,
    onClose,
    onIssue,
    onUpdate,
    onRespond,
    medicalReview,
    allowInsert,
    hideSection,
    hideReport,
    notifications,
    onFailure,
    defaultPropertyName,
    canView,
    canIssue,
    canClose,
    canCancel,
    onCancel,
    canRespond,

    headerRows
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        alert: {
            padding: theme.spacing(2)
        }
    }), [theme]);

    const [dialogState, setDialogState] = useState<DialogState | null>(null);

    const onDialogClose = useCallback(() => {
        setDialogState(currentState => ({
            ...currentState,
            mode: currentState?.mode ?? QueryStatus.Issued,
            dialogOpen: false
        }))
    }, [setDialogState])

    const onNewClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Issued,
            dialogOpen: true,
            formDefinition: undefined,
            formId: undefined,
            formType: undefined,
            propertyName: defaultPropertyName,
            query: undefined,
            queryRecipient: undefined,
        })
    }, [setDialogState, defaultPropertyName])

    const querySets = useMemo(() => {
        if (!headerRows) {
            return null;
        }

        return formDefinitions
            .map(formDefinition => {
                return {
                    formDefinition,
                    formType: formTypes.find(ft => ft.id === formDefinition.type),
                    queries: queries.filter(q => q.formDefinitionId === formDefinition.id)
                };
            })
            .filter(x => x.queries.length > 0)
            .sort((a, b) => {
                if ((a.formType?.id ?? 0) > (b.formType?.id ?? 0)) {
                    return 1;
                }


                if ((a.formType?.id ?? 0) < (b.formType?.id ?? 0)) {
                    return -1;
                }


                if (a.formDefinition.id! > b.formDefinition.id!) {
                    return 1;
                }

                if (a.formDefinition.id! < b.formDefinition.id!) {
                    return -1;
                }

                return 0;
            })
    }, [formTypes, formDefinitions, queries, headerRows]);

    const getNewQueryNumber = useCallback(() => {
        return (queries.filter(q => q.parentQueryId == null)?.length ?? 0) + 1;
    }, [queries]);

    const getRelatedQueries = useCallback((query: IQuery) => {
        return queries.filter(q => q.queryNumber == query.queryNumber
            && q.formDefinitionId == query.formDefinitionId
            && q.formId == query.formId);
    }, [queries]);

    if (!queries || !canView) {
        return null;
    }

    return (
        <>
            {
                !!queries?.length && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>
                                        Query Number
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        Status
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        Recipient
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        Query
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        Response
                                    </strong>
                                </TableCell>
                                {
                                    (!disabled || (!canIssue && !canRespond && !canClose)) && (
                                        <TableCell>
                                            <strong>

                                            </strong>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                headerRows && querySets && querySets.map(querySet => {
                                    return (
                                        <Fragment key={`formDefinition-${querySet.formDefinition.id}`}>
                                            <TableRow>
                                                <TableCell colSpan={disabled ? 5 : 6}>
                                                    <Typography variant="h6">
                                                        {querySet.formType?.name} &ndash; {querySet.formDefinition?.name}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {
                                                querySet.queries.map(query => {
                                                    return (
                                                        <QueryGridRow
                                                            key={query.id ?? 'insert'}
                                                            query={query}
                                                            relatedQueries={getRelatedQueries(query)}
                                                            queryRecipients={queryRecipients}
                                                            formTypes={formTypes}
                                                            formDefinitions={formDefinitions}
                                                            disabled={disabled}
                                                            setDialogState={setDialogState}
                                                            canIssue={canIssue}
                                                            canRespond={canRespond}
                                                            canClose={canClose}
                                                            canCancel={canCancel}
                                                        />
                                                    );
                                                })
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                !headerRows && queries.map(query => {
                                    return (
                                        <QueryGridRow
                                            key={query.id ?? 'insert'}
                                            query={query}
                                            relatedQueries={getRelatedQueries(query)}
                                            queryRecipients={queryRecipients}
                                            formTypes={formTypes}
                                            formDefinitions={formDefinitions}
                                            disabled={disabled}
                                            setDialogState={setDialogState}
                                            canIssue={canIssue}
                                            canRespond={canRespond}
                                            canClose={canClose}
                                            canCancel={canCancel}
                                        />
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                )
            }
            {
                !queries?.length && (
                    <Box sx={styles.alert}>
                        <BasicAlert
                            title="No Queries"
                            description="There are no queries associated with this report."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </Box>
                )
            }
            {
                !disabled && allowInsert && canIssue && (
                    <QueryGridFab
                        onClick={onNewClick}
                        count={queries?.length}
                    />
                )
            }
            {
                dialogState?.dialogOpen && (
                    <QueryDialog
                        dialogOpen={dialogState?.dialogOpen}
                        onClose={onClose}
                        onIssue={onIssue}
                        onUpdate={onUpdate}
                        onRespond={onRespond}
                        onCancel={onCancel}
                        onDialogClose={onDialogClose}
                        formDefinition={dialogState?.formDefinition}
                        formId={dialogState?.formId}
                        formType={dialogState?.formType}
                        medicalReview={medicalReview}
                        propertyName={dialogState?.propertyName}
                        query={dialogState?.query}
                        queryRecipient={dialogState?.queryRecipient}
                        queryRecipients={queryRecipients}
                        mode={dialogState?.mode ?? QueryStatus.Issued}
                        formDefinitions={formDefinitions}
                        formTypes={formTypes}
                        hideReport={hideReport}
                        hideSection={hideSection}
                        notifications={notifications}
                        onFailure={onFailure}
                        getNewQueryNumber={getNewQueryNumber}
                    />
                )
            }
        </>
    );
};

export default QueryGrid;
