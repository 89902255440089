import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import { IUpdatedFormProperty, UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId, UpdatedFormPropertyCollectionResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TUpdatedFormProperty extends IUpdatedFormProperty = IUpdatedFormProperty>(url: string, forms: Forms, formDefinitionId?: number, formId?: number) => {
    const response = await forms.serviceStackClient.get(new UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId({ formDefinitionId, formId }));

    return response;
}

const get = async (forms: Forms, patientId: number, repeat: number) => {
}

const useUpdatedFormPropertiesByFromDefinitionIdAndFormId = <TUpdatedFormProperty extends IUpdatedFormProperty = IUpdatedFormProperty>(formDefinitionId?: number | null, formId?: number | null, configuration?: SWRConfiguration<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!formDefinitionId || !formId) {
            return null;
        }

        return [`${forms.baseRoute}/updated-form-property/collection/form-definition-id-form-id`, forms, formDefinitionId, formId]
    }, [forms, formDefinitionId, formId])

    const { data, error } = useSWR<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<UpdatedFormPropertyCollectionResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);



    const result = useMemo(() => {
        return {
            data: data?.data as TUpdatedFormProperty[] | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useUpdatedFormPropertiesByFromDefinitionIdAndFormId;