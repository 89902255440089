import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormDefinitionCollectionResponse, FormDefinitionGetCollection } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';
import { Forms } from '../../Forms';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms) => {
    const response = await forms.serviceStackClient.get(new FormDefinitionGetCollection());

    return response;
}

const useFormDefinitions = (configuration?: SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>([`${forms.baseRoute}/forms-definition/collection`, forms], fetcher, configuration);

    const boundMutate: BoundMutator<FormDefinitionCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/forms-definition/collection`, forms], data, shouldRevalidate);
    }, [forms])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useFormDefinitions;