import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Form, IFormLabel, GroupedField, IGroupedFieldStyleProps, TextDisplay, ProgressButton } from '@ngt/forms';
import { IMedicalReviewDefinition, IReviewer, IMedicalReview } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

interface IUpdateMedicalReviewFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => Promise<any>;
}


const labels: IFormLabel[] = [
    {
        name: 'institution.name',
        label: 'Institution',
        detailedLabel: 'Institution'
    },
    {
        name: 'patient.studyNumber',
        label: 'Patient',
        detailedLabel: 'Patient'
    },
    {
        name: 'medicalReviewDefinition.name',
        label: 'Medical Review Type',
        detailedLabel: 'Medical Review Type'
    },
    {
        name: 'reviewer.name',
        label: 'Reviewer',
        detailedLabel: 'Reviewer'
    }
];

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 5,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const UpdateMedicalReviewFormDialog: FunctionComponent<IUpdateMedicalReviewFormDialogProps> = ({
    open,
    onClose,
    onSubmit
}) => {
    const [updating, setUpdating] = useState(false);

    const handleSubmit = useCallback(async () => {
        setUpdating(true);
        await onSubmit();
        onClose();
        setUpdating(false);
    }, [onClose, onSubmit, setUpdating])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Update Medical Review Report
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }} dividers>
                <BasicAlert
                    title="Please Note"
                    description={
                        <>
                            <p>Are you sure you wish to update this report?</p>
                        </>
                    }
                    icon={faInfoCircle}
                    severity="info"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={updating}>
                    Cancel
                </Button>
                <ProgressButton onClick={handleSubmit} color="primary" loading={updating}>
                    Update
                </ProgressButton>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateMedicalReviewFormDialog;
