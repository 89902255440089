/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import React, { useContext, Context } from 'react';

/**
 * typings
 */
import FormsContext from '../../contexts/FormsContext';
import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';
import FormContext from '../../contexts/data/FormContext';
import { IFormLabel } from '../../contexts/form/LabelsContext';
import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';
import LookupsContext from '../../contexts/utility/LookupsContext';
import {  IForm, IValidationResult } from '../../api/dtos';
import ISaveModel from '../../utilities/ISaveModel';
import IValidateModel from '../../utilities/IValidateModel';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/


/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/


export interface IUseFormOptions<Type extends IForm> {
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useFormByContext = <TForm extends IForm = IForm, TValidationResult extends IValidationResult = IValidationResult>() => {


    // Get form name from the DTO class.

    const { data: formDefinition, loading: formDefinitionLoading } = useContext(FormDefinitionContext);
    const { data: lookups, loading: lookupsLoading } = useContext(LookupsContext);
    const { data: form, loading: formLoading, save: saveForm, validate: validateForm  } = useContext(FormContext);

    const formDefinitionCode = React.useMemo(() => {
        return formDefinition?.code;
    }, [formDefinition])

    // Pull in Forms Context to get form meta data and DTO collection.
    const formsContext = React.useContext(FormsContext);

    // Get formDefinitionId using form name and form meta data.
    const formName = React.useMemo(() => {
        return formsContext.formMetadata?.find(fm => fm.formDefinitionCode === formDefinitionCode)?.name;
    }, [formDefinitionCode, formsContext.formMetadata]);

    // If form definition ID is not found in meta data this means that the form is not registered in the Forms.
    if (!formName) {
        throw new Error('Form is not registered in Forms.')
    }

    const formLabels = React.useMemo(() => {
        const labels: IFormLabel[] = [];

        formDefinition?.properties?.forEach(p => labels.push({
            name: pascalToCameCasePropertyPath(p.propertyName),
            label: p.label,
            detailedLabel: p.detailedLabel
        }));

        if (formDefinition?.subformProperties) {
            Object.keys(formDefinition.subformProperties).forEach(key => {
                formDefinition.subformProperties[key].forEach(p => labels.push({
                    name: pascalToCameCasePropertyPath(`${key}.${p.propertyName}`),
                    label: p.label,
                    detailedLabel: p.detailedLabel
                }));
            })
        }

        return labels;
    }, [pascalToCameCasePropertyPath, formDefinition])


    // swap lookup property paths to camel case from pascal case.
    const processedLookups = React.useMemo(() => {
        return lookups?.map(lookup => ({ ...lookup, propertyName: pascalToCameCasePropertyPath(lookup.propertyName) }))
    }, [lookups, pascalToCameCasePropertyPath]);



    return {
        form: form as TForm | null,
        formDefinition,
        formName: formDefinition?.name,
        labels: formLabels,
        lookups: processedLookups,
        loading: lookupsLoading || formDefinitionLoading || formLoading,
        saveForm: saveForm as unknown as ISaveModel<TForm>,
        validateForm: validateForm as unknown as IValidateModel<TForm, TValidationResult>
    }
};

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useFormByContext;

