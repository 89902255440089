import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext, IValidationResult } from '@ngt/forms';
import { IMasterGroup, MasterGroupGetSingleById, MasterGroupPostCreate, MasterGroupPostSave, MasterGroupPostValidate, MasterGroupSingleResponse, MasterGroupValidationResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TMasterGroup extends IMasterGroup = IMasterGroup>(url: string, forms: Forms, id?: number, data?: TMasterGroup, metadata?: Record<string, any>) => {
    if (!id) {
        return await create<TMasterGroup>(forms, data, metadata);
    }

    return await get<TMasterGroup>(forms, id)
}

const create = async <TMasterGroup extends IMasterGroup = IMasterGroup>(forms: Forms, data?: TMasterGroup, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new MasterGroupPostCreate({ data, metadata }));

    return response;
}

const get = async <TMasterGroup extends IMasterGroup = IMasterGroup>(forms: Forms, id: number) => {
    const response = await forms.serviceStackClient.get(new MasterGroupGetSingleById({ id }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TMasterGroup extends IMasterGroup = IMasterGroup>(forms: Forms, data: TMasterGroup, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new MasterGroupPostSave({ data, metadata }));

    return response;
}

const validate = async <TMasterGroup extends IMasterGroup = IMasterGroup, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, data: TMasterGroup, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new MasterGroupPostValidate({ data, metadata }));

    return response;
}

const useMasterGroupById = <TMasterGroup extends IMasterGroup = IMasterGroup, TValidationResult extends IValidationResult = IValidationResult>(id?: number | null, createData?: TMasterGroup, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!id && !createData && !createMetadata) {
            return null;
        }

        if (!id) {
            return [`${forms.baseRoute}/master-group/single/id`, forms, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/master-group/single/id`, forms, id, null, null]
    }, [forms, id, createData, createMetadata])

    const { data, error } = useSWR<MasterGroupSingleResponse, MasterGroupSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<MasterGroupSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TMasterGroup, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TMasterGroup>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TMasterGroup, metadata?: Record<string, any>) => {
        const response = await validate<TMasterGroup, TValidationResult>(forms, validateData, metadata);

        return response?.validationResult;
    }, [boundMutate, forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TMasterGroup | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useMasterGroupById;