/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';

import { Theme, InputAdornment, InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';

import { MobileDatePicker as MuiMobileDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'

import { DateType } from '@date-io/type';

import { DateTime } from 'luxon';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps, useFormErrorBoundary } from '@ngt/forms-core';

import FormOptionsContext from '../../../contexts/form/FormOptionsContext';
import { makeStyles } from '../../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
type DatePickerProps = Partial<Omit<MuiDatePickerProps, 'type'>> & IInputRenderProps<string, Dtos.IValidationError>

export interface IMobileDatePickerProps extends DatePickerProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    fullWidth?: boolean;
    inputFormat?: string;
    inputProps?: InputBaseComponentProps;
    renderProps?: TextFieldProps;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const MobileDatePicker: React.FunctionComponent<IMobileDatePickerProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, touch, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    clearable,
    inputFormat,
    InputProps,
    inputProps,
    disabled,
    hasErrors,
    errors,
    renderProps,
    ...rest
}) => {

    const [open, setOpen] = React.useState(false);

    const onChangeCombined = React.useCallback((date: DateTime | null) => {
        if (onChange) {
            onChange(date);
        }

        onInputChange(date?.toISO({ includeOffset: false }) as any, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (open) {
            return;
        }

        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
        touch();
    }, [inputProps?.onBlur, onBlur, open]);

    const derivedValue = React.useMemo(() => {
        if (!value) {
            return null;
        }

        return DateTime.fromISO(value);
    }, [value])

    const DerivedInputProps = React.useMemo(() => {
        if (!InputProps) {
            return {
                endAdornment: (
                    <InputAdornment
                        position="end"
                    >
                        <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />
                    </InputAdornment>
                )
            };
        }

        if (InputProps.endAdornment !== undefined) {
            return InputProps;
        }

        return {
            ...InputProps,
            endAdornment: (
                <InputAdornment
                    position="end"
                >
                    <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />
                </InputAdornment>
            )
        }
    }, [InputProps]);

    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);


    return (
        <MuiMobileDatePicker
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            inputFormat={inputFormat === undefined ? 'dd/MM/yyyy' : inputFormat}
            clearable={clearable === undefined ? true : clearable}
            InputProps={DerivedInputProps}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            onOpen={onOpen}
            onClose={onClose}
            name={name}
            value={derivedValue}
            onChange={onChangeCombined}
            disabled={disabled}
            renderInput={props => <TextField
                {...renderProps}
                onBlur={onBlurCombined}
                onFocus={onFocusCombined}
                {...props}
                error={hasErrors}
                helperText={errors && errors.length > 0 && errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
            />}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MobileDatePicker;
