/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { IFormSubmitValidationFailed, IFormSubmitFailed, IFormSubmit, IFormContext, useScopedField, useFormState} from '@ngt/forms-core';
import { ContextForm, IContextFormProps, IValidationError, IValidationResult, SubmitButton, useSnackbar, titleCase, FormDefinitionContext, ValidationErrorType, FormContext, FieldProvider, GroupedErrorDisplay } from '@ngt/forms';
import { IMedicalReviewForm, MedicalReviewPermission, MedicalReviewStatus } from '../../api/dtos';
import { AlertTitle } from '@mui/lab';
import pluralize from 'pluralize';
import { Button, SxProps, Theme, styled, useTheme } from '@mui/material';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { useNavigate } from 'react-router-dom';
import useContextPermissions from '../../hooks/utility/useContextPermissions';
import { PatientContext } from '@ngt/forms-trials';
import UpdateMedicalReviewFormDialog from '../dialog/UpdateMedicalReviewFormDialog';
import { makeStyles } from '../../styles/makeStyles';
import { Box } from '@mui/material';
import NextButton from './NextButton';
import NextButtonSubmit from './NextButtonSubmit';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: React.ReactNode;
//}

/**
 * This interface defines the properties for the Form component.
 */
export interface IContextMedicalReviewFormProps<TData extends IMedicalReviewForm = IMedicalReviewForm, TValidationResult extends IValidationResult = IValidationResult> extends IContextFormProps<TData, TValidationResult> {
}
/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles()((theme: Theme) => ({
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    margin: {
        marginLeft: theme.spacing(2)
    }
}));

const subscription = { value: true };

interface ICompleteButton {
    entityName: string;
    disableEntityLowerCase?: boolean;
    disableEntityTitleCase?: boolean;
}

const CompleteButton: FunctionComponent<ICompleteButton> = ({
    entityName,
    disableEntityLowerCase,
    disableEntityTitleCase
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            buttonGroup: {
                padding: theme.spacing(2),
                textAlign: 'right',
            },
            margin: {
                marginLeft: theme.spacing(2)
            } 
        } 
    }, [theme]);

    const {submitting} = useFormState({submitting: true})

    const [updateOpen, setUpdateOpen] = useState(false);

    const onUpdateDialogOpen = useCallback(() => {
        setUpdateOpen(true);
    }, [setUpdateOpen]);

    const onUpdateDialogClose = useCallback(() => {
        setUpdateOpen(false);
    }, [setUpdateOpen]);

    const formContext = useContext(FormContext);

    const form = formContext.data;

    const update = (formContext as any).update;

    const { enqueueSnackbar } = useSnackbar();


    const onUpdateClick = useCallback(async () => {
        const entityTitleCase = !disableEntityTitleCase ? titleCase(entityName) : entityName;
        const entityLowerCase = !disableEntityLowerCase ? entityName?.toLowerCase() : entityName;

        try {
            await update(form, undefined);
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Updated
                    </AlertTitle>
                    {!disableEntityLowerCase ? 'The' : ''} {entityLowerCase} was successfully updated.
                </>,
                { variant: 'success' }
            );
        }
        catch {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Updated
                    </AlertTitle>
                    An error occured while attempting to update {!disableEntityLowerCase ? 'the ' : ''}{entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [form, update, entityName, disableEntityTitleCase, disableEntityLowerCase]);

    const onSubmitClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IMedicalReviewForm, IValidationError>) => {
        formActions?.setFieldValue('medicalReviewStatus', MedicalReviewStatus.Complete, false, false, false, false);
        formActions?.setFieldValue('FORM_NEXT', false, true, true, true, false);
    }, []);

    const { state: { value } } = useScopedField<MedicalReviewStatus>('medicalReviewStatus', subscription, false);

    const { data: [canCompleteMedicalReviewForm, canUpdateMedicalReviewForm] } = useContextPermissions([MedicalReviewPermission.CompleteMedicalReviewForm, MedicalReviewPermission.UpdateMedicalReview]);

    if (value === MedicalReviewStatus.Cancelled) {
        return null;
    }

    if (!canUpdateMedicalReviewForm &&
        !(canCompleteMedicalReviewForm && value !== MedicalReviewStatus.Complete)) {
        return null;
    }

    return (
        <>
            {
                canUpdateMedicalReviewForm && (
                    <UpdateMedicalReviewFormDialog
                        open={updateOpen}
                        onClose={onUpdateDialogClose}
                        onSubmit={onUpdateClick}
                    />
                )
            }
            <Box sx={styles.buttonGroup}>
                {
                    canUpdateMedicalReviewForm && (
                        <Button onClick={onUpdateDialogOpen} variant="contained" color="primary">
                            Update Report
                        </Button>
                    )
                }
                {
                    ((value !== MedicalReviewStatus.Complete && canCompleteMedicalReviewForm) || (submitting === true && canCompleteMedicalReviewForm)) && (
                        <SubmitButton onClick={onSubmitClick} sx={styles.margin} variant="contained" color="primary">
                            Mark Review Complete
                        </SubmitButton>
                    )
                }
                {
                    ((value !== MedicalReviewStatus.Complete && canCompleteMedicalReviewForm) || (submitting === true && canCompleteMedicalReviewForm)) && <NextButtonSubmit />
                }
                <NextButton />
            </Box>
        </>
    )
}

const ContextMedicalReviewForm = <TData extends IMedicalReviewForm = IMedicalReviewForm, TValidationResult extends IValidationResult = IValidationResult>({
    entity,
    onSubmitFailed,
    onSubmitValidationFailed,
    children,
    onAfterSubmit,
    disableEntityLowerCase,
    disableEntityTitleCase,
    ...formProps
}: IContextMedicalReviewFormProps<TData, TValidationResult>) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: form } = useContext(FormContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: patient } = useContext(PatientContext);
    const { createMedicalReviewRouteFn } = useContext(MedicalReviewExtensionContext);
    const typedForm: TData = form as any;

    const entityName = useMemo(() => {
        return entity ?? formDefinition?.name ?? 'Unknown';
    }, [formDefinition, entity]);

    const onAfterSubmitToUse: IFormSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onAfterSubmit) {
            return onAfterSubmit(formState, formActions)
        }

        

        if (
            patient && 
            medicalReview && 
            formState.value?.medicalReviewStatus === MedicalReviewStatus.Complete &&
            formActions?.getFieldValue<boolean>("FORM_NEXT")
        ) {
            
            navigate(createMedicalReviewRouteFn(patient, medicalReview))
        }
    }, [createMedicalReviewRouteFn, patient, medicalReview, onAfterSubmit, navigate])

    const onSubmitValidationFailure: IFormSubmitValidationFailed<TData, IValidationError> = useCallback(async (formState, formActions, validationError: boolean) => {
        formActions?.setFieldValue('medicalReviewStatus', typedForm?.medicalReviewStatus, false, false, false, false);

        if (onSubmitValidationFailed) {
            return onSubmitValidationFailed(formState, formActions, validationError);
        }

        const entityTitleCase = !disableEntityTitleCase ? titleCase(entityName) : entityName;
        const entityLowerCase = !disableEntityLowerCase ? entityName?.toLowerCase() : entityName;

        const { errors } = formState;

        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate {!disableEntityLowerCase ? 'the ' : ''}{entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type !== ValidationErrorType.Warning) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit {!disableEntityLowerCase ? 'the ' : ''}{entityLowerCase} again.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [enqueueSnackbar, entityName, onSubmitValidationFailed, typedForm, disableEntityTitleCase, disableEntityLowerCase]);

    const onFormSubmitFailure: IFormSubmitFailed<TData, IValidationError> = useCallback(async (formState, formActions) => {
        formActions?.setFieldValue('medicalReviewStatus', typedForm?.medicalReviewStatus, false, false, false, false);

        if (onSubmitFailed) {
            return onSubmitFailed(formState, formActions, null);
        }

        formActions.reset(true);

        const entityTitleCase = !disableEntityTitleCase ? titleCase(entityName) : entityName;
        const entityLowerCase = !disableEntityLowerCase ? entityName?.toLowerCase() : entityName;

        enqueueSnackbar(
            <>
                <AlertTitle>
                    {entityTitleCase} Not Saved
                </AlertTitle>
                An error occurred while attempting to save {!disableEntityLowerCase ? 'the ' : ''}{entityLowerCase}.
            </>,
            { variant: 'error-critical' }
        );
    }, [onSubmitFailed, entityName, typedForm, enqueueSnackbar, disableEntityTitleCase, disableEntityLowerCase]);

    return (
        <ContextForm
            {...formProps}
            entity={entity}
            disableEntityLowerCase={disableEntityLowerCase}
            disableEntityTitleCase={disableEntityTitleCase}
            onSubmitFailed={onFormSubmitFailure}
            onSubmitValidationFailed={onSubmitValidationFailure}
            onAfterSubmit={onAfterSubmitToUse}
        >
            {children}
            <FieldProvider name="medicalReviewStatus" />
            <GroupedErrorDisplay />
            <CompleteButton entityName={entityName} disableEntityLowerCase={disableEntityLowerCase} disableEntityTitleCase={disableEntityTitleCase} />
        </ContextForm>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextMedicalReviewForm;