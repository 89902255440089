/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { useCallback, useMemo, useState } from 'react';

import { Typography, Collapse, Button } from '@mui/material';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { Form as FormCore, IFormProps as IFormBaseProps} from '@ngt/forms-core';

import FormErrors from './debug/FormErrors';

import FormInitialValues from './debug/FormInitialValues';

import FormValues from './debug/FormValues';

import FormTouched from './debug/FormTouched';

import FormDirty from './debug/FormDirty';

import FormFocused from './debug/FormFocused';

import { ILookup, IValidationError } from '../../api/dtos';

import FormState from './debug/FormState';

import LabelsContext, { IFormLabel } from '../../contexts/form/LabelsContext';

import FormLabels from './debug/FormLabels';

import FormLookupsContext from '../../contexts/form/FormLookupsContext';

import FormLookups from './debug/FormLookups';
import FormErrorHandler from './FormErrorHandler';
import FormFields from './debug/FormFields';
import FormOptionsContext, { FieldErrorOption } from '../../contexts/form/FormOptionsContext';
import { IDtoClass } from '../..';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: ReactNode;
//}

type OmitCalculatedTypes<T> = Omit<T, ''>

/**
 * This interface defines the properties for the Form component.
 */
export interface IFormProps<TData extends object> extends OmitCalculatedTypes<IFormBaseProps<TData, IValidationError>> {
    lookups?: ILookup[]
    labels?: IFormLabel[]
    fieldErrors?: FieldErrorOption;
    readOnly?: boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const Form = <TData extends object>({
    lookups,
    labels,
    fieldErrors,
    readOnly,
    children,
    ...formProps
}: IFormProps<TData>) => {

    const [showDebug, setShowDebug] = useState(false);

    const onShowDebugClick = useCallback(() => {
        setShowDebug(!showDebug);
    }, [showDebug, setShowDebug]);

    const formOptions = useMemo(() => {
        return {
            fieldErrors: fieldErrors !== undefined ? fieldErrors : FieldErrorOption.AutoShowAllErrors,
            readOnly: readOnly === true
        }
    }, [fieldErrors, readOnly]);

    return (
        <FormOptionsContext.Provider value={formOptions}>
            <FormLookupsContext.Provider value={lookups}>
                <LabelsContext.Provider value={labels}>
                    <FormCore
                        {...formProps}
                    >
                        <FormErrorHandler>
                            {children}
                            {
                                process.env.NODE_ENV !== 'production' && (
                                    <Button
                                        onClick={onShowDebugClick}
                                        color="secondary"
                                        fullWidth
                                    >
                                        Debug
                                    </Button>
                                )
                            }
                            <Collapse
                                in={showDebug}
                                mountOnEnter
                            >
                                {
                                    showDebug && (
                                        <>
                                            <FormState />
                                            <FormFields />
                                            <FormInitialValues />
                                            <FormValues />
                                            <FormTouched />
                                            <FormDirty />
                                            <FormFocused />
                                            <FormErrors />
                                            <FormLabels />
                                            <FormLookups />
                                        </>
                                    )
                                }
                            </Collapse>                        
                        </FormErrorHandler>
                    </FormCore>
                </LabelsContext.Provider>
            </FormLookupsContext.Provider>
        </FormOptionsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Form;