/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the current form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import { set } from 'lodash';
import * as React from 'react';
import { IFormContext } from './FormContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export enum ConditionMode {
    Enable,
    Show
}

export interface IConditionViewProps {
    conditionMet: boolean;
    mode: ConditionMode;
    children?: React.ReactNode;
}

export interface IConditionConfigurationContext<TValues extends object = any, TError = any> {
    onConditionNotMet?: (names: string[], formActions: IFormContext<TValues, TError>) => void;
    view: React.ComponentType<IConditionViewProps>;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const DefaultConditionView: React.FunctionComponent<IConditionViewProps> = ({
    conditionMet,
    mode,
    children
}) => {
    if (conditionMet || mode !== ConditionMode.Show) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        null
    );
};

export const DefaultConditionConfigurationContext: IConditionConfigurationContext = {
    onConditionNotMet: (names, formActions) => {
        formActions?.setValue((value: any) => {
            names.forEach(name => {
                set(value, name, undefined);
            })

            return value;
        }, true, true, false, false);

        formActions?.setTouched(touched => {
            names.forEach(name => {
                delete touched[name];
            })

            return touched;
        }, true);

        formActions?.setFocused(focused => {
            names.forEach(name => {
                delete focused[name];
            });

            return focused;
        }, true);
    },
    view: DefaultConditionView
}

const ConditionConfigurationContext = React.createContext<IConditionConfigurationContext>(DefaultConditionConfigurationContext);

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ConditionConfigurationContext;