/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { ChangeEvent, ComponentType, useCallback, useContext, useRef, useState, KeyboardEvent, MouseEvent, FunctionComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';

import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';

import { LinearProgress, Theme, darken, lighten, Button, Portal } from '@mui/material';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { IInputRenderProps } from '@ngt/forms-core';
import FormsContext from '../../../contexts/FormsContext';
import { IFileUpload, IValidationError, DeleteUpload, UploadResponse } from '../../../api/dtos';
import { makeStyles } from '../../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type FileUploadsProps = IInputRenderProps<IFileUpload, IValidationError>

export interface IFileUploadProps extends FileUploadsProps {
    onChange?: (value?: IFileUpload) => void;
    existingLink?: (value?: IFileUpload) => string;
    existingDownloadLink?: (value?: IFileUpload) => string;
    disabled?: boolean;
    errors?: IValidationError[];
    hasErrors?: boolean;
    view?: ComponentType<any>;
    ViewProps?: Record<string, any>;
}


export interface IFileUploadView {
    FileUploadViewProps: {
        name: string;
        uploading: boolean;
        error: boolean;
        progress?: number;
        value?: IFileUpload | null;
        onFailedReset: () => void;
        onUpload: () => void;
        onCancel: () => void;
        hasValidationErrors: boolean;
        validationErrors?: IValidationError[];
        disabled: boolean;
        viewLink?: string;
        downloadLink?: string;
        uploadViewLink?: string;
        uploadDownloadLink?: string;
    }
}
/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    input: {
        display: 'none'
    }
}));

const useViewStyles = makeStyles()((theme: Theme) => ({
    input: {
        display: 'none'
    },
    button: {
        padding: theme.spacing(0),
        cursor: 'pointer',
        height: 32
    },
    label: {
        width: '100%',
        padding: theme.spacing(0.75, 2),
        cursor: 'pointer'
    },
    buttonFailed: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,

        '&:hover': {
            background: darken(theme.palette.error.main, 0.20)
        }
    },
    uploadingButton: {
        cursor: 'pointer',
        padding: theme.spacing(0, 2)
    },
    progress: {
        height: theme.spacing(1.5)
    },
    icon: {
        minWidth: 0,
        padding: theme.spacing(0, 1.25),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: theme.spacing(2),

        '& > *': {
            opacity: 0.9
        }
    },
    container: {
        display: 'flex'
    },
    uploadContainer: {
        flex: '1 1 auto'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_FILE_UPLOAD_VIEW: FunctionComponent<IFileUploadView> = ({
    FileUploadViewProps: {
        name,
        error,
        onCancel,
        onFailedReset,
        onUpload,
        uploading,
        progress,
        value,
        hasValidationErrors,
        validationErrors,
        disabled,
        downloadLink,
        uploadDownloadLink,
        uploadViewLink,
        viewLink
    }
}) => {
    const { classes } = useViewStyles();

    const onUploadKeyPress = useCallback((event?: KeyboardEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        if (event?.charCode === 32) {
            onUpload();
        }
    }, [onUpload]);

    const onUploadClick = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        onUpload();
    }, [onUpload]);

    if (uploading) {
        return (
            <>
                <Button
                    variant="contained"
                    component="button"
                    className={classes.uploadingButton}
                    fullWidth
                    color="primary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <LinearProgress
                    className={classes.progress}
                    value={progress}
                    color="secondary"
                    variant="determinate"
                />
            </>
        );
    }

    if (error) {
        return (
            <Button
                variant="contained"
                component="button"
                fullWidth
                className={classes.buttonFailed}
                color="inherit"
                onClick={onFailedReset}
            >
                Upload Failed
            </Button>
        );
    }

    if (value?.upload) {
        return (
            <div
                className={classes.container}
            >
                <div
                    className={classes.uploadContainer}
                >
                    <Button
                        variant="contained"
                        component="button"
                        fullWidth
                        color="primary"
                        onClick={onCancel}
                    >
                        Remove Upload
                    </Button>
                </div>
                {
                    value.upload.image && (
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icon}
                            component="a"
                            href={uploadViewLink}
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                        </Button>
                    )
                }
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.icon}
                    component="a"
                    href={uploadDownloadLink}
                >
                    <FontAwesomeIcon icon={faDownload} fixedWidth />
                </Button>
            </div>
        );
    }

    return (
        <div
            className={classes.container}
        >
            <div
                className={classes.uploadContainer}
            >
                <Button
                    variant="contained"
                    className={classes.button}
                    component="button"
                    fullWidth
                    color="primary"
                    disabled={disabled}
                    onKeyPress={onUploadKeyPress}
                    onClick={onUploadClick}
                >
                    <label
                        htmlFor={name}
                        className={classes.label}
                    >
                        {
                            value?.exists ?
                                'Upload New' :
                                'Upload'
                        }
                    </label>
                </Button>
            </div>
            {
                !!value?.exists && !!value?.image && (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.icon}
                        component="a"
                        href={viewLink}
                        target="_blank"
                    >
                        <FontAwesomeIcon icon={faEye} fixedWidth />
                    </Button>
                )
            }
            {
                !!value?.exists && (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.icon}
                        component="a"
                        href={downloadLink}
                    >
                        <FontAwesomeIcon icon={faDownload} fixedWidth />
                    </Button>
                )
            }
        </div>
    );
};

const FileUpload: FunctionComponent<IFileUploadProps> = ({
    view,
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, touch, focus: onFocus, ...restInputActions } },
    onChange,
    disabled,
    existingLink,
    existingDownloadLink,
    errors,
    hasErrors,
    ViewProps,
    ...rest
}) => {
    const { classes } = useStyles();

    const forms = useContext(FormsContext);

    const [failed, setFailed] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [request, setRequest] = useState<XMLHttpRequest | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const onUpload = useCallback(() => {
        inputRef.current?.click();
    }, [inputRef])


    const onChangeCombined = useCallback((value?: IFileUpload) => {
        if (onChange) {
            onChange(value);
        }

        onInputChange(value, true, true, true);
    }, [onChange, onInputChange]);

    const onBlurCombined = useCallback(() => {
        onBlur();
        touch();
    }, [onChange, onInputChange]);

    const cancelUpload = useCallback(() => {
        request?.abort()
        setRequest(null);
        onChangeCombined(undefined);
        setUploading(false);
        setProgress(0);
        setFailed(false);

        if (value?.upload?.guid) {
            forms.serviceStackClient.delete(new DeleteUpload({ guid: value?.upload?.guid }))
        }
    }, [request, setRequest, setProgress, setUploading, onChangeCombined, value, forms.serviceStackClient])

    const processUploadResponse = useCallback((responseStr: string) => {
        try {
            const result: UploadResponse = JSON.parse(responseStr);

            if (!result.responseStatus && result.upload) {

                onChangeCombined({ ...value, upload: result.upload } as any);
                setUploading(false);
                setProgress(0);
                setRequest(null);
                setFailed(false);
            }
            else {
                setUploading(false);
                setProgress(0);
                setRequest(null);
                setFailed(true);
            }
        }
        catch {
            setUploading(false);
            setProgress(0);
            setRequest(null);
            setFailed(true);
        }
    }, [setUploading, setFailed, setProgress, setRequest, onChangeCombined, value])

    const uploadFile = useCallback((file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();

        if (xhr.upload) {
            xhr.upload.onprogress = (event) => {
                const done = (event as any).position || event.loaded;
                const total = (event as any).totalSize || event.total;

                const percentage = (Math.floor(done / total * 1000) / 10);

                setProgress(percentage);

                console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done / total * 1000) / 10) + '%');
            };
        }

        xhr.onreadystatechange = (event) => {
            if (4 === xhr.readyState) {
                if (xhr.status === 200) {
                    processUploadResponse(xhr.responseText);
                }
                else if (xhr.status !== 0) {
                    setUploading(false);
                    setFailed(true);
                    setProgress(0);
                }
            }
        };

        xhr.open('post', `${forms.baseRoute}/file-upload?format=json`, true);

        xhr.send(formData);

        setRequest(xhr);
    }, [onChangeCombined, setProgress, setFailed, setRequest, setUploading, processUploadResponse]);

    const onFileChange = useCallback((event?: ChangeEvent<HTMLInputElement>) => {
        cancelUpload();

        if (event?.target.value && event?.target.value !== '') {
            const file = event.target.files?.item(0);

            if (file) {
                setUploading(true);
                setProgress(0);
                onChangeCombined(undefined);
                setFailed(false);

                uploadFile(file)
                return;
            }
        }

        setUploading(false);
        setProgress(0);
        onChangeCombined(undefined);
        setFailed(false);
    }, [setUploading, onChangeCombined, setProgress, cancelUpload, setFailed, uploadFile])

    const onFailedClick = useCallback(() => {
        setFailed(false);
    }, [setFailed]);

    const View = view as any ?? DEFAULT_FILE_UPLOAD_VIEW;

    const viewLink = value?.exists ? existingLink?.(value) ?? `${forms.baseRoute}/file/view/${(value as any).id}` : undefined;
    const downloadLink = value?.exists ? existingDownloadLink?.(value) ?? `${forms.baseRoute}/file/download/${(value as any).id}` : undefined;
    const uploadViewLink = value?.upload ? `${forms.baseRoute}/file-upload/${value.upload.guid}` : undefined;
    const uploadDownloadLink = value?.upload ? `${forms.baseRoute}/file-upload/download/${value.upload.guid}` : undefined;

    return (
        <>
            <Portal>
                <input
                    ref={inputRef}
                    name={name}
                    id={name}
                    className={classes.input}
                    type="file"
                    onBlur={onBlurCombined}
                    onFocus={onFocus}
                    onChange={onFileChange}
                    disabled={disabled}
                />
            </Portal>
            <View
                {...ViewProps as any}
                FileUploadViewProps={{
                    name: name,
                    error: failed,
                    hasValidationErrors: hasErrors ?? false,
                    onCancel: cancelUpload,
                    onFailedReset: onFailedClick,
                    onUpload: onUpload,
                    uploading: uploading,
                    progress: progress,
                    validationErrors: errors,
                    value: value,
                    disabled: disabled ?? false,
                    downloadLink: downloadLink,
                    uploadDownloadLink: uploadDownloadLink,
                    uploadViewLink: uploadViewLink,
                    viewLink: viewLink
                }}
            />
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FileUpload;
