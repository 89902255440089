/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { FunctionComponent, ReactNode, useMemo } from 'react';

import { Theme, useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import GroupedFieldsContext from '../../../../contexts/form/GroupedFieldsContext';
import { makeStyles } from '../../../../utilities/makeStyles';
import { Box } from '@mui/material';
import {SxProps} from '@mui/system';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IGroupedFieldsProps extends IGroupedFieldsStyleProps {
}

interface IGroupedFieldsStyleProps {
    color?: 'Auto' | 'Inherit' | 'None';
    paddingTop?:  number;
    paddingBottom?: number;
    children?: ReactNode;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<{ props: IGroupedFieldsStyleProps }>()((theme: Theme, {props}) => ({
    container: {
        borderBottom: props.color === 'Auto' || !props.color ? `1px solid ${theme.palette.grey[300]}` : undefined,
        borderTop: props.color === 'Auto' || !props.color ? `1px solid ${theme.palette.grey[300]}` : undefined,
        marginTop: props.color === 'Auto' || !props.color ? -1 : undefined,

        paddingTop: props.paddingTop ?? theme.spacing(0),
        paddingBottom: props.paddingBottom ?? theme.spacing(0),

        '&:nth-child(even)': {
            background: props.color === 'Auto' || !props.color ? theme.palette.grey[100] : undefined
        },

        '&:nth-child(odd)': {
            background: props.color === 'Auto' || !props.color ? theme.palette.common.white : undefined
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const GroupedFields: FunctionComponent<IGroupedFieldsProps> = ({
    color,
    paddingTop,
    paddingBottom,
    children
}) => {
    const { classes } = useStyles({ props: { color, paddingBottom, paddingTop } });

    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            container: {
                borderBottom: color === 'Auto' || !color ? `1px solid ${theme.palette.grey[300]}` : undefined,
                borderTop: color === 'Auto' || !color ? `1px solid ${theme.palette.grey[300]}` : undefined,
                marginTop: color === 'Auto' || !color ? '-1px' : undefined,
        
                paddingTop: paddingTop ?? theme.spacing(0),
                paddingBottom: paddingBottom ?? theme.spacing(0),
        
                '&:nth-child(even)': {
                    background: `${color === 'Auto' || !color ? theme.palette.grey[100] : undefined} !important`
                },
        
                '&:nth-child(odd)': {
                    background: `${color === 'Auto' || !color ? theme.palette.common.white : undefined} !important`
                }
            }
        }
    }, [theme, color, paddingTop, paddingBottom])

    return (
        <Box
            sx={styles.container}
        >
            <GroupedFieldsContext.Provider value={true}>
                {children}
            </GroupedFieldsContext.Provider>
        </Box>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default GroupedFields;
