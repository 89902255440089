/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@mui/material/styles';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps, useFormErrorBoundary } from '@ngt/forms-core';

import { useFieldErrors } from '../../../hooks/form/useFieldErrors';
import FormOptionsContext, { FieldErrorOption } from '../../../contexts/form/FormOptionsContext';
import LabelContext from '../../../contexts/form/LabelContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type TEXT_FIELD_TYPE =
    | typeof TYPE_PASSWORD
    | typeof TYPE_TEXT
    | typeof TYPE_EMAIL
    | typeof TYPE_NUMBER
    | typeof TYPE_URL
    | typeof TYPE_TELEPHONE
    | typeof TYPE_DATE
    | typeof TYPE_DATETIME_LOCAL
    | typeof TYPE_MONTH
    | typeof TYPE_TIME
    | typeof TYPE_WEEK;

type TextFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<string, Dtos.IValidationError>

export interface ITextProps extends TextFieldsProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    labelDecoration?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

//const useStyles = makeStyles<Theme>((theme: Theme) => ({
//    text: {
//    }
//}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Text: React.FunctionComponent<ITextProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, touch, ...restInputActions } },
    fullWidth,
    onChange,
    inputProps,
    errors,
    hasErrors,
    ...rest
}) => {

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }

        onInputChange(event.target.value && event.target.value !== '' ? event.target.value : undefined as any, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
        touch();
    }, [inputProps?.onBlur, onBlur]);


    return (
        <MuiTextField
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            name={name}
            value={value ?? ''}
            onChange={onChangeCombined}
            error={hasErrors}
            helperText={errors && errors.length > 0 && errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Text;
