/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme } from '@mui/material/styles';

import { Radio as MuiRadio, RadioProps as MuiRadioProps, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps, FormControlLabel, FormControl, FormLabel, InputLabel, FormControlProps, FormHelperText, FormControlLabelProps } from '@mui/material';
import { SxProps } from '@mui/system';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FormLookupContext from '../../../contexts/form/FormLookupContext';

import { IInputRenderProps } from '@ngt/forms-core';
import FormOptionsContext from '../../../contexts/form/FormOptionsContext';
import { makeStyles } from '../../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type RadioGroupProps = Partial<MuiRadioGroupProps> & IInputRenderProps<any, Dtos.IValidationError>

export interface IRadioGroup extends Omit<RadioGroupProps, 'onChange'> {
    RadioProps?: MuiRadioProps;
    LabelProps?: Omit<FormControlLabelProps, 'label' | 'value' | 'control'>;
    disabled?: boolean;
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    label?: React.ReactNode;
    margin?: FormControlProps['margin'];
    fullWidth?: FormControlProps['fullWidth'];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, eventValue: any | null | undefined) => void;
    optionLabelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    optionLabelSx: SxProps<Theme>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    group: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const RadioGroup: React.FunctionComponent<IRadioGroup> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, touch, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    disabled,
    className,
    onBlur,
    onFocus,
    RadioProps,
    label,
    margin,
    hasErrors,
    errors,
    fullWidth,
    LabelProps,
    ...rest
}) => {
    const { classes } = useStyles();

    const lookup = React.useContext(FormLookupContext);
    
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, eventValue: string | null | undefined) => {
        let convertedValue: number | undefined = undefined;

        if (eventValue) {
            try {
                convertedValue = parseInt(eventValue);
            }
            catch {
                convertedValue = undefined;
            }
        }

        if (onChange) {
            onChange(event, convertedValue);
        }

        onInputChange(convertedValue, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
        touch();
    }, [onBlur, onInputBlur]);

    return (
        <FormControl
            error={hasErrors}
            margin={margin}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            disabled={disabled}
        >
            {label && <FormLabel>{label}</FormLabel>}
                <MuiRadioGroup
                {...rest as any}
                    name={name}
                    value={value?.toString() ?? null}
                    onChange={onChangeCombined}
                    onFocus={onFocusCombined}
                    onBlur={onBlurCombined}
                    className={className ?? ''}
                >
                    {
                        children ?? lookup?.items?.map(item => {
                            return (
                                <FormControlLabel
                                    {...LabelProps}
                                    sx={LabelProps?.sx}
                                    disabled={LabelProps?.disabled || disabled}
                                    key={item.id?.toString()}
                                    value={item.id?.toString()}
                                    control={<MuiRadio {...RadioProps} color={RadioProps?.color ? RadioProps?.color : 'primary'} />}
                                    label={item.value.toString()}
                                />
                            );
                        })
                    }
                </MuiRadioGroup>
            {
                errors && errors.length > 0 && (
                    <FormHelperText>
                        {errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default RadioGroup;
