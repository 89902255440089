/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component for the select icon.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

/**
 * Used to show icons.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * The chevron icon.
 */
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

/**
 * Used to style the icon.
 */
import { Theme } from '@mui/material';
import { makeStyles } from '../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ISelectIconProps {
    className?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useIconStyles = makeStyles()((theme: Theme) => ({
    icon: {
        top: 'calc(50% - 8px)'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const SelectIcon: React.FunctionComponent<ISelectIconProps> = ({ className }) => {
    const { classes } = useIconStyles();

    return (
        <FontAwesomeIcon icon={faChevronDown} className={`${className} ${classes.icon}`} fixedWidth />
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SelectIcon;
