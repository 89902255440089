/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme } from '@mui/material/styles';

import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormGroup, FormGroupProps, FormControlLabel, FormControl, FormLabel, InputLabel, FormControlProps, FormHelperText } from '@mui/material';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FormLookupContext from '../../../contexts/form/FormLookupContext';

import { IInputRenderProps } from '@ngt/forms-core';
import FormOptionsContext from '../../../contexts/form/FormOptionsContext';
import { makeStyles } from '../../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type CheckboxGroupProps = Partial<FormGroupProps> & IInputRenderProps<any[], Dtos.IValidationError>

export interface ICheckboxGroup extends Omit<CheckboxGroupProps, 'onChange'> {
    CheckboxProps?: MuiCheckboxProps;
    disabled?: boolean;
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    label?: React.ReactNode;
    margin?: FormControlProps['margin'];
    fullWidth?: FormControlProps['fullWidth'];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, eventValue: any[] | null | undefined) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    group: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const CheckboxGroup: React.FunctionComponent<ICheckboxGroup> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, touch, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    disabled,
    className,
    onBlur,
    onFocus,
    CheckboxProps,
    label,
    margin,
    hasErrors,
    errors,
    fullWidth,
    ...rest
}) => {
    const { classes } = useStyles();

    const lookup = React.useContext(FormLookupContext);
    
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = value ?? [];

        const checkboxValue = event.target.value;
        const item = lookup?.items?.find(x => x.id.toString() === checkboxValue);

        if (!item) {
            return;
        }

        const includesItem = newValue.includes(item?.id);

        if (!includesItem) {
            newValue = [...newValue, item.id];
        }
        else if (includesItem)
        {
            newValue = newValue.filter(x => x !== item.id);
        }

        if (onChange) {
            onChange(event, newValue);
        }

        onInputChange(newValue, true, true, true);
    }, [value, onChange, onInputChange, lookup]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
        
        touch();
    }, [onBlur, onInputBlur]);

    return (
        <FormControl
            error={hasErrors}
            margin={margin}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            disabled={disabled}
        >
            {label && <FormLabel>{label}</FormLabel>}
                <FormGroup
                    {...rest as any}
                    name={name}
                    value={value?.toString() ?? null}
                    onChange={onChangeCombined}
                    onFocus={onFocusCombined}
                    onBlur={onBlurCombined}
                    className={className ?? classes.group}
                >
                    {
                        children ?? lookup?.items?.map(item => {
                            return (
                                <FormControlLabel
                                    disabled={disabled}
                                    key={item.id?.toString()}
                                    value={item.id?.toString()}
                                    control={<MuiCheckbox {...CheckboxProps} color={CheckboxProps?.color ? CheckboxProps?.color : 'primary'} checked={value?.includes(item.id)} />}
                                    label={item.value.toString()}
                                />
                            );
                        })
                    }
                </FormGroup>
            {
                errors && errors.length > 0 && (
                    <FormHelperText>
                        {errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CheckboxGroup;
