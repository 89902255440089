import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { MedicalReviewCollectionResponse, MedicalReviewGetCollectionByPatientId } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, patientId?: number) => {
    const response = await forms.serviceStackClient.get(new MedicalReviewGetCollectionByPatientId({ patientId }));

    return response;
}

const useMedicalReviewsByPatientId = (patientId?: number, configuration?: SWRConfiguration<MedicalReviewCollectionResponse, MedicalReviewCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<MedicalReviewCollectionResponse, MedicalReviewCollectionResponse>(patientId ? [`${forms.baseRoute}/medical-review/collection/patient-id`, forms, patientId] : null, fetcher, configuration);

    const boundMutate: BoundMutator<MedicalReviewCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/medical-review/collection/patient-id`, forms, patientId], data, shouldRevalidate);
    }, [forms, patientId])

    const result = useMemo(() => {
        return {
            data: data?.data,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useMedicalReviewsByPatientId;