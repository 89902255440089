import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { Form, IFormType, IFormLabel, ILookup, FieldProvider, Select, CheckboxGroup, Checkbox, GroupedField, SubmitButton, ILookupFilterProps, IValidationError, ValidationErrorType, ILookupItem, IGroupedFieldStyleProps, FormsContext, useSnackbar, IFormDefinition, GroupedFields } from '@ngt/forms';
import { IMedicalReviewDefinition, IReviewer, MedicalReviewStatus, MedicalReviewPostCreateReview } from '../../api/dtos';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { IFormSubmit, IFormSubmitFailed, IFormValidate, useScopedField, ValidateOn } from '@ngt/forms-core';
import { AlertTitle } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

interface ICreateMedicalReviewDialogProps {
    medicalReviewDefinitions?: IMedicalReviewDefinition[];
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    reviewers?: IReviewer[];
    institution?: IInstitution;
    patient?: IPatient;
    open: boolean;
    onClose: () => void;
}

interface IInternalForm {
    getDefaultFormDefinitionIds: (id?: number) => number[] | undefined;
    singleDefinition: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        padding: 0,

        '&:first-of-type': {
            padding: 0
        }
    },
    settingsContainer: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',

        '&.Mui-expanded': {
            margin: 0
        }
    },
    settingsSummary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    settingsTitle: {
        padding: theme.spacing(0)
    },
    settingsContent: {
        display: 'block',
        padding: 0
    }
}));


const labels: IFormLabel[] = [
    {
        name: 'data.medicalReviewDefinitionId',
        label: 'Type',
        detailedLabel: 'Type'
    },
    {
        name: 'data.reviewerId',
        label: 'Assigned Reviewer',
        detailedLabel: 'Assigned Reviewer'
    },
    {
        name: 'options.formDefinitionIds',
        label: 'Included Reports',
        detailedLabel: 'Included Reports'
    },
    {
        name: 'options.includeUnchangedForms',
        label: 'Include Unchanged Forms',
        detailedLabel: 'Include Unchanged Forms'
    },
    {
        name: 'options.compareFormsAcrossMedicalReviewDefinitions',
        label: 'Compare Forms Across Review Types',
        detailedLabel: 'Compare Forms Across Review Types'
    }
];

const lookupFilters: Record<string, ILookupFilterProps> = {
    formDefinitionIds: {
        filterFunction: (formState, fieldName, lookup) => {

            if (!lookup) {
                return undefined;
            }

            return {
                ...lookup,
                items: lookup?.items?.filter(x => (x as any)?.parent === formState.value.data?.medicalReviewDefinitionId)
            }

        },
        subscription: { value: true }
    }
}

const formSubscriptions = {
    formDefinitionIds: { }
}

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 5,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const validate: IFormValidate<MedicalReviewPostCreateReview, IValidationError> = async (formState, formActions) => {
    let errors: Record<string, IValidationError[]> = {};

    if (!formState.value?.options?.formDefinitionIds?.length) {
        errors['options.formDefinitionIds'] = [
            {
                code: 'CMR-001',
                message: 'At least one report must be selected.',
                detailedMessage: 'At least one report must be selected.',
                property: 'options.formDefinitionIds',
                type: ValidationErrorType.Normal
            }
        ];
    }

    return errors;
}

const CreateMedicalReviewDialog: FunctionComponent<ICreateMedicalReviewDialogProps> = ({
    medicalReviewDefinitions,
    formTypes,
    formDefinitions,
    institution,
    patient,
    reviewers,
    open,
    onClose,
}) => {
    const { classes } = useStyles();

    const navigate = useNavigate();
    const forms = useContext(FormsContext);
    const medicalReviewContext = useContext(MedicalReviewExtensionContext);

    const { enqueueSnackbar } = useSnackbar();

    const availableReviewers = useMemo(() => {
        return reviewers?.filter(reviewer => {
            if (reviewer.onTrial) {
                return true;
            }

            if (institution?.masterGroupId && reviewer.masterGroups?.[institution.masterGroupId]) {
                return true;
            }

            if (institution?.coordinatingGroupId && reviewer.coordinatingGroups?.[institution.coordinatingGroupId]) {
                return true;
            }

            if (institution?.id && reviewer.institutions?.[institution.id]) {
                return true;
            }

            return false;
        });
    }, [institution, reviewers]);

    const lookups: ILookup[] = useMemo(() => {
        return [
            {
                propertyName: 'data.medicalReviewDefinitionId',
                items: medicalReviewDefinitions?.map((medicalReviewDefinition, i) => ({
                    id: medicalReviewDefinition.id,
                    value: medicalReviewDefinition.name,
                    order: i
                }))
            },
            {
                propertyName: 'data.reviewerId',
                items: availableReviewers?.map((reviewer, i) => ({
                    id: reviewer.id,
                    value: reviewer.name,
                    order: i
                }))
            },
            {
                propertyName: 'options.formDefinitionIds',
                items: medicalReviewDefinitions?.map(definition => {
                    return definition.availableFormDefinitionIds?.map((formDefinitionId, i) => {
                        const formDefinition = formDefinitions?.find(x => x.id === formDefinitionId);

                        return {
                            id: formDefinitionId,
                            value: formDefinition?.name ?? formDefinitionId,
                            order: i,
                            parent: definition.id
                        }
                    })
                }).reduce((a, b) => [...a, ...b], [])
            }
        ] as ILookup[];
    }, [availableReviewers, medicalReviewDefinitions, formDefinitions])

    const getDefaultFormDefinitionIds = useCallback((medicalReviewDefinitionId?: number) => {
        const medicalReviewDefinition = medicalReviewDefinitions?.find(x => x.id === medicalReviewDefinitionId);

        return medicalReviewDefinition?.defaultFormDefinitionIds
    }, [medicalReviewDefinitions])

    const initialValues: MedicalReviewPostCreateReview = useMemo(() => {
        const medicalReviewDefinition = medicalReviewDefinitions?.find(x => true);

        return {
            data: {
                id: undefined,
                enteredBy: undefined,
                enteredDate: undefined,
                modifiedBy: undefined,
                modifiedDate: undefined,
                status: MedicalReviewStatus.New,
                patientId: patient?.id,
                medicalReviewDefinitionId: medicalReviewDefinition?.id,
                reviewerId: availableReviewers?.find(x => true)?.id
            },
            metadata: {},
            options: {
                formDefinitionIds: medicalReviewDefinition?.defaultFormDefinitionIds ?? [],
                includeUnchangedForms: false
            }
        } as unknown as MedicalReviewPostCreateReview

    }, [medicalReviewDefinitions, availableReviewers, patient]);

    const onSubmit: IFormSubmit<MedicalReviewPostCreateReview, IValidationError> = useCallback(async (formState, formActions) => {
        const response = await forms.serviceStackClient.post(new MedicalReviewPostCreateReview(formState.value ?? undefined));

        if (response.hasNoForms) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Not Created
                    </AlertTitle>
                    No reports were included in the medical review.
                </>,
                { variant: 'error-low' }
            );
        }
        else if (response.hasNoChanges) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Not Created
                    </AlertTitle>
                    No reports have changed since the last completed medical review.
                </>,
                { variant: 'error-low' }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Created
                    </AlertTitle>
                    The medical review was successfully created.
                </>,
                { variant: 'success' }
            );

            navigate(medicalReviewContext.createMedicalReviewRouteFn(patient!, response.data))
        }

    }, [forms, enqueueSnackbar, navigate, medicalReviewContext, patient])


    const onSubmitFailed: IFormSubmitFailed<MedicalReviewPostCreateReview, IValidationError> = useCallback(async (formState, formActions) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Medical Review Not Created
                </AlertTitle>
                An error occurred while attempting to create the medical review.
            </>,
            { variant: 'error-critical' }
        );
    }, [enqueueSnackbar]);

    const noDefinitions = !medicalReviewDefinitions?.length;
    const noReviewers = !availableReviewers?.length;
    const singleDefinition = medicalReviewDefinitions?.length === 1;

    return (
        <Dialog open={open} onClose={onClose} fullWidth scroll="body" maxWidth="md">
            <DialogTitle>
                Create Medical Review
            </DialogTitle>
            {
                !noReviewers && !noDefinitions && (
                    <Form
                        initialValues={initialValues}
                        labels={labels}
                        lookups={lookups}
                        onValidate={validate}
                        validateOn={ValidateOn.onChange}
                        onSubmit={onSubmit}
                        onSubmitFailed={onSubmitFailed}
                    >
                        <DialogContent className={classes.content} dividers>
                            <InternalForm getDefaultFormDefinitionIds={getDefaultFormDefinitionIds} singleDefinition={singleDefinition} />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <SubmitButton color="primary">
                                Ok
                            </SubmitButton>
                        </DialogActions>
                    </Form>

                )
            }
            {
                (noReviewers || noDefinitions) && (
                    <>
                        <DialogContent className={classes.content} dividers>
                            {
                                noReviewers && (
                                    <BasicAlert
                                        title="No Medical Reviewer"
                                        description="There is no medical reviewer for this institution."
                                        icon={faExclamationTriangle}
                                        severity="warning"
                                    />
                                )
                            }
                            {
                                noDefinitions && (
                                    <BasicAlert
                                        title="No Medical Review Definition"
                                        description="There has been no medical review definition setup for this trial."
                                        icon={faExclamationTriangle}
                                        severity="warning"
                                    />
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </>

                )
            }
        </Dialog>
    );
};

const InternalForm: FunctionComponent<IInternalForm> = ({
    getDefaultFormDefinitionIds,
    singleDefinition
}) => {
    const { classes } = useStyles();
    const { context: { setValue: setFormDefinitionIdsValue } } = useScopedField<number[]>('options.formDefinitionIds', formSubscriptions.formDefinitionIds, false);

    const onMedicalReviewDefinitionChange = useCallback((event: ChangeEvent<{ name: string | undefined; value: number; }>, selectedItem: ILookupItem | undefined) => {
        setFormDefinitionIdsValue(getDefaultFormDefinitionIds(selectedItem?.id as any))
    }, [getDefaultFormDefinitionIds, setFormDefinitionIdsValue])

    return (
        <>
            <FieldProvider name="data" autoRegister={false}>
                {
                    !singleDefinition && (
                        <GroupedField
                            name="medicalReviewDefinitionId"
                            component={Select}
                            nullOption={false}
                            GroupStyleProps={groupStyleProps}
                            onChange={onMedicalReviewDefinitionChange}
                        />
                    )
                }
                <GroupedField
                    name="reviewerId"
                    component={Select}
                    nullOption={false}
                    GroupStyleProps={groupStyleProps}
                />
            </FieldProvider>
            <GroupedFields>
                <Accordion className={classes.settingsContainer} elevation={0} square>
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.settingsSummary}
                    >
                        <Typography variant="h2" className={classes.settingsTitle}>
                            Advanced Settings
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.settingsContent}>
                        <FieldProvider name="options" autoRegister={false}>
                            <GroupedField
                                LookupFilterProps={lookupFilters.formDefinitionIds}
                                name="formDefinitionIds"
                                component={CheckboxGroup}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="includeUnchangedForms"
                                component={Checkbox}
                                GroupStyleProps={groupStyleProps}
                            />
                            {
                                !singleDefinition && (
                                    <GroupedField
                                        name="compareFormsAcrossMedicalReviewDefinitions"
                                        component={Checkbox}
                                        GroupStyleProps={groupStyleProps}
                                    />
                                )
                            }
                        </FieldProvider>
                    </AccordionDetails>
                </Accordion>
            </GroupedFields>
        </>
    );
};

export default CreateMedicalReviewDialog;
