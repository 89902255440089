import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@mui/material';
import { Alert, AlertTitle, AlertProps } from '@mui/lab';
import { ResponseStatus } from '@servicestack/client';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '../../styles/makeStyles';


interface IRequestAlertProps {
    responseStatus: ResponseStatus
    icon?: IconProp;
    severity?: AlertProps['severity'];
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2)
    },
}));

const useAlertStyles = makeStyles()(() => ({
    message: {
        flex: '1 1 auto'
    },
}));

const RequestAlert: FunctionComponent<IRequestAlertProps> = ({
    responseStatus,
    icon,
    severity
}) => {
    const {classes} = useStyles();
    const {classes: alertClasses} = useAlertStyles();

    if (!responseStatus || (!responseStatus.message && !responseStatus.stackTrace)) {
        return null;
    }

    return (
        <Alert
            icon={icon ? <FontAwesomeIcon fixedWidth icon={icon} /> : undefined}
            severity={severity}
            classes={alertClasses}
        >
            {responseStatus.message && <AlertTitle className={classes.title}>{responseStatus.message}</AlertTitle>}
            {
                responseStatus.stackTrace && (
                    <pre>
                        {responseStatus.stackTrace}
                    </pre>
                )
            }
        </Alert>
    )
};

export default RequestAlert;