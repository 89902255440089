import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse, darken, lighten, ListItem, ListItemIcon, ListItemText, Theme, Typography, useTheme } from '@mui/material';
import React, { ComponentType, FunctionComponent, useCallback, useMemo } from 'react';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer, MedicalReviewStatus } from '../../api/dtos';
import MedicalReviewSummaryListForm from './MedicalReviewSummaryListForm';
import { faSlash } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewSummaryListTypeProps {
    patient?: IPatient;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    medicalReview?: IMedicalReview;
    reviewer?: IReviewer;
    expanded: boolean;
    toggleExpanded: (id: number) => void;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult, hasMultiple: boolean; }>;
    formType: IFormType;
    action?: ComponentType<IMedicalReviewSummaryFormTypeActionProps>;
}

export interface IMedicalReviewSummaryFormTypeActionProps {
    patient?: IPatient;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    medicalReview?: IMedicalReview;
    reviewer?: IReviewer;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult, hasMultiple: boolean; }>;
    formType: IFormType;
}

const useStyles = makeStyles()((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex',
            marginTop: -1
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        reviews: {
            paddingLeft: theme.spacing(6)
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: 4,
            paddingBottom: 4,
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        },
        inProgress: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
        },
        complete: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            borderColor: getBorderColor(theme.palette.success.main, 0.8),
        },
        completedForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        Boxider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        totalForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
});


const MedicalReviewSummaryListType: FunctionComponent<IMedicalReviewSummaryListTypeProps> = ({
    expanded,
    medicalReviewDefinition,
    medicalReview,
    reviewer,
    forms,
    formType,
    toggleExpanded,
    action,
    patient
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        const getColor = theme.palette.mode === 'light' ? darken : lighten;
        const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
        const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

        return {
            container: {
                display: 'flex',
                marginTop: '-1px'
            },
            item: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            },
            reviews: {
                paddingLeft: theme.spacing(6)
            },
            title: {
                display: 'flex',
                alignItems: 'center',
                paddingTop: '4px',
                paddingBottom: '4px',
            },
            titleMain: {
                marginRight: 'auto'
            },
            titleCount: {
                alignSelf: 'stretch',
                minWidth: '140px',
                display: 'flex',
                padding: theme.spacing(0, 2),
                borderWidth: '1px',
                borderStyle: 'solid',
                justifyContent: 'space-between',

                borderRadius: 0,

                color: getColor(theme.palette.grey[700], 0.6),
                backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
                borderColor: getBorderColor(theme.palette.grey[700], 0.8),
            },
            new: {
                color: getColor(theme.palette.info.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
                borderColor: getBorderColor(theme.palette.info.main, 0.8),
            },
            inProgress: {
                color: getColor(theme.palette.warning.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
                borderColor: getBorderColor(theme.palette.warning.main, 0.8),
            },
            complete: {
                color: getColor(theme.palette.success.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
                borderColor: getBorderColor(theme.palette.success.main, 0.8),
            },
            completedForms: {
                maxWidth: 5,
                minWidth: 5,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            Boxider: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            totalForms: {
                maxWidth: 5,
                minWidth: 5,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }
        }
    }, [theme]);

    const onClick = useCallback(() => {
        toggleExpanded(formType.id!)

    }, [formType.id, toggleExpanded]);

    const ActionComponent = action;

    const count = useMemo(() => {
        const totalForms = forms.filter(x => x.formValidation?.metadata?.Status !== MedicalReviewStatus.Cancelled)?.length ?? 0;
        const inProgressForms = forms.filter(x => x.formValidation?.metadata?.Status === MedicalReviewStatus.InProgress)?.length ?? 0;
        const completeForms = forms.filter(x => x.formValidation?.metadata?.Status === MedicalReviewStatus.Complete)?.length ?? 0;

        if (totalForms === 0) {
            return null;
        }

        const containerStyle = completeForms === 0 ? styles.new 
            : inProgressForms > 0 && completeForms !== totalForms ? styles.inProgress 
            : completeForms === totalForms ? styles.complete : null;


        return (
            <Box
                sx={{...styles.titleCount, ...containerStyle}}
            >
                <Box
                    sx={styles.completedForms}
                >
                    {completeForms}
                </Box>
                <Box
                    sx={styles.Boxider}
                >
                    <FontAwesomeIcon fixedWidth icon={faSlash} flip="horizontal" />
                </Box>
                <Box
                    sx={styles.totalForms}
                >
                    {totalForms}
                </Box>
            </Box>
        )
    }, [forms, classes])

    return (
        <>
            <Box
                sx={styles.container}
            >
                <ListItem
                    button
                    sx={styles.item}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Box sx={styles.title}>
                                <Typography variant="subtitle1" sx={styles.titleMain}>
                                    <strong>{formType?.name}</strong>
                                </Typography>
                            </Box>
                        )}
                    />
                    {count}
                </ListItem>
                {
                    !!ActionComponent && !!formType && (
                        <ActionComponent
                            patient={patient}
                            medicalReviewDefinition={medicalReviewDefinition}
                            medicalReview={medicalReview}
                            forms={forms}
                            formType={formType}
                            reviewer={reviewer}
                        />
                    )
                }
            </Box>
            <Collapse
                in={expanded}
            >
                <Box
                    sx={styles.reviews}
                >
                    {
                        !!medicalReviewDefinition && !!forms?.length && (
                            forms?.map((form) => {
                                return (
                                    <MedicalReviewSummaryListForm
                                        key={`${form.formDefinition.id}-${form.formValidation?.metadata?.Id?.toString()}`}
                                        patient={patient}
                                        medicalReviewDefinition={medicalReviewDefinition}
                                        medicalReview={medicalReview}
                                        formValidation={form.formValidation}
                                        formDefinition={form.formDefinition}
                                        hasMultiple={form.hasMultiple}
                                        formType={formType}
                                        reviewer={reviewer}
                                    />
                                );
                            })
                        )
                    }
                </Box>
            </Collapse>
        </>
    );
};

export default MedicalReviewSummaryListType;
