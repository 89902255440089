import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import { IMedicalReview, MedicalReviewGetSingleByPatientIdAndRepeat, MedicalReviewPostCreate, MedicalReviewPostSave, MedicalReviewPostValidate, MedicalReviewSingleResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TMedicalReview extends IMedicalReview = IMedicalReview>(url: string, forms: Forms, patientId?: number, repeat?: number, data?: TMedicalReview, metadata?: Record<string, any>) => {
    if (!patientId || !repeat) {
        return await create<TMedicalReview>(forms, data, metadata);
    }

    return await get(forms, patientId, repeat)
}

const create = async <TMedicalReview extends IMedicalReview = IMedicalReview>(forms: Forms, data?: TMedicalReview, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new MedicalReviewPostCreate({ data, metadata }));

    return response;
}

const get = async (forms: Forms, patientId: number, repeat: number) => {
    const response = await forms.serviceStackClient.get(new MedicalReviewGetSingleByPatientIdAndRepeat({ patientId, repeat }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TMedicalReview extends IMedicalReview = IMedicalReview>(forms: Forms, data: TMedicalReview, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new MedicalReviewPostSave({ data, metadata }));

    return response;
}

const validate = async <TMedicalReview extends IMedicalReview = IMedicalReview>(forms: Forms, data: TMedicalReview, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new MedicalReviewPostValidate({ data, metadata }));

    return response;
}

const useMedicalReviewByPatientIdAndRepeat = <TMedicalReview extends IMedicalReview = IMedicalReview>(patientId?: number | null, repeat?: number | null, createData?: TMedicalReview, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<MedicalReviewSingleResponse, MedicalReviewSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if ((!patientId || !repeat) && !createData && !createMetadata) {
            return null;
        }

        if (!patientId || !repeat) {
            return [`${forms.baseRoute}/medical-review/single/patient-id-repeat`, forms, null, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/medical-review/single/patient-id-repeat`, forms, patientId, repeat, null, null]
    }, [forms, patientId, repeat, createData, createMetadata])

    const { data, error } = useSWR<MedicalReviewSingleResponse, MedicalReviewSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<MedicalReviewSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TMedicalReview, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TMedicalReview>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TMedicalReview, metadata?: Record<string, any>) => {
        const response = await validate<TMedicalReview>(forms, validateData, metadata);

        return response?.validationResult;
    }, [forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TMedicalReview | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useMedicalReviewByPatientIdAndRepeat;