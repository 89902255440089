import { useContext, useEffect } from "react";
import LayoutActionContext from "../../contexts/layout/LayoutActionContext";

const useFullWidthLayout = () => {
    const { setLayoutState } = useContext(LayoutActionContext);

    useEffect(() => {
        setLayoutState(currentState => ({ ...currentState, fullWidth: true }));

        return () => {
            setLayoutState(currentState => ({ ...currentState, fullWidth: false }));
        }
    }, [setLayoutState])
}

export default useFullWidthLayout;