import React, { FunctionComponent, useCallback, useContext } from 'react';
import { FormDefinitionsContext, FormTypesContext, IFormDefinition, IFormType } from '@ngt/forms';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import { IQuery, IQueryRecipient, MedicalReviewPermission, MedicalReviewStatus, QueryStatus } from '../../api/dtos';
import QueryGrid from './QueryGrid';
import QueriesContext from '../../contexts/data/QueriesContext';
import QueryRecipientsContext from '../../contexts/configuration/QueryRecipientsContext';
import useContextPermissions from '../../hooks/utility/useContextPermissions';

interface IContextQueryGridProps {
    headerRows?: boolean;
}

const ContextQueryGrid: FunctionComponent<IContextQueryGridProps> = ({
    headerRows
}) => {
    const { data: queries, save: saveQueries } = useContext(QueriesContext);
    const { data: queryRecipients } = useContext(QueryRecipientsContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);

    const { data: [canView, canIssue, canRespond, canClose, canCancel] } = useContextPermissions([MedicalReviewPermission.ViewQuery, MedicalReviewPermission.IssueQuery, MedicalReviewPermission.RespondToQuery, MedicalReviewPermission.CloseQuery, MedicalReviewPermission.CancelQuery]);



    const onIssue = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {

        const newQueries = [...(queries ?? [])];

        if (relatedQuery) {
            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = { ...relatedQuery, status: QueryStatus.Closed };

            const newIndex = index + 1;
            if (newIndex < 0 || newIndex > newQueries.length - 1) {
                if (query) {
                    newQueries.push(query);
                }
            }
            else {
                if (query) {
                    newQueries.splice(newIndex, 0, query);
                }
            }
        }
        else {
            if (query) {
                newQueries.push(query);
            }
        }

        await saveQueries(newQueries);
    }, [queries, saveQueries]);

    const onRespond = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if (query) {
                newQueries[index] = query;
            }

            await saveQueries(newQueries);
        }
    }, [queries, saveQueries]);

    const onClose = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if (query) {
                newQueries[index] = query;
            }

            await saveQueries(newQueries);
        }
    }, [queries, saveQueries]);

    const onUpdate = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if (query) {
                newQueries[index] = query;
            }

            await saveQueries(newQueries);
        }
    }, [queries, saveQueries]);

    return (
        <QueryGrid
            disabled={medicalReview?.status === MedicalReviewStatus.Cancelled || medicalReview?.status === MedicalReviewStatus.Complete}
            onIssue={onIssue}
            onUpdate={onUpdate}
            onRespond={onRespond}
            onClose={onClose}
            onCancel={onClose}
            medicalReview={medicalReview!}
            queries={queries ?? []}
            queryRecipients={queryRecipients ?? []}
            formTypes={formTypes ?? []}
            formDefinitions={formDefinitions ?? []}
            headerRows={headerRows}
            canView={canView}
            canIssue={canIssue}
            canRespond={canRespond}
            canClose={canClose}
            canCancel={canCancel}
            notifications
        />
    )
};

export default ContextQueryGrid;
