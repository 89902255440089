/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded country.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

import useLookupsByFormDefinitionId from '../../hooks/utility/useLookupsByFormDefinitionId';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ILookupsContext extends ReturnType<typeof useLookupsByFormDefinitionId> {

}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const LookupsContext = React.createContext<ILookupsContext>({
    data: undefined,
    error: undefined,
    loading: true,
    mutate: async (data, shouldRevalidate) => {
        return undefined;
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const GetFieldLookup = (lookups: Dtos.ILookup[] | null | undefined, fieldName: string) => {
    return lookups?.find(l => l.propertyName === fieldName) ?? null;
}

export const GetFieldLookupItem = (lookups: Dtos.ILookup[] | null | undefined, fieldName: string, id: string | undefined) => {
    return GetFieldLookup(lookups, fieldName)?.items?.find(li => li.id === id) ?? null;
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default LookupsContext;