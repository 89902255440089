/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Toolbar, Button, Theme, useTheme } from '@mui/material';
import { makeStyles } from '../../styles/makeStyles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from '@ngt/forms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IBreadcrumb {
    label: React.ReactNode;
    icon: IconDefinition;
    to: string;
}

/**
 * This interface defines the properties for the Breadcrumbs component.
 */
export interface IBreadcrumbsProps {
    breadcrumbs: IBreadcrumb[]
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    navigationBar: {
        minHeight: 0,
        background: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: 0
    },
    navLink: {
        borderRadius: 0,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    navLinkActive: {
        background: theme.palette.primary.light
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({
    breadcrumbs
}) => {
    const {classes} = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            navigationBar: {
                minHeight: '0px !important',
                background: theme.palette.primary.light,
                color: theme.palette.common.white,
                padding: '0px !important'
            },
            navLink: {
                borderRadius: 0,
                textTransform: 'none !important',
                fontWeight: 'normal'
            },
            navLinkActive: {
                background: theme.palette.primary.light
            }
        }
    }, [theme]);

    return (
        <Toolbar
            sx={styles.navigationBar}
        >
            {
                breadcrumbs?.map((breadcrumb, i) => {
                    return (
                        <Button
                            key={i}
                            component={NavLink}
                            to={breadcrumb.to}
                            color="inherit"
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            startIcon={<FontAwesomeIcon icon={breadcrumb.icon} fixedWidth />}
                            size="large"
                            sx={{ textTransform: 'none' }}
                        >
                            {breadcrumb.label}
                        </Button>
                    );
                })
            }
        </Toolbar>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Breadcrumbs;