/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to initialise a form..
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import FormManager, { IFormActions, IFormManagerOptions } from '../FormManager';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormOptions<TFormValue extends object = any, TError = any> extends IFormManagerOptions<TFormValue, TError> {
}

export interface IUseFormReturn<TFormValue extends object = any, TError = any> extends IFormActions<TFormValue, TError> {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useForm = <TFormValue extends object = any, TError = any>(options?: IUseFormOptions<TFormValue, TError>, ref?: React.MutableRefObject<FormManager<TFormValue, TError> | null>) => {
    const form = React.useMemo(() => {
        return new FormManager<TFormValue, TError>(options);
    }, []);

    if (ref) {
        ref.current = form;
    }

    React.useEffect(() => {
        form.setAllowSubmit(options?.allowSubmit);
    }, [form.setAllowSubmit, options?.allowSubmit])

    React.useEffect(() => {
        form.setInitialValue(options?.initialValues);
    }, [form.setInitialValue, options?.initialValues])

    React.useEffect(() => {
        form.setOnSubmit(options?.onSubmit);
    }, [form.setOnSubmit, options?.onSubmit])

    React.useEffect(() => {
        form.setOnValidate(options?.onValidate);
    }, [form.setOnValidate, options?.onValidate])

    React.useEffect(() => {
        form.setValidateOn(options?.validateOn);
    }, [form.setValidateOn, options?.validateOn])

    const formMethods: IUseFormReturn<TFormValue, TError> = React.useMemo(() => {
        return {
            getValue: form.getValue,
            getTouched: form.getTouched,
            getDirty: form.getDirty,
            getFocused: form.getFocused,
            getErrors: form.getErrors,
            getInitialValue: form.getInitialValue,
            getSubmitting: form.getSubmitting,
            getValidating: form.getValidating,
            setValue: form.setValue,
            setTouched: form.setTouched,
            setDirty: form.setDirty,
            setFocused: form.setFocused,
            setErrors: form.setErrors,
            setValidating: form.setValidating,
            setSubmitting: form.setSubmitting,
            getFieldValue: form.getFieldValue,
            getFieldTouched: form.getFieldTouched,
            getFieldDirty: form.getFieldDirty,
            getFieldFocused: form.getFieldFocused,
            getFieldErrors: form.getFieldErrors,
            getFieldInitialValue: form.getFieldInitialValue,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            setFieldDirty: form.setFieldDirty,
            setFieldFocused: form.setFieldFocused,
            setFieldErrors: form.setFieldErrors,
            reset: form.reset,
            submit: form.submit,
            validate: form.validate,
            subscribe: form.subscribeToForm,
            subscribeToField: form.subscribeToField,
            getFields: form.getFields,
            setFields: form.setFields
        };
    }, [form]);

    return formMethods;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useForm;