import { faBuilding, faHeartbeat, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { Grid, Theme, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { IInstitution, InformationCard, IPatient } from '@ngt/forms-trials';
import { IReviewer, IMedicalReview, IMedicalReviewPatient } from '../../api/dtos';
import { convertMedicalReviewStatusToString } from '../../utilities/MedicalReviewStatus';
import { makeStyles } from '../../styles/makeStyles';

interface IPatientInformationProps {
    patient?: IPatient | null;
    institution?: IInstitution | null;
    medicalReview?: IMedicalReview | null;
    reviewer?: IReviewer | null;
}


const useStyles = makeStyles()((theme: Theme) => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));


const PatientInformation: FunctionComponent<IPatientInformationProps> = ({
    patient,
    institution,
    reviewer,
    medicalReview
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            item: {
                display: 'flex',
        
                '& > *': {
                    flex: '1 1 auto'
                }
            }
        }
    }, [theme]);

    const state = convertMedicalReviewStatusToString(medicalReview?.status ?? (patient as IMedicalReviewPatient)?.reviewStatus);

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color={theme.palette.primary.main}
                    icon={faUser}
                    title="Patient"
                    text={patient?.studyNumber}
                    caption={(patient as IMedicalReviewPatient)?.treatmentArm}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color={theme.palette.secondary.main}
                    icon={faBuilding}
                    title="Institution"
                    text={institution?.code}
                    caption={institution?.name}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color="#76c6d7"
                    icon={faHeartbeat}
                    title="Status"
                    text={state}
                    caption={reviewer?.name}
                />
            </Grid>
        </Grid>
    );
};

export default PatientInformation;
