import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { CountryCollectionResponse, CountryGetCollection } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms) => {
    const response = await forms.serviceStackClient.get(new CountryGetCollection());

    return response;
}

const useCountries = (configuration?: SWRConfiguration<CountryCollectionResponse, CountryCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<CountryCollectionResponse, CountryCollectionResponse>([`${forms.baseRoute}/country/collection`, forms], fetcher, configuration);

    const boundMutate: BoundMutator<CountryCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/country/collection`, forms], data, shouldRevalidate);
    }, [forms])

    const result = useMemo(() => {
        return {
            data: data?.data,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useCountries;