/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import FormContext, { IFormContext } from '../contexts/FormContext';

import { IFieldSubscription, IFieldSubscriber, IUnsubscribe, IFormMutation, IFieldGetActions, IFieldSetActions, IFormSubscribeActions } from '../FormManager';
import ConditionContext from '../contexts/ConditionContext';
import { OmitFirstArg } from '../utilities/OmitFirstArg';
import { ReplaceReturnType } from '../utilities/ReplaceReturnType';
import ConditionActionContext from '../contexts/ConditionActionContext';



export type ReplaceValueArg<F, TFieldValue, TFormValue extends object, TError> = F extends (x: any, ...args: infer P) => infer R ? (value?: TFieldValue | IFormMutation<TFormValue, TError, TFieldValue> | null, ...args: P) => R : never;
export type ReplaceSubscriberArg<F, TFieldValue, TFormValue extends object, TError> = F extends (x: any, ...args: infer P) => infer R ? (subscriber: IFieldSubscriber<TFieldValue, TFormValue, TError>, ...args: P) => R : never;
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFieldReturn<TFieldValue = any, TFormValue extends object = any, TError = any> {
    getValue: ReplaceReturnType<OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldValue']>, TFieldValue | null | undefined>;
    getTouched: OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldTouched']>;
    getDirty: OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldDirty']>;
    getFocused: OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldFocused']>;
    getErrors: OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldErrors']>;
    getInitialValue: ReplaceReturnType<OmitFirstArg<IFieldGetActions<TFormValue, TError>['getFieldInitialValue']>, TFieldValue | null | undefined>;
    setValue: ReplaceValueArg<OmitFirstArg<IFieldSetActions<TFormValue, TError>['setFieldValue']>, TFieldValue, TFormValue, TError>;
    setTouched: OmitFirstArg<IFieldSetActions<TFormValue, TError>['setFieldTouched']>;
    setDirty: OmitFirstArg<IFieldSetActions<TFormValue, TError>['setFieldDirty']>;
    setFocused: OmitFirstArg<IFieldSetActions<TFormValue, TError>['setFieldFocused']>;
    setErrors: OmitFirstArg<IFieldSetActions<TFormValue, TError>['setFieldErrors']>;
    subscribe: ReplaceSubscriberArg<OmitFirstArg<IFormSubscribeActions<TFormValue, TError>['subscribeToField']>, TFieldValue, TFormValue, TError>;
    register: (notify?: boolean) => void;
    unregister: (notify?: boolean) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useField = <TFieldValue = any, TFormValue extends object = any, TError = any>(path: string, autoRegister?: boolean) => {
    const context: IFormContext<any, TError> | null = React.useContext(FormContext);

    if (context === null) {
        throw new Error("Form Context does not exist");
    }

    const conditionActionContext = React.useContext(ConditionActionContext);

    const fieldActions: IUseFieldReturn<TFieldValue, TFormValue, TError> = React.useMemo(() => {
        return {
            getValue: () => context.getFieldValue(path),
            getTouched: () => context.getFieldTouched(path),
            getDirty: () => context.getFieldDirty(path),
            getFocused: () => context.getFieldFocused(path),
            getErrors: () => context.getFieldErrors(path),
            getInitialValue: () => context.getFieldInitialValue(path),
            setValue: (value, notify, updateDirty, updateTouched, updateFocused) => context.setFieldValue(path, value, notify, updateDirty, updateTouched, updateFocused),
            setTouched: (touched, notify) => context.setFieldTouched(path, touched, notify),
            setDirty: (dirty, notify) => context.setFieldDirty(path, dirty, notify),
            setFocused: (focused, notify) => context.setFieldFocused(path, focused, notify),
            setErrors: (errors, notify) => context.setFieldErrors(path, errors, notify),
            subscribe: (subscriber, subscription) => context.subscribeToField(path, subscriber, subscription),
            register: (notify) => {
                conditionActionContext?.addName(path);
                context.setFields(fields => {
                    if (!fields.includes(path)) {
                        fields.push(path);
                    }

                    return fields;
                })
            },
            unregister: (notify) => {
                conditionActionContext?.removeName(path);

                context.setFields(fields => {
                    if (fields.includes(path)) {
                        return fields.filter(x => x !== path);
                    }

                    return fields;
                }, notify)
            }
        }
    }, [context, path, conditionActionContext?.addName, conditionActionContext?.removeName]);

    React.useEffect(() => {
        if (autoRegister === false) {
            return () => { };
        }

        fieldActions.register()

        return () => {
            fieldActions.unregister();
        }
    }, [fieldActions.register, fieldActions.unregister, autoRegister]);

    return fieldActions;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useField;