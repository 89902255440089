import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import { IInstitution, InstitutionGetSingleById, InstitutionPostCreate, InstitutionPostSave, InstitutionPostValidate, InstitutionSingleResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TInstitution extends IInstitution = IInstitution>(url: string, forms: Forms, id?: number, data?: TInstitution, metadata?: Record<string, any>) => {
    if (!id) {
        return await create<TInstitution>(forms, data, metadata);
    }

    return await get(forms, id)
}

const create = async <TInstitution extends IInstitution = IInstitution>(forms: Forms, data?: TInstitution, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new InstitutionPostCreate({ data, metadata }));

    return response;
}

const get = async (forms: Forms, id: number) => {
    const response = await forms.serviceStackClient.get(new InstitutionGetSingleById({ id }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TInstitution extends IInstitution = IInstitution>(forms: Forms, data: TInstitution, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new InstitutionPostSave({ data, metadata }));

    return response;
}

const validate = async <TInstitution extends IInstitution = IInstitution>(forms: Forms, data: TInstitution, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new InstitutionPostValidate({ data, metadata }));

    return response;
}

const useInstitutionById = <TInstitution extends IInstitution = IInstitution>(id?: number | null, createData?: TInstitution, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!id && !createData && !createMetadata) {
            return null;
        }

        if (!id) {
            return [`${forms.baseRoute}/institution/single/id`, forms, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/institution/single/id`, forms, id, null, null]
    }, [forms, id, createData, createMetadata])

    const { data, error } = useSWR<InstitutionSingleResponse, InstitutionSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<InstitutionSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TInstitution, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TInstitution>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TInstitution, metadata?: Record<string, any>) => {
        const response = await validate<TInstitution>(forms, validateData, metadata);

        return response?.validationResult;
    }, [forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TInstitution | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useInstitutionById;