/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to initialise a form..
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import FormErrorContext from '../contexts/FormErrorContext';
import { IFormState, IFormSubscription } from '../FormManager';
import useFormActions from './useFormActions';
import { isEqual } from 'lodash';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type ShowErrorFunction<TValues extends object = any, TError = any> = (name: string, error: TError, formState: IFormState<TValues, TError>) => boolean;

export interface IUseFormErrorBoundaryOptions<TValues extends object = any, TError = any> {
    includeError?: ShowErrorFunction<TValues, TError>;
    subscription?: Partial<Omit<IFormSubscription, 'errors'>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormErrorBoundary = <TValues extends object = any, TError = any>(options?: IUseFormErrorBoundaryOptions<TValues, TError>) => {
    const formErrorContext = React.useContext(FormErrorContext);
    const subscription = React.useMemo(() => {
        if (!options?.subscription) {
            return undefined;
        }

        return { ...options?.subscription, errors: true };
    }, [options?.subscription]);

    const formActions = useFormActions<TValues, TError>();
    const [scopedErrors, setScopedErrors] = React.useState<TError[] | null>()

    const hasSubscription = !!subscription;
    
    React.useEffect(() => {
        if (!formErrorContext?.names) {
            return () => {

            };
        }
        
        const calculateAndSetScopedErrors = (formState: IFormState<TValues, TError>) => {
            setScopedErrors(currentScopedErrors => {
                const newScopedErrors = formErrorContext.names.reduce((a: TError[], b: string) => {
                    if (formState.errors[b] && formState.errors[b].length > 0) {
                        if (options?.includeError) {
                            return [...a, ...formState.errors[b].filter(error => options!.includeError!(b, error, formState))]
                        }
                        else {
                            return [...a, ...formState.errors[b]]
                        }
                    }
        
                    return a;
                }, []);

                if (!isEqual(currentScopedErrors, newScopedErrors)) {
                    return newScopedErrors;
                }

                return currentScopedErrors;
            });
        };

        const sub: IFormSubscription | undefined = hasSubscription ? 
        { 
            dirty: !!subscription?.dirty,
            errors: !!subscription?.errors,
            focused: !!subscription?.focused,
            initialValue: !!subscription?.initialValue,
            touched: !!subscription?.touched,
            value: !!subscription?.value,
            fields: !!subscription?.fields,
            submitting: !!subscription?.submitting,
            validating: !!subscription?.validating
        } : 
        undefined;

        const unsubscribe = formActions.subscribe((formState) => {
            calculateAndSetScopedErrors(formState);
        }, sub);

        calculateAndSetScopedErrors({
            dirty: formActions.getDirty(),
            errors: formActions.getErrors(),
            fields: formActions.getFields(),
            focused: formActions.getFocused(),
            initialValue: formActions.getInitialValue(),
            submitting: formActions.getSubmitting(),
            touched: formActions.getTouched(),
            validating: formActions.getValidating(),
            value: formActions.getValue()
        });

        return () => {
            unsubscribe
        }
    }, [formErrorContext?.names, formActions, setScopedErrors, subscription?.dirty, subscription?.errors, subscription?.focused, subscription?.initialValue, subscription?.touched, subscription?.value, subscription?.fields, subscription?.submitting, subscription?.validating, hasSubscription]);

    return scopedErrors;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormErrorBoundary;