/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';
import {  RouteLoading,  FormTypeCollectionResponse, FormDefinitionCollectionResponse, useFormTypes, useFormDefinitions, FormDefinitionsContext, FormTypesContext } from '@ngt/forms';
import { MedicalReviewDefinitionSingleResponse, MedicalReviewSingleResponse, MedicalReviewValidationCollectionResponse, ReviewerSingleResponse } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import useReviewerById from '../../hooks/data/useReviewerById';
import useMedicalReviewByPatientIdAndRepeat from '../../hooks/data/useMedicalReviewByPatientIdAndRepeat';
import { Navigate, Route, Routes } from 'react-router-dom';
import useMedicalReviewDefinitionById from '../../hooks/configuration/useMedicalReviewDefinitionById';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import ReviewerContext from '../../contexts/data/ReviewerContext';
import MedicalReviewDefinitionContext from '../../contexts/configuration/MedicalReviewDefinitionContext';
import useMedicalReviewFormValidationByMedicalReviewId from '../../hooks/data/useMedicalReviewFormValidationByMedicalReviewId';
import MedicalReviewValidationsContext from '../../contexts/data/MedicalReviewValidationsContext';
import { CoordinatingGroupContext, CoordinatingGroupSingleResponse, CountryContext, CountrySingleResponse, InstitutionContext, InstitutionSingleResponse, MasterGroupContext, MasterGroupSingleResponse, PatientContext, PatientSingleResponse, useCoordinatingGroupById, useCountryById, useInstitutionById, useMasterGroupById, usePatientByStudyNumber } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMedicalReviewByPatientStudyNumberAndRepeatResolverProps {
    patientStudyNumber: string | null;
    medicalReviewRepeat: number | null;
    resolveBeforeLoad?: boolean | null;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewConfiguration?: Partial<SWRConfiguration<MedicalReviewSingleResponse, MedicalReviewSingleResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    reviewerConfiguration?: Partial<SWRConfiguration<ReviewerSingleResponse, ReviewerSingleResponse>>;
    medicalReviewDefinitionConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;
    medicalReviewFormValidationsConfiguration?: Partial<SWRConfiguration<MedicalReviewValidationCollectionResponse, MedicalReviewValidationCollectionResponse>>;

    children?: React.ReactNode
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const MedicalReviewByPatientStudyNumberAndRepeatResolver: React.FunctionComponent<IMedicalReviewByPatientStudyNumberAndRepeatResolverProps> = ({
    patientStudyNumber,
    medicalReviewRepeat,
    resolveBeforeLoad,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionsConfiguration,
    medicalReviewConfiguration,
    medicalReviewDefinitionConfiguration,
    reviewerConfiguration,
    formTypesConfiguration,
    medicalReviewFormValidationsConfiguration
}) => {
    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const medicalReviewContext = useMedicalReviewByPatientIdAndRepeat(patientContext?.data?.id, medicalReviewRepeat ?? 1, undefined, undefined, medicalReviewConfiguration);

    const medicalReviewDefinitionContext = useMedicalReviewDefinitionById(medicalReviewContext?.data?.medicalReviewDefinitionId, medicalReviewDefinitionConfiguration);

    const formTypesContext = useFormTypes(formTypesConfiguration);

    const formDefinitionsContext = useFormDefinitions(formDefinitionsConfiguration);

    const reviewerContext = useReviewerById(medicalReviewContext?.data?.reviewerId, undefined, undefined, reviewerConfiguration);

    const medicalReviewFormValidationsContext = useMedicalReviewFormValidationByMedicalReviewId(medicalReviewContext?.data?.id, undefined, medicalReviewFormValidationsConfiguration);

    const loading =
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        (patientContext?.data?.id && medicalReviewContext?.loading) ||
        formDefinitionsContext?.loading ||
        (medicalReviewContext?.data?.medicalReviewDefinitionId && medicalReviewDefinitionContext?.loading) ||
        (medicalReviewContext?.data?.reviewerId && reviewerContext?.loading) ||
        (medicalReviewContext?.data?.id && medicalReviewFormValidationsContext?.loading) ||
        formTypesContext?.loading;

    const hasError = !!(
        !patientStudyNumber ||
        formDefinitionsContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        medicalReviewContext?.error ||
        medicalReviewDefinitionContext?.error ||
        reviewerContext?.error ||
        formTypesContext?.error ||
        medicalReviewFormValidationsContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        )
    }

    return (
        <MasterGroupContext.Provider value={masterGroupContext}>
            <CoordinatingGroupContext.Provider value={coordinatingGroupContext}>
                <CountryContext.Provider value={countryContext}>
                    <InstitutionContext.Provider value={institutionContext}>
                        <PatientContext.Provider value={patientContext}>
                            <MedicalReviewContext.Provider value={medicalReviewContext}>
                                <MedicalReviewDefinitionContext.Provider value={medicalReviewDefinitionContext}>
                                    <FormTypesContext.Provider value={formTypesContext}>
                                        <ReviewerContext.Provider value={reviewerContext}>
                                            <FormDefinitionsContext.Provider value={formDefinitionsContext}>
                                                <MedicalReviewValidationsContext.Provider value={medicalReviewFormValidationsContext}>
                                                        {
                                                            !resolveBeforeLoad || !loading ?
                                                                children :
                                                                <RouteLoading />
                                                        }
                                                </MedicalReviewValidationsContext.Provider>
                                            </FormDefinitionsContext.Provider>
                                        </ReviewerContext.Provider>
                                    </FormTypesContext.Provider>
                                </MedicalReviewDefinitionContext.Provider>
                            </MedicalReviewContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewByPatientStudyNumberAndRepeatResolver;
