import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, Theme } from '@mui/material';
import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { Form, IFormType, IFormLabel, ILookup, FieldProvider, CheckboxGroup, Checkbox, GroupedField, SubmitButton, ILookupFilterProps, IValidationError, ValidationErrorType, IGroupedFieldStyleProps, FormsContext, useSnackbar, IFormDefinition, GroupedFields } from '@ngt/forms';
import { IMedicalReviewDefinition, IReviewer, MedicalReviewStatus, MedicalReviewPostUpdateReview, IMedicalReview } from '../../api/dtos';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IFormSubmit, IFormSubmitFailed, IFormValidate, ValidateOn } from '@ngt/forms-core';
import { AlertTitle } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IInstitution, IPatient, BasicAlert } from '@ngt/forms-trials';
import useMedicalReviewDefinitions from '../../hooks/configuration/useMedicalReviewDefinitions';
import { makeStyles } from '../../styles/makeStyles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

interface IUpdateMedicalReviewDialogProps {
    medicalReview: IMedicalReview;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    institution?: IInstitution;
    patient?: IPatient;
    open: boolean;
    onClose: () => void;
    onFormUpdated?: () => Promise<any>;
}

interface IInternalForm {
    singleDefinition?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        padding: 0,

        '&:first-of-type': {
            padding: 0
        }
    },
    settingsContainer: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',

        '&.Mui-expanded': {
            margin: 0
        }
    },
    settingsSummary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    settingsTitle: {
        padding: theme.spacing(0)
    },
    settingsContent: {
        display: 'block',
        padding: 0
    }
}));


const labels: IFormLabel[] = [
    {
        name: 'data.medicalReviewDefinitionId',
        label: 'Type',
        detailedLabel: 'Type'
    },
    {
        name: 'data.reviewerId',
        label: 'Assigned Reviewer',
        detailedLabel: 'Assigned Reviewer'
    },
    {
        name: 'options.formDefinitionIds',
        label: 'Included Reports',
        detailedLabel: 'Included Reports'
    },
    {
        name: 'options.includeUnchangedForms',
        label: 'Include Unchanged Forms',
        detailedLabel: 'Include Unchanged Forms'
    },
    {
        name: 'options.compareFormsAcrossMedicalReviewDefinitions',
        label: 'Compare Forms Across Review Types',
        detailedLabel: 'Compare Forms Across Review Types'
    }
];

const lookupFilters: Record<string, ILookupFilterProps> = {
    formDefinitionIds: {
        filterFunction: (formState, fieldName, lookup) => {

            if (!lookup) {
                return undefined;
            }

            return {
                ...lookup,
                items: lookup?.items?.filter(x => (x as any)?.parent === formState.value.data?.medicalReviewDefinitionId)
            }

        },
        subscription: { value: true }
    }
}

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 5,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const validate: IFormValidate<MedicalReviewPostUpdateReview, IValidationError> = async (formState, formActions) => {
    let errors: Record<string, IValidationError[]> = {};

    if (!formState.value?.options?.formDefinitionIds?.length) {
        errors['options.formDefinitionIds'] = [
            {
                code: 'CMR-001',
                message: 'At least one report must be selected.',
                detailedMessage: 'At least one report must be selected.',
                property: 'options.formDefinitionIds',
                type: ValidationErrorType.Normal
            }
        ];
    }

    return errors;
}

const UpdateMedicalReviewDialog: FunctionComponent<IUpdateMedicalReviewDialogProps> = ({
    medicalReview,
    medicalReviewDefinition,
    formTypes,
    formDefinitions,
    institution,
    patient,
    open,
    onClose,
    onFormUpdated
}) => {
    const { classes } = useStyles();

    const navigate = useNavigate();
    const forms = useContext(FormsContext);
    const medicalReviewContext = useContext(MedicalReviewExtensionContext);

    const { enqueueSnackbar } = useSnackbar();

    const lookups: ILookup[] = useMemo(() => {
        return [
            {
                propertyName: 'options.formDefinitionIds',
                items: medicalReviewDefinition?.availableFormDefinitionIds?.map((formDefinitionId, i) => {
                    const formDefinition = formDefinitions?.find(x => x.id === formDefinitionId);

                    return {
                        id: formDefinitionId,
                        value: formDefinition?.name ?? formDefinitionId,
                        order: i,
                        parent: medicalReviewDefinition.id
                    }
                }) ?? []
            }
        ] as ILookup[];
    }, [medicalReviewDefinition, formDefinitions])

    const initialValues: MedicalReviewPostUpdateReview = useMemo(() => {

        return {
            medicalReviewId: medicalReview?.id,
            metadata: {},
            options: {
                formDefinitionIds: medicalReview?.formDefinitionIds ?? [],
                includeUnchangedForms: false,
                addNewForms: true,
                compareFormsAcrossMedicalReviewDefinitions: false,
                deleteOldForms: true
                
            }
        } as unknown as MedicalReviewPostUpdateReview

    }, [medicalReviewDefinition, medicalReview, patient]);

    const onSubmit: IFormSubmit<MedicalReviewPostUpdateReview, IValidationError> = useCallback(async (formState, formActions) => {
        const response = await forms.serviceStackClient.post(new MedicalReviewPostUpdateReview(formState.value ?? undefined));

        if (response.hasNoForms) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Not Updated
                    </AlertTitle>
                    No reports were included in the medical review.
                </>,
                { variant: 'error-low' }
            );
        }
        else if (response.hasNoChanges) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Not Updated
                    </AlertTitle>
                    No reports have changed for this medical review.
                </>,
                { variant: 'error-low' }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Medical Review Updated
                    </AlertTitle>
                    The medical review was successfully updated.
                </>,
                { variant: 'success' }
            );

            if (onFormUpdated) {
                await onFormUpdated();
            }

            if (onClose) {
                onClose();
            }
            //history.push(medicalReviewContext.createMedicalReviewRouteFn(patient!, response.data))
        }

    }, [forms, enqueueSnackbar, navigate, medicalReviewContext, patient, onFormUpdated, onClose])


    const onSubmitFailed: IFormSubmitFailed<MedicalReviewPostUpdateReview, IValidationError> = useCallback(async (formState, formActions) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Medical Review Not Updated
                </AlertTitle>
                An error occurred while attempting to update the medical review.
            </>,
            { variant: 'error-critical' }
        );
    }, [enqueueSnackbar]);


    const { data: medicalReviewDefinitions, loading: medicalReviewDefinitionsLoading } = useMedicalReviewDefinitions();

    const singleDefinition = (medicalReviewDefinitions?.length ?? 0) <= 1;

    return (
        <Dialog open={open} onClose={onClose} fullWidth scroll="body" maxWidth="md">
            <DialogTitle>
                Update Medical Review
            </DialogTitle>
            {
                medicalReviewDefinitionsLoading && (
                    <CircularProgress color="primary" />
                )
            }
            {
                !medicalReviewDefinitionsLoading && (
                    <Form
                        initialValues={initialValues}
                        labels={labels}
                        lookups={lookups}
                        onValidate={validate}
                        validateOn={ValidateOn.onChange}
                        onSubmit={onSubmit}
                        onSubmitFailed={onSubmitFailed}
                    >
                        <DialogContent className={classes.content} dividers>
                            <InternalForm singleDefinition={singleDefinition} />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <SubmitButton color="primary">
                                Ok
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                )
            }
        </Dialog>
    );
};

const InternalForm: FunctionComponent<IInternalForm> = ({
    singleDefinition
}) => {
    const { classes } = useStyles();

    return (
        <>
            <GroupedFields>
                <Accordion className={classes.settingsContainer} elevation={0} square>
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.settingsSummary}
                    >
                        <Typography variant="h2" className={classes.settingsTitle}>
                            Advanced Settings
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.settingsContent}>
                        <FieldProvider name="options" autoRegister={false}>
                            <GroupedField
                                name="formDefinitionIds"
                                component={CheckboxGroup}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="includeUnchangedForms"
                                component={Checkbox}
                                GroupStyleProps={groupStyleProps}
                            />
                            {
                                !singleDefinition && (
                                    <GroupedField
                                        name="compareFormsAcrossMedicalReviewDefinitions"
                                        component={Checkbox}
                                        GroupStyleProps={groupStyleProps}
                                    />
                                )
                            }
                        </FieldProvider>
                    </AccordionDetails>
                </Accordion>
            </GroupedFields>
        </>
    );
};

export default UpdateMedicalReviewDialog;
