/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Variant } from '@mui/material/styles/createTypography';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = IInputRenderProps<string, Dtos.IValidationError>

export interface IHeadingDisplayProps extends TextFieldsProps {
    varient?: Variant;
    heading?: string;
    className?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const HeadingDisplay: React.FunctionComponent<IHeadingDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    varient,
    heading,
    className,
    ...rest
}) => {
    if (value === null || value === undefined) {
        return null;
    }

    return (
        <Typography variant={varient} className={className}>
            {heading} {value}
        </Typography>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default HeadingDisplay;
