import React, { FunctionComponent, useMemo } from 'react';
import { Fab, Theme, Portal, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltLines } from '@fortawesome/pro-duotone-svg-icons';
import { makeStyles } from '../../styles/makeStyles';

interface IQueryGridFabProps {
    onClick: () => void;
    count?: number
}

const useStyles = makeStyles()((theme: Theme) => ({
    count: {
        background: theme.palette.primary.main,
        fontSize: '2.5rem',
        top: `-${theme.spacing(0.5)}`,
        right: `-${theme.spacing(0.5)}`
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

const QueryGridFab: FunctionComponent<IQueryGridFabProps> = ({
    onClick,
    count
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            count: {
                background: theme.palette.primary.main,
                fontSize: '2.5rem',
                top: `-${theme.spacing(0.5)}`,
                right: `-${theme.spacing(0.5)}`
            },
            fab: {
                position: 'fixed',
                bottom: theme.spacing(2),
                right: theme.spacing(2)
            }
        }
    }, [theme]);

    return (
        <Portal>
            <Fab color="secondary" sx={styles.fab} onClick={onClick}>
                <span className="fa-layers fa-fw fa-2x">
                    <FontAwesomeIcon icon={faCommentAltLines} />
                    {
                        (count ?? 0) > 0 && (
                            <span className={`fa-layers-counter ${classes.count}`}>{count}</span>
                        )
                    }
                </span>
            </Fab>
        </Portal>
    );
};

export default QueryGridFab;
