import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { IQuery, IQueryRecipient, QueryStatus } from '../../api/dtos';
import { TableRow, TableCell, Typography, Theme, Button, Tooltip, useTheme } from '@mui/material';
import { IFormDefinition, IFormType } from '@ngt/forms';
import { convertQueryStatusToString } from '../../utilities/QueryStatus';
import { faCommentAltPlus, faCommentAltTimes, faCommentsAlt, faCommentAltEdit } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogState } from './QueryDialog';
import { makeStyles } from '../../styles/makeStyles';

export interface IQueryGridRowProps {
    query: IQuery;
    relatedQueries: IQuery[];
    queryRecipients: IQueryRecipient[];
    formTypes: IFormType[];
    formDefinitions: IFormDefinition[];
    disabled?: boolean;
    setDialogState: (state: DialogState) => void;
    canIssue?: boolean;
    canRespond?: boolean;
    canClose?: boolean;
    canCancel?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    cell: {
        verticalAlign: 'top'
    },
    pre: {
        whiteSpace: 'pre-wrap',
        margin: theme.spacing(0)
    },
    button: {
        minWidth: '0',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),

        '&:first-child': {
            margin: theme.spacing(0)
        }
    },
    alert: {
        padding: theme.spacing(2)
    }
}));

const QueryGridRow: FunctionComponent<IQueryGridRowProps> = ({
    query,
    relatedQueries,
    queryRecipients,
    disabled,
    formTypes,
    formDefinitions,
    setDialogState,
    canIssue,
    canClose,
    canCancel,
    canRespond
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        cell: {
            verticalAlign: 'top',
        },
        mergedCell: {
            verticalAlign: 'top',
            borderBottom: relatedQueries?.length > 0 ? (query.id == (relatedQueries.sort((a, b) => a.repeat! - b.repeat!)[relatedQueries.length - 1]).id ? 1 : 0) : 1,
            borderBottomColor: 'rgb(224, 224, 224)'
        },
        pre: {
            whiteSpace: 'pre-wrap',
            margin: theme.spacing(0)
        },
        button: {
            minWidth: '0',
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),

            '&:first-child': {
                margin: theme.spacing(0)
            }
        },
        alert: {
            padding: theme.spacing(2)
        }
    }), [theme, query, relatedQueries]);

    const recipient = useMemo(() => {
        return queryRecipients?.find(x => x.id === query.recipientId)
    }, [queryRecipients, query]);

    const formDefinition = useMemo(() => {
        return formDefinitions?.find(x => x.id === query.formDefinitionId)
    }, [formDefinitions, query]);

    const formType = useMemo(() => {
        return formTypes?.find(x => x.id === formDefinition?.type)
    }, [formTypes, formDefinition]);

    const onCreateNewClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Issued,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient]);

    const onRespondClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Responded,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient])

    const onCloseClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Closed,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient])

    const onCancelClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Cancelled,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient])

    const onUpdateQueryClick = useCallback(() => {
        setDialogState({
            mode: "Update",
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient])

    const onUpdateResponseClick = useCallback(() => {
        setDialogState({
            mode: query.status!,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName,
            queryRecipient: recipient
        })
    }, [setDialogState, formDefinition, formType, query, recipient])

    const latestStatus = useMemo(() => {
        if (relatedQueries?.length > 0) {
            const latestFupQuery = relatedQueries.sort((a, b) => a.repeat! - b.repeat!)[relatedQueries.length - 1];

            return convertQueryStatusToString(latestFupQuery.status);
        }

        return convertQueryStatusToString(query.status);
        
    }, [query, relatedQueries, convertQueryStatusToString]);

    return (
        <TableRow>
            <TableCell
                sx={styles.mergedCell}
                width={150}
            >
                {
                    !query.parentQueryId && (
                        <Typography>
                            {query.queryNumber}
                        </Typography>
                    )
                }
            </TableCell>
            <TableCell
                sx={{ ...styles.mergedCell, borderLeft: 1, borderLeftColor: 'rgb(224, 224, 224)' }}
                width={150}
            >
                {
                    !query.parentQueryId && (
                        <Typography>
                            {latestStatus}
                        </Typography>    
                    )
                }
            </TableCell>
            <TableCell
                sx={{ ...styles.cell, borderLeft: 1, borderLeftColor: 'rgb(224, 224, 224)' }}
                width={220}
            >
                <Typography>
                    {recipient?.name}
                </Typography>
            </TableCell>
            <TableCell
                sx={styles.cell}
            >
                {
                    query.question && (
                        <pre
                            style={{
                                whiteSpace: 'pre-wrap',
                                margin: theme.spacing(0)
                            }}
                        >
                            <Typography>
                                {query.question}
                            </Typography>
                        </pre>
                    )
                }
            </TableCell>
            <TableCell
                sx={styles.cell}
            >
                {
                    query.response && (
                        <pre
                            style={{
                                whiteSpace: 'pre-wrap',
                                margin: theme.spacing(0)
                            }}
                        >
                            <Typography>
                                {query.response}
                            </Typography>
                        </pre>
                    )
                }
            </TableCell>
            {
                (!disabled && (canIssue || canRespond || canClose || canCancel)) && (
                    <TableCell
                        sx={styles.cell}
                        width={180}
                    >
                        {
                            query.status === QueryStatus.Responded && canIssue && (
                                <Tooltip title="Request more information">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onCreateNewClick}>
                                        <FontAwesomeIcon icon={faCommentAltPlus} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Issued && canRespond && (
                                <Tooltip title="Respond to the query">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onRespondClick}>
                                        <FontAwesomeIcon icon={faCommentsAlt} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Issued && canIssue && (
                                <Tooltip title="Update the query">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onUpdateQueryClick}>
                                        <FontAwesomeIcon icon={faCommentAltEdit} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Responded && canRespond && (
                                <Tooltip title="Update the response">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onUpdateResponseClick}>
                                        <FontAwesomeIcon icon={faCommentAltEdit} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Responded && canClose && (
                                <Tooltip title="Close the query">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onCloseClick}>
                                        <FontAwesomeIcon icon={faCommentAltTimes} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Issued && canCancel && (
                                <Tooltip title="Cancel the query">
                                    <Button color="primary" variant="contained" sx={styles.button} onClick={onCancelClick}>
                                        <FontAwesomeIcon icon={faCommentAltTimes} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                    </TableCell>
                )
            }
        </TableRow>
    );
};

export default QueryGridRow;
