/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';
import { Typography} from '@mui/material';
import { ReactNode } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = IInputRenderProps<number, Dtos.IValidationError>

export interface INumericDisplayProps extends TextFieldsProps {
    decimalPlaces?: number;
    prefix?: ReactNode;
    suffix?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const NumericDisplay: React.FunctionComponent<INumericDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    decimalPlaces,
    prefix,
    suffix,
    ...rest
}) => {
    if (value === null || value === undefined) {
        return null;
    }

    const convertedValue = decimalPlaces ? value.toFixed(decimalPlaces) : value;
    
    return (
        <Typography>
            {prefix}{convertedValue}{suffix}
        </Typography>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default NumericDisplay;
