/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode, useContext } from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, PathMatch } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load formDefinitions into the FormDefinitionByIdContext
 */
import FormDefinitionByIdResolver from '../../resolver/configuration/FormDefinitionByIdResolver';

/**
 * Used to get OPMS settings
 */
import FormsContext from '../../../contexts/FormsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormDefinitionByIdRoute component.
 */
export interface IFormDefinitionByIdRouteProps extends RouteProps {
    id?: number;

    computedMatch?: PathMatch<string>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormDefinitionByIdRoute: FunctionComponent<IFormDefinitionByIdRouteProps> = ({
    id,
    resolveBeforeLoad,
    children,
    ...routeProps
}) => {
    const onlinePatientManagement = useContext(FormsContext)
    const params = routeProps.computedMatch?.params ?? useParams<string>();
    const paramIdStr = params[onlinePatientManagement.routeParameters.formDefinitionId];
    let paramId: number | null = null;

    try {
        if (paramIdStr) {
            paramId = parseInt(paramIdStr);
        }
    }
    catch (error) {
        console.error(`Invalid ID in route: ${paramIdStr}`)
    }

    return <Route {...routeProps}>
        <FormDefinitionByIdResolver id={paramId ?? id ?? 0} resolveBeforeLoad={resolveBeforeLoad}>
            {children}
        </FormDefinitionByIdResolver>
    </Route>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormDefinitionByIdRoute;