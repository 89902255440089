/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the collapsibletable component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import {
    Theme,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    CircularProgress,
    useTheme,
    Box
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { default as pluralize } from 'pluralize';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a table
 */
import Table, { ITableProps } from './Table';
import { makeStyles } from '../../utilities/makeStyles';

/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ICollapsibleTableProps<Type extends object = any> extends ITableProps<Type> {
    title: string;
    loading: boolean;
    entityName?: string;
    startCollapsed?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    expansionPanelContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,

        '&.Mui-expanded': {
            minHeight: 48
        },

        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',

            '&.Mui-expanded': {
                marginTop: theme.spacing(1.5),
                marginBottom: theme.spacing(1.5)
            }
        }
    },
    expand: {
        color: theme.palette.common.white
    },
    expandContent: {
        padding: theme.spacing(0)
    },
    tableWrapper: {
        overflow: 'auto',
        width: '100%'
    }
}));

const usePaginationStyles = makeStyles()((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const CollapsibleTable = <Type extends object>({
    title,
    loading,
    rows,
    entityName,
    startCollapsed,
    ...tableProps
}: ICollapsibleTableProps<Type>) => {
    const disabled = !loading && (!rows || rows.length === 0);

    const [expanded, setExpanded] = React.useState(startCollapsed !== true);

    const { classes } = useStyles();
    const theme = useTheme();

    const styles = React.useMemo(() => {
        return {
            expansionPanelContainer: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2)
            },
            header: {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
        
                '&.Mui-expanded': {
                    minHeight: 48
                },
        
                '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
        
                    '&.Mui-expanded': {
                        marginTop: theme.spacing(1.5),
                        marginBottom: theme.spacing(1.5)
                    }
                }
            },
            expand: {
                color: theme.palette.common.white
            },
            expandContent: {
                padding: theme.spacing(0)
            },
            tableWrapper: {
                overflow: 'auto',
                width: '100%'
            }
        }
    }, [theme]);

    const onClick = React.useCallback(() => {
        setExpanded(!expanded)
    }, [expanded, setExpanded]);

    const upperCasePlural = pluralize.plural(title);

    const lowerCasePlural = upperCasePlural.toLowerCase();

    const entityNameToUse = entityName ?
        entityName :
        title;

    return (
        <>
            <Accordion
                disabled={disabled}
                expanded={expanded && !disabled && !loading}
                sx={styles.expansionPanelContainer}
            >
                <AccordionSummary
                    onClick={onClick}
                    expandIcon={loading ? <CircularProgress color="secondary" size={16} /> : disabled ? false : <FontAwesomeIcon className={classes.expand} icon={faChevronDown} size="lg" fixedWidth />}
                    aria-controls={`${title}-content`}
                    id="`${title}-header`"
                    sx={styles.header}
                >
                    <Typography>
                        {upperCasePlural}
                    </Typography>
                    {
                        disabled && (
                            <Typography>
                                There are no {lowerCasePlural}
                            </Typography>
                        )
                    }
                    {
                        !loading && rows && !disabled && (
                            <Chip
                                label={`${pluralize(entityNameToUse, tableProps.rowCount ?? rows.length, true)}`}
                                color="secondary"
                                size="small"
                                sx={{
                                    marginRight: theme.spacing(1)
                                }}
                            />
                        )
                    }
                </AccordionSummary>
                <AccordionDetails
                    sx={styles.expandContent}
                >
                    <Box
                        sx={styles.tableWrapper}
                    >
                        <Table
                            rows={rows}
                            {...tableProps}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CollapsibleTable;
