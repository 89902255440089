import React, { FunctionComponent, useContext } from 'react';
import { FormDefinitionContext, FormTypeContext } from '@ngt/forms';

interface IContextFormTitleProps {
}



const ContextFormTitle: FunctionComponent<IContextFormTitleProps> = () => {
    const { data: formType } = useContext(FormTypeContext);
    const { data: formDefinition } = useContext(FormDefinitionContext);

    return (
        <>
            {formType?.name} &ndash; {formDefinition?.name}
        </>
    );
};

export default ContextFormTitle;
