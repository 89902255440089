/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { FunctionComponent, useCallback } from 'react';

import { Theme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import FieldProvider from '../../../FieldProvider';
import FormErrorHandler from '../../../FormErrorHandler';
import GroupedErrorDisplay from '../GroupedErrorDisplay';
import { FormErrorBoundaryMode } from '@ngt/forms-core';
import { TableCell, TableRow, Button } from '@mui/material';
import GroupedSubformGridCell, { IGroupedSubformGridCellProps } from './GroupedSubformGridCell';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IGroupedSubformGridRowProps {
    cells: Array<IGroupedSubformGridCellProps>;
    noErrors?: boolean;
    index: number;
    removeRow: (index: number) => void;
    disabled?: boolean;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const GroupedSubformGridRow: FunctionComponent<IGroupedSubformGridRowProps> = ({
    cells,
    noErrors,
    removeRow,
    index,
    disabled
}) => {

    const remove = useCallback(() => {
        removeRow(index);
    }, [removeRow, index])

    return (
        <>
            <FormErrorHandler errorMode={FormErrorBoundaryMode.Contain}>
                <FieldProvider name={`[${index}]`}>
                    <TableRow
                        sx={{
                            borderBottom: !noErrors ? 'none' : '',
                    
                            '& td': {
                                borderBottom: !noErrors ? 'none' : ''
                            },
                           width: '100%'
                        }}
                    >
                        {
                            cells.map((cell, i) => {
                                return (
                                    <GroupedSubformGridCell
                                        key={i}
                                        {...cell}
                                    />
                                );
                            })
                        }
                        {
                            !disabled && (
                                <TableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            color: 'error.main'
                                        }}
                                        onClick={remove}
                                    >
                                        <FontAwesomeIcon fixedWidth icon={faTimes} />
                                    </Button>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </FieldProvider>
                {
                    !noErrors && (
                        <TableRow
                            sx={{
                                p: 0,
                        
                                '& td': {
                                    p: 0
                                }
                            }}
                        >
                            <TableCell colSpan={(cells?.length ?? 0) + 1}>
                                <GroupedErrorDisplay />
                            </TableCell>
                        </TableRow>
                    )
                }
            </FormErrorHandler>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default GroupedSubformGridRow;
