/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';
import { FormContext, FormDefinitionContext, FormTypeContext, FormDefinitionSingleResponse, IDataSingleResponse, LookupCollectionResponse, LookupsContext, RouteLoading, useFormDefinitionByCode, useLookupsByFormDefinitionId, FormTypeSingleResponse, useFormTypeById } from '@ngt/forms';
import { IMedicalReviewForm, MedicalReviewDefinitionSingleResponse, MedicalReviewSingleResponse, QueryRecipientCollectionResponse, ReviewerSingleResponse, UpdatedFormPropertyCollectionResponse } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import useReviewerById from '../../hooks/data/useReviewerById';
import useMedicalReviewFormByMedicalReviewIdAndRepeat from '../../hooks/data/useMedicalReviewFormByMedicalReviewIdAndRepeat';
import useMedicalReviewByPatientIdAndRepeat from '../../hooks/data/useMedicalReviewByPatientIdAndRepeat';
import useUpdatedFormPropertiesByFromDefinitionIdAndFormId from '../../hooks/data/useUpdatedFormPropertiesByFromDefinitionIdAndFormId';
import useMedicalReviewDefinitionById from '../../hooks/configuration/useMedicalReviewDefinitionById';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import UpdatedFormPropertiesContext from '../../contexts/data/UpdatedFormPropertiesContext';
import ReviewerContext from '../../contexts/data/ReviewerContext';
import MedicalReviewDefinitionContext from '../../contexts/configuration/MedicalReviewDefinitionContext';
import { IMedicalReviewFormMapping } from '../../utilities/IMedicalReviewFormMapping';
import useQueryRecipients from '../../hooks/configuration/useQueryRecipients';
import QueryRecipientsContext from '../../contexts/configuration/QueryRecipientsContext';
import { useMemo } from 'react';
import { CoordinatingGroupContext, CoordinatingGroupSingleResponse, CountryContext, CountrySingleResponse, InstitutionContext, InstitutionSingleResponse, MasterGroupContext, MasterGroupSingleResponse, PatientContext, PatientSingleResponse, useCoordinatingGroupById, useCountryById, useInstitutionById, useMasterGroupById, usePatientByStudyNumber } from '@ngt/forms-trials';
import { Navigate, Route, Routes } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolverProps {
    formDefinitionCode: string | null;
    patientStudyNumber: string | null;
    medicalReviewRepeat: number | null;
    formRepeat: number | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IMedicalReviewFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewConfiguration?: Partial<SWRConfiguration<MedicalReviewSingleResponse, MedicalReviewSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IMedicalReviewForm>, IDataSingleResponse<IMedicalReviewForm>>>;
    reviewerConfiguration?: Partial<SWRConfiguration<ReviewerSingleResponse, ReviewerSingleResponse>>;
    medicalReviewDefinitionConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    queryRecipientsConfiguration?: Partial<SWRConfiguration<QueryRecipientCollectionResponse, QueryRecipientCollectionResponse>>;
    updatedFormPropertiesConfiguration?: Partial<SWRConfiguration<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>>;

    children?: ReactNode;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver: FunctionComponent<IMedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolverProps> = ({
    formDefinitionCode,
    patientStudyNumber,
    medicalReviewRepeat,
    formRepeat,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    medicalReviewConfiguration,
    medicalReviewDefinitionConfiguration,
    reviewerConfiguration,
    formTypeConfiguration,
    queryRecipientsConfiguration,
    updatedFormPropertiesConfiguration
}) => {

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode ? formDefinitionCode : undefined, formDefinitionConfiguration);

    const formDefCode = useMemo(() => formDefinitionContext?.data?.code ?? '', [formDefinitionContext?.data?.code]);

    const currentFormMapping = useMemo(() => {
        return formMapping?.[formDefCode]
    }, [formDefCode, formMapping])

    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const medicalReviewContext = useMedicalReviewByPatientIdAndRepeat(patientContext?.data?.id, medicalReviewRepeat ?? 1, undefined, undefined, medicalReviewConfiguration);

    const medicalReviewDefinitionContext = useMedicalReviewDefinitionById(medicalReviewContext?.data?.medicalReviewDefinitionId, medicalReviewDefinitionConfiguration);

    const formTypeContext = useFormTypeById(formDefinitionContext?.data?.type, formTypeConfiguration);

    const reviewerContext = useReviewerById(medicalReviewContext?.data?.reviewerId, undefined, undefined, reviewerConfiguration);

    const formContext = useMedicalReviewFormByMedicalReviewIdAndRepeat(formDefinitionCode, medicalReviewContext?.data?.id, formRepeat ?? 1, currentFormMapping?.createData, currentFormMapping?.createMetadata, formConfiguration);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    const queryRecipientsContext = useQueryRecipients(queryRecipientsConfiguration);

    const updatedFormPropertiesContext = useUpdatedFormPropertiesByFromDefinitionIdAndFormId(formDefinitionContext?.data?.id, formContext?.data?.id, updatedFormPropertiesConfiguration);

    let conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const loading =
        (formDefinitionCode && formDefinitionContext?.loading) ||
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        (patientContext?.data?.id && medicalReviewContext?.loading) ||
        (formDefinitionCode && medicalReviewContext?.data?.id && formContext?.loading) ||
        (formDefinitionContext?.data?.id && lookupsContext?.loading) ||
        (medicalReviewContext?.data?.medicalReviewDefinitionId && medicalReviewDefinitionContext?.loading) ||
        (medicalReviewContext?.data?.reviewerId && reviewerContext?.loading) ||
        (formDefinitionContext?.data?.type && formTypeContext?.loading) ||
        (formDefinitionContext?.data?.id && formContext?.data?.id && updatedFormPropertiesContext?.loading) ||
        queryRecipientsContext?.loading;

    const hasError = !!(
        !patientStudyNumber ||
        !formDefinitionCode ||
        formDefinitionContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        medicalReviewContext?.error ||
        formContext?.error ||
        lookupsContext?.error ||
        medicalReviewDefinitionContext?.error ||
        reviewerContext?.error ||
        formTypeContext?.error ||
        queryRecipientsContext?.error ||
        updatedFormPropertiesContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        )
    }

    return (
        <MasterGroupContext.Provider value={masterGroupContext}>
            <CoordinatingGroupContext.Provider value={coordinatingGroupContext}>
                <CountryContext.Provider value={countryContext}>
                    <InstitutionContext.Provider value={institutionContext}>
                        <PatientContext.Provider value={patientContext}>
                            <MedicalReviewContext.Provider value={medicalReviewContext}>
                                <MedicalReviewDefinitionContext.Provider value={medicalReviewDefinitionContext}>
                                    <FormTypeContext.Provider value={formTypeContext}>
                                        <ReviewerContext.Provider value={reviewerContext}>
                                            <FormDefinitionContext.Provider value={formDefinitionContext}>
                                                <FormContext.Provider value={formContext}>
                                                    <LookupsContext.Provider value={lookupsContext}>
                                                        <QueryRecipientsContext.Provider value={queryRecipientsContext}>
                                                            <UpdatedFormPropertiesContext.Provider value={updatedFormPropertiesContext}>
                                                                {
                                                                    !resolveBeforeLoad || !loading ?
                                                                        conditionalContexts :
                                                                        <RouteLoading />
                                                                }
                                                            </UpdatedFormPropertiesContext.Provider>
                                                        </QueryRecipientsContext.Provider>
                                                    </LookupsContext.Provider>
                                                </FormContext.Provider>
                                            </FormDefinitionContext.Provider>
                                        </ReviewerContext.Provider>
                                    </FormTypeContext.Provider>
                                </MedicalReviewDefinitionContext.Provider>
                            </MedicalReviewContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver;
