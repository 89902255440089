/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams } from 'react-router-dom';
import { FormDefinitionCollectionResponse, FormTypeCollectionResponse } from '@ngt/forms';
import MedicalReviewFormsByPatientStudyNumberResolver from '../resolver/MedicalReviewsByPatientStudyNumberResolver';
import { SWRConfiguration } from 'swr/dist/types';
import { MedicalReviewCollectionResponse, MedicalReviewDefinitionCollectionResponse, ReviewerCollectionResponse } from '../../api/dtos';
import { useContext } from 'react';
import { CoordinatingGroupSingleResponse, CountrySingleResponse, InstitutionSingleResponse, MasterGroupSingleResponse, PatientSingleResponse, TrialsExtensionContext } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export type IMedicalReviewsByPatientStudyNumberRouteProps = RouteProps & {
    patientStudyNumber?: string;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;


    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewsConfiguration?: Partial<SWRConfiguration<MedicalReviewCollectionResponse, MedicalReviewCollectionResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    reviewersConfiguration?: Partial<SWRConfiguration<ReviewerCollectionResponse, ReviewerCollectionResponse>>;
    medicalReviewDefinitionsConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionCollectionResponse, MedicalReviewDefinitionCollectionResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;

    children?: ReactNode
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const MedicalReviewsByPatientStudyNumberRoute: FunctionComponent<IMedicalReviewsByPatientStudyNumberRouteProps> = ({
    patientStudyNumber,
    resolveBeforeLoad,
    children,


    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionsConfiguration,
    medicalReviewsConfiguration,
    medicalReviewDefinitionsConfiguration,
    reviewersConfiguration,
    formTypesConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];


    return (
        <MedicalReviewFormsByPatientStudyNumberResolver
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formDefinitionsConfiguration={formDefinitionsConfiguration}
            formTypesConfiguration={formTypesConfiguration}
            institutionConfiguration={institutionConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            medicalReviewsConfiguration={medicalReviewsConfiguration}
            medicalReviewDefinitionsConfiguration={medicalReviewDefinitionsConfiguration}
            patientConfiguration={patientConfiguration}
            reviewersConfiguration={reviewersConfiguration}
        >
            {children}
        </MedicalReviewFormsByPatientStudyNumberResolver>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewsByPatientStudyNumberRoute;