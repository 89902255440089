/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../../api/dtos';


import { IGroupedSubformGridProps as IBaseGroupedSubformGridProps, IGroupedSubformGridCell as IBaseGroupedSubformGridCell, GroupedSubformGrid as BaseGroupedSubformGrid } from '@ngt/forms';
import UpdatedFormPropertyStyler, { IUpdatedFormPropertyStyler } from '../../../UpdatedFormPropertyStyler';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IGroupedSubformGridCell<TValues extends Record<string, any>> extends IBaseGroupedSubformGridCell<TValues> {
    UpdatedFormPropertyStylerProps?: IUpdatedFormPropertyStyler;
}

export interface IGroupedSubformGridProps<TValues extends Object = any> extends IBaseGroupedSubformGridProps<TValues> {
    columns: IGroupedSubformGridCell<TValues>[];
}





/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const GroupedSubformGrid = <TValues extends Object = any>({
    columns,
    ...props
}: IGroupedSubformGridProps<TValues>) => {
    const calculatedColumns: Array<IGroupedSubformGridCell<TValues>> = React.useMemo(() => {
        return columns?.map(x => {
            const wrappingComponent = ({ children }: any) => {
                let content = children;

                const A = x.WrappingComponent;

                if (A) {
                    content = <A>
                        {content}
                    </A>
                }

                return <UpdatedFormPropertyStyler {...x.UpdatedFormPropertyStylerProps}>
                    {content}
                </UpdatedFormPropertyStyler>

            };

            const column = { ...x, WrappingComponent: wrappingComponent };

            return column;
        });
    }, [columns])

    return (
        <BaseGroupedSubformGrid columns={calculatedColumns} {...props} />
    )
}


export default GroupedSubformGrid;
