import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { IUserDto, IUserGetSingle, ResponseStatus } from '../api/dtos';
import { BoundMutator } from '../utilities/BoundMutator';
import FormsContext from '../contexts/FormsContext';
import { Forms } from '../Forms';
import useValueChangedLog from './useValueChangedLog';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TUser extends IUserDto = IUserDto>(url: string, forms: Forms) => {
    const requestType = forms.dtos[`UserGetSingle`]

    const response: IUserResponse<TUser> = await forms.serviceStackClient.get(new requestType());

    return response;
}

export interface IUserResponse<TUser extends IUserDto = IUserDto> {
    user: TUser | null | undefined,
    responseStatus: ResponseStatus | undefined;
}

const useAuthenticatedUser = <TUser extends IUserDto = IUserDto>(configuration?: SWRConfiguration<IUserResponse<TUser>, IUserResponse<TUser>>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<IUserResponse<TUser>, IUserResponse<TUser>>([`${forms.baseRoute}/user`, forms], fetcher, configuration);

    const boundMutate: BoundMutator<IUserResponse<TUser>> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/user`, forms], data, shouldRevalidate);
    }, [forms]);

    const result = useMemo(() => {
        return {
            data: data?.user,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    useValueChangedLog(boundMutate, 'boundMutate');
    useValueChangedLog(data, 'data');
    useValueChangedLog(error, 'error');

    return result;
}

export default useAuthenticatedUser;