/* Options:
Date: 2024-02-29 09:08:58
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44343

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: NextMedicalReviewForm,MedicalReviewCreationOptions,MedicalReviewUpdateOptions,MedicalReviewRequestContext,PostHasPermissions,NewtonGreen.ServiceStack.MedicalReview.Interfaces/*,NewtonGreen.ServiceStack.MedicalReview.Enumerations/*,__MedicalReview.MasterGroup/*,__MedicalReview.CoordinatingGroup/*,__MedicalReview.Country/*,__MedicalReview.Institution/*,__MedicalReview.Patient/*,__MedicalReview.MedicalReview/*,__MedicalReview.Reviewer/*,__MedicalReview.Query/*,__MedicalReview.QueryRecipient/*,__MedicalReview.UpdatedFormProperty/*
//ExcludeTypes: 
DefaultImports: IFormDefinition:@ngt/forms,IFormType:@ngt/forms,IPatient:@ngt/forms-trials,IHasPatientId:@ngt/forms-trials,IForm:@ngt/forms,IDataModel:@ngt/forms,IReturn:@ngt/forms,IGet:@ngt/forms,IHasFormDefinitionId:@ngt/forms,IHasFormId:@ngt/forms,IPost:@ngt/forms,IConfigurationModel:@ngt/forms,IConfigurationGetSingleById:@ngt/forms,IConfigurationGetCollection:@ngt/forms,IConfigurationGetSingleByCode:@ngt/forms,IHasCode:@ngt/forms,IDataGetSingleById:@ngt/forms,IDataGetCollection:@ngt/forms,ResponseStatus:@ngt/forms,MedicalReview:./medicalReview,ValidationResult:./ValidationResult,Reviewer:./reviewer,MedicalReviewDefinition:./medicalReviewDefinition,Query:./query,QueryRecipient:./queryRecipient,UpdatedFormProperty:./updatedFormProperty
*/

import { IFormDefinition } from "@ngt/forms";
import { IFormType } from "@ngt/forms";
import { IPatient } from "@ngt/forms-trials";
import { IHasPatientId } from "@ngt/forms-trials";
import { IForm } from "@ngt/forms";
import { IDataModel } from "@ngt/forms";
import { IReturn } from "@ngt/forms";
import { IGet } from "@ngt/forms";
import { IHasFormDefinitionId } from "@ngt/forms";
import { IHasFormId } from "@ngt/forms";
import { IPost } from "@ngt/forms";
import { IConfigurationModel } from "@ngt/forms";
import { IConfigurationGetSingleById } from "@ngt/forms";
import { IConfigurationGetCollection } from "@ngt/forms";
import { IConfigurationGetSingleByCode } from "@ngt/forms";
import { IHasCode } from "@ngt/forms";
import { IDataGetSingleById } from "@ngt/forms";
import { IDataGetCollection } from "@ngt/forms";
import { ResponseStatus } from "@ngt/forms";
import { MedicalReview } from "./medicalReview";
import { ValidationResult } from "./ValidationResult";
import { Reviewer } from "./reviewer";
import { MedicalReviewDefinition } from "./medicalReviewDefinition";
import { Query } from "./query";
import { QueryRecipient } from "./queryRecipient";
import { UpdatedFormProperty } from "./updatedFormProperty";

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export interface IMedicalReviewGetNextFormByIds extends IGet
{
    medicalReviewId?: number;
    currentFormDefinitionId?: number;
    currentFormRepeat?: number;
    metadata: { [index: string]: Object; };
    statuses: MedicalReviewStatus[];
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

export class NextMedicalReviewForm
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public formDefinitionName: string;
    public formTypeId?: number;
    public formTypeCode: string;
    public formTypeName: string;
    public formRepeat?: number;

    public constructor(init?: Partial<NextMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewNextFormResponse
{
    nextForm: NextMedicalReviewForm;
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    startDate?: string;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    queryNumber?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IMedicalReviewFormType extends IFormType
{
    order?: number;
}

export interface IMedicalReviewFormDefinition extends IFormDefinition
{
    order?: number;
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewNextFormResponse implements IMedicalReviewNextFormResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public nextForm: NextMedicalReviewForm;

    public constructor(init?: Partial<MedicalReviewNextFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientSingleResponse(); }
}

// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
}

// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerCollectionResponse(); }
}

// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
}

// @Route("/medical-review/medical-review/{MedicalReviewId}/next-form", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetNextFormByIds implements IReturn<MedicalReviewNextFormResponse>, IMedicalReviewGetNextFormByIds
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=1)
    public currentFormDefinitionId?: number;

    // @DataMember(Order=1)
    public currentFormRepeat?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public statuses: MedicalReviewStatus[];

    public constructor(init?: Partial<MedicalReviewGetNextFormByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetNextFormByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewNextFormResponse(); }
}

// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

