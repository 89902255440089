import { useContext } from "react";
import { IUserDto } from "../api/dtos";
import AuthenticatedUserContext from "../contexts/utility/AuthenticatedUserContext";

const useUser = <TUser extends IUserDto = IUserDto>() => {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    return (authenticatedUserContext.data as TUser | null | undefined);
}

export default useUser;