import { LoadingButton } from "@mui/lab";
import { Button, Theme, useTheme } from "@mui/material";
import { useFormActions, useFormState, useScopedField } from "@ngt/forms-core";
import { IValidationError, ValidationErrorType } from "@ngt/forms";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { faBold } from "@fortawesome/pro-solid-svg-icons";
import { JsonServiceClient } from "@servicestack/client";
import * as Dtos from '../../api/dtos';
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FormContext, FormDefinitionContext, FormsContext } from "@ngt/forms";
import { PatientContext } from "@ngt/forms-trials";
import MedicalReviewContext from "../../contexts/data/MedicalReviewContext";
import { makeStyles } from "src/styles/makeStyles";


const NextButtonSubmit: FC = ({
    }) => {

        
    const theme = useTheme();
    
    const styles = useMemo(() => {
        return {
            margin: {
                marginLeft: theme.spacing(2)
            } 
        } 
    }, [theme]);


    const {fields, errors, validating, submitting} = useFormState({errors: true, fields: true, validating: true, submitting: true})

    const {data: medicalReview} = useContext(MedicalReviewContext)
    const {data: formDefinition} = useContext(FormDefinitionContext)
    const {data: form} = useContext(FormContext)
    const {data: patient} = useContext(PatientContext);
    const {serviceStackClient: client} = useContext(FormsContext);

    const {submit, setFieldValue, getErrors} = useFormActions<any, IValidationError>();

    const navigate = useNavigate();

    const onNext = useCallback(async () => {

        setFieldValue('medicalReviewStatus', Dtos.MedicalReviewStatus.Complete, true, true, true, false);
        await submit();


        const errors = getErrors();
        
        const errorPaths = Object.keys(errors);

        const hasErrors = errorPaths.some(path => errors[path]?.filter(x => x.type !== ValidationErrorType.Warning).length > 0);

        if (hasErrors) {
            return;
        }
        setFieldValue('FORM_NEXT', true, true, true, true, false);

        // setFieldValue('medicalReviewStatus', Dtos.MedicalReviewStatus.Complete, true, true, true, false);
        // await submit();

        const request = new Dtos.MedicalReviewGetNextFormByIds({
            medicalReviewId: medicalReview?.id,
            currentFormDefinitionId: formDefinition?.id,
            currentFormRepeat: form?.repeat,
            statuses: undefined,
        })

        try {
            var response = await client
                .get(request);

            if (response.nextForm) {
                if (response.nextForm.formRepeat === 1) {
                    navigate(`/medical-review/${patient?.studyNumber}/${medicalReview?.repeat}/${response.nextForm.formDefinitionCode}`);
                }
                else {
                    navigate(`/medical-review/${patient?.studyNumber}/${medicalReview?.repeat}/${response.nextForm.formDefinitionCode}/${response.nextForm.formRepeat}`);
                }
            }
            else {
                navigate(`/medical-review/${patient?.studyNumber}/${medicalReview?.repeat}`);
            }
        }
        catch
        {
            navigate(`/medical-review/${patient?.studyNumber}/${medicalReview?.repeat}`);
        }

    }, [navigate, formDefinition?.id, form?.repeat, medicalReview?.id, medicalReview?.repeat, patient?.studyNumber, submit, setFieldValue, client, getErrors])
    
    if ((form as Dtos.IMedicalReviewForm)?.medicalReviewStatus !== Dtos.MedicalReviewStatus.New && (form as Dtos.IMedicalReviewForm)?.medicalReviewStatus !== Dtos.MedicalReviewStatus.InProgress) {
        return null;
    }

    return (
        <LoadingButton
            variant="contained" 
            onClick={onNext}
            loading={submitting}
            sx={styles.margin}
        >
            Complete & Next
        </LoadingButton>
    )
}

export default NextButtonSubmit;