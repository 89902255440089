/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected formDefinition.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass formDefinition context to the rest of the app.
 */
import FormDefinitionByIdContext from '../../../contexts/configuration/FormDefinitionContext';

/*
 * Used to load master groups
 */
import useFormDefinitionById from '../../../hooks/configuration/useFormDefinitionById';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import LookupsContext from '../../../contexts/utility/LookupsContext';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormDefinitionByIdResolverProps {
    id: number,
    resolveBeforeLoad?: boolean | null;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormDefinitionByIdResolver: FunctionComponent<IFormDefinitionByIdResolverProps> = ({
    id,
    resolveBeforeLoad,
    children
}) => {
    const context = useFormDefinitionById(id);

    const lookupsContext = useLookupsByFormDefinitionId(context?.data?.id);

    const notLoading =
        !context?.loading &&
        !lookupsContext?.loading;

    return (
        <FormDefinitionByIdContext.Provider value={context}>
            <LookupsContext.Provider value={lookupsContext}>
                {
                    !resolveBeforeLoad || notLoading ?
                        children :
                        <RouteLoading />
                }
            </LookupsContext.Provider>
        </FormDefinitionByIdContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormDefinitionByIdResolver;