import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { CoordinatingGroupCollectionResponse, CoordinatingGroupGetCollection } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms) => {
    const response = await forms.serviceStackClient.get(new CoordinatingGroupGetCollection());

    return response;
}

const useCoordinatingGroups = (configuration?: SWRConfiguration<CoordinatingGroupCollectionResponse, CoordinatingGroupCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<CoordinatingGroupCollectionResponse, CoordinatingGroupCollectionResponse>([`${forms.baseRoute}/coordinating-group/collection`, forms], fetcher, configuration);

    const boundMutate: BoundMutator<CoordinatingGroupCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/coordinating-group/collection`, forms], data, shouldRevalidate);
    }, [forms])

    const result = useMemo(() => {
        return {
            data: data?.data,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useCoordinatingGroups;