/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { ComponentType, FunctionComponent, ReactNode } from 'react';

import { TableCell } from '@mui/material';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import FieldProvider from '../../../FieldProvider';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IGroupedSubformGridCellProps {
    name: string;
    content?: ReactNode;
    WrappingComponent?: ComponentType<{ children?: ReactNode }>;
}



/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const GroupedSubformGridCell: FunctionComponent<IGroupedSubformGridCellProps> = ({
    name,
    content,
    WrappingComponent
}) => {

    let internal = content;

    if (WrappingComponent) {
        internal = <WrappingComponent>
            {internal}
        </WrappingComponent>
    }


    return (
        <FieldProvider name={name} prependParentName={true}>
            <TableCell>
                {internal}
            </TableCell>
        </FieldProvider>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default GroupedSubformGridCell;
