import { faCheckCircle, faCircle, faCommentAltLines, faExclamationCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, darken, lighten, ListItem, ListItemIcon, ListItemText, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer, MedicalReviewStatus } from '../../api/dtos';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewSummaryListFormProps {
    patient?: IPatient;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    medicalReview?: IMedicalReview;
    reviewer?: IReviewer;
    formType: IFormType;
    formDefinition: IFormDefinition;
    formValidation: IValidationResult,
    hasMultiple: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            marginTop: -1,
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        } as any,
        icon: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        text: {
            margin: 0,
            paddingLeft: theme.spacing(2)
        },
        title: {
            display: 'flex',
            alignItems: 'center'
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: theme.palette.info.main
        },
        inProgress: {
            color: theme.palette.warning.main
        },
        complete: {
            color: theme.palette.success.main
        },
        cancelled: {
            color: theme.palette.grey[500]
        },
        unknown: {
            color: theme.palette.error.main
        },
        queryIcon: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        Boxider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        queryCount: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
});


const MedicalReviewSummaryListForm: FunctionComponent<IMedicalReviewSummaryListFormProps> = ({
    patient,
    medicalReview,
    formDefinition,
    formType,
    formValidation,
    hasMultiple,
    medicalReviewDefinition,
    reviewer
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        const getColor = theme.palette.mode === 'light' ? darken : lighten;
        const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
        const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

        return {
            item: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                marginTop: '-1px',
                borderLeft: `1px solid ${theme.palette.grey[300]}`,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            } as any,
            icon: {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            },
            text: {
                margin: 0,
                paddingLeft: theme.spacing(2)
            },
            title: {
                display: 'flex',
                alignItems: 'center'
            },
            titleMain: {
                marginRight: 'auto'
            },
            titleCount: {
                alignSelf: 'stretch',
                minWidth: '140px',
                display: 'flex',
                padding: theme.spacing(0, 2),
                borderWidth: '1px',
                borderStyle: 'solid',
                justifyContent: 'space-between',

                borderRadius: 0,

                color: getColor(theme.palette.grey[700], 0.6),
                backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
                borderColor: getBorderColor(theme.palette.grey[700], 0.8),
            },
            new: {
                color: theme.palette.info.main
            },
            inProgress: {
                color: theme.palette.warning.main
            },
            complete: {
                color: theme.palette.success.main
            },
            cancelled: {
                color: theme.palette.grey[500]
            },
            unknown: {
                color: theme.palette.error.main
            },
            queryIcon: {
                maxWidth: '40px',
                minWidth: '40px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            Boxider: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            queryCount: {
                maxWidth: '40px',
                minWidth: '40px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
        }
    }, [theme]);
    
    const medicalReviewContext = useContext(MedicalReviewExtensionContext);

    const title = useMemo(() => {
        const repeat = formValidation.metadata?.Repeat

        return (
            <Box sx={styles.title}>
                <Typography variant="subtitle1" sx={styles.titleMain}>
                    {formDefinition?.name} {hasMultiple && !!repeat && `(${repeat})`}
                </Typography>
            </Box>
        )
    }, [formDefinition, formValidation, hasMultiple, classes])

    const icon = useMemo(() => {
        switch (formValidation.metadata?.Status) {
            case MedicalReviewStatus.New:
                return <Tooltip title="New">
                    <Box>
                        <FontAwesomeIcon fixedWidth icon={faCircle} className={classes.new} size="2x" />
                    </Box>
                </Tooltip>;
            case MedicalReviewStatus.InProgress:
                return <Tooltip title="In Progress">
                    <Box>
                        <FontAwesomeIcon fixedWidth icon={faMinusCircle} className={classes.inProgress} size="2x" />
                    </Box>
                </Tooltip>;
            case MedicalReviewStatus.Complete:
                return <Tooltip title="Complete">
                    <Box>
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} className={classes.complete} size="2x" />
                    </Box>
                </Tooltip>;
            case MedicalReviewStatus.Cancelled:
                return <Tooltip title="Cancelled">
                    <Box>
                        <FontAwesomeIcon fixedWidth icon={faTimesCircle} className={classes.cancelled} size="2x" />
                    </Box>
                </Tooltip>;
            default:
                return <Tooltip title="Unknown">
                    <Box>
                        <FontAwesomeIcon fixedWidth icon={faExclamationCircle} className={classes.unknown} size="2x" />
                    </Box>
                </Tooltip>;
        }
    }, [formValidation.metadata?.Status, classes])

    const queries = useMemo(() => {
        //const total = formValidation.metadata?.Queries ?? 0;
        const issued = (formValidation.metadata?.IssuedQueries as unknown as number) ?? 0;
        //const responded = formValidation.metadata?.RespondedQueries ?? 0;
        //const closed = formValidation.metadata?.ClosedQueries ?? 0;

        if (issued === 0) {
            return null;
        }

        return (
            <Box sx={styles.titleCount}>
                <Box sx={styles.queryIcon}>
                    <span>
                        <FontAwesomeIcon icon={faCommentAltLines} size="lg" />
                    </span>
                </Box>
                <Box sx={styles.Boxider}>
                </Box>
                <Box sx={styles.queryCount}>
                    {issued}
                </Box>
            </Box>
        );
    }, [formValidation.metadata, classes])

    return (
        <ListItem
            sx={styles.item}
            button
            component={Link}
            to={medicalReviewContext?.createMedicalReviewFormRouteFn(patient!, medicalReview!, formDefinition!, formValidation!)}
        >
            <ListItemIcon
                sx={styles.icon}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                sx={styles.text}
                primary={title}
            />
            {queries}
        </ListItem>
    );
};

export default MedicalReviewSummaryListForm;
