/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { IFormSubscription, IFormState } from '../FormManager';
import useFormActions from '../hooks/useFormActions';
import ConditionContext from '../contexts/ConditionContext';
import FieldContext, { IFieldContext } from '../contexts/FieldContext';
import ConditionConfigurationContext, { ConditionMode, IConditionConfigurationContext } from '../contexts/ConditionConfigurationContext';
import ConditionActionContext from '../contexts/ConditionActionContext';
import ConditionFieldContext from '../contexts/ConditionFieldContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Form component.
 */
export interface IConditionProps<TValues extends object = any, TError = any> {
    onConditionNotMet?: IConditionConfigurationContext<TValues, TError>['onConditionNotMet'] | null;
    view?: IConditionConfigurationContext<TValues, TError>['view'];
    condition: (state: IFormState<TValues, TError>, parentPropertyPath?: string | null) => boolean;
    subscription?: Partial<IFormSubscription>;
    mode?: ConditionMode;
    registerParent?: boolean;
    children?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Condition = <TFormValue extends object = any, TError = any>({
    condition,
    subscription,
    mode,
    registerParent,
    children,
    onConditionNotMet,
    view
}: IConditionProps<TFormValue, TError>) => {
    const formActions = useFormActions<TFormValue, TError>();

    const conditionConfigurationContext = React.useContext(ConditionConfigurationContext);

    const newConditionConfigurationContext = React.useMemo(() => {
        return {
            onConditionNotMet: onConditionNotMet === null ? undefined : (onConditionNotMet ?? conditionConfigurationContext.onConditionNotMet),
            view: view ?? conditionConfigurationContext.view
        }
    }, [view, onConditionNotMet]);

    const parentContext = React.useContext(ConditionContext);
    const parentActionContext = React.useContext(ConditionActionContext);

    const fieldContext: IFieldContext<any, TFormValue, TError> | null = React.useContext(FieldContext);

    const parentFieldName = fieldContext?.name;

    const [conditionMet, setConditionMet] = React.useState<boolean>(() => {
        if (parentContext?.conditionMet === false) {
            return false;
        }

        return condition({
            value: formActions.getValue(),
            dirty: formActions.getDirty(),
            errors: formActions.getErrors(),
            fields: formActions.getFields(),
            focused: formActions.getFocused(),
            initialValue: formActions.getInitialValue(),
            touched: formActions.getTouched(),
            validating: formActions.getValidating(),
            submitting: formActions.getSubmitting()
        }, parentFieldName);
    });

    React.useEffect(() => {
        let met = false;

        if (parentContext?.conditionMet !== false) {
            met = condition({
                value: formActions.getValue(),
                dirty: formActions.getDirty(),
                fields: formActions.getFields(),
                errors: formActions.getErrors(),
                focused: formActions.getFocused(),
                initialValue: formActions.getInitialValue(),
                touched: formActions.getTouched(),
                validating: formActions.getValidating(),
                submitting: formActions.getSubmitting()
            }, parentFieldName);
        }

        setConditionMet(met);
    }, [formActions, setConditionMet, condition, parentContext?.conditionMet, parentFieldName]);


    React.useEffect(() => {
        const unsubscribe = formActions.subscribe(
            (formState) => {

                let met = false;

                if (parentContext?.conditionMet !== false) {
                    met = condition(formState, parentFieldName);
                }

                setConditionMet(met);
            },
            subscription as IFormSubscription | undefined
        );

        return () => {
            unsubscribe();
        }
    }, [formActions.subscribe, subscription, condition, parentContext?.conditionMet, parentFieldName]);

    const [names, setNames] = React.useState<string[]>([]);
    
    const addName = React.useCallback((name: string) => {
        parentActionContext?.addName(name);

        setNames(currentNames => {
            if (currentNames.includes(name)) {
                return currentNames;
            }

            return [...currentNames, name];
        });
    }, [setNames, parentActionContext?.addName]);

    const removeName = React.useCallback((name: string) => {
        parentActionContext?.removeName(name);

        setNames(currentNames => {
            if (!currentNames.includes(name)) {
                return currentNames;
            }

            return currentNames.filter(n => n !== name);
        });
    }, [setNames, parentActionContext?.removeName]);

    // Conditionally register parent field to clear field on disable.
    React.useEffect(() => {
        if (registerParent && parentFieldName) {
            addName(parentFieldName);
        }

        return () => {
            if (registerParent && parentFieldName) {
                removeName(parentFieldName)
            }
        }
    }, [registerParent, parentFieldName, addName, removeName]);

    const context = React.useMemo(() => {
        if (parentContext?.conditionMet === false) {
            return {
                conditionMet: false
            };
        }

        return {
            conditionMet
        }
    }, [conditionMet, parentContext?.conditionMet]);

    const conditionFieldContext = React.useMemo(() => {
        return {
            names
        }
    }, [names]);

    const conditionActionContext = React.useMemo(() => {
        return {
            addName,
            removeName
        }
    }, [addName, removeName]);

    React.useEffect(() => {
        if (!conditionMet) {
            if (newConditionConfigurationContext.onConditionNotMet) {
                newConditionConfigurationContext.onConditionNotMet(names, formActions);
            }
        }
    }, [conditionMet, names, formActions, newConditionConfigurationContext]);

    const View = newConditionConfigurationContext.view;

    return (
        <ConditionActionContext.Provider value={conditionActionContext}>
            <ConditionFieldContext.Provider value={conditionFieldContext}>
                <ConditionContext.Provider value={context}>
                    <View conditionMet={context.conditionMet} mode={mode ?? ConditionMode.Enable} children={children} />
                </ConditionContext.Provider>
            </ConditionFieldContext.Provider>
        </ConditionActionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Condition;