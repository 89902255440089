import { Theme } from '@mui/material';
import { getParentPropertyPath, pascalToCameCasePropertyPath } from '@ngt/forms';
import { useFieldState } from '@ngt/forms-core';
import React, { FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { makeStyles } from '../../styles/makeStyles';
import UpdatedFormPropertiesContext from '../../contexts/data/UpdatedFormPropertiesContext';

export interface IUpdatedFormPropertyAdditionalProperty {
    name?: string;
    prependParentPath?: boolean;
    fn?: (parentPropertyPath: string | undefined) => string[];
}

export interface IUpdatedFormPropertyStyler {
    excludeBaseProperty?: boolean;
    additionaProperties?: IUpdatedFormPropertyAdditionalProperty[];
    children?: ReactNode;
}

const subscription = {};

const useStyles = makeStyles()((theme: Theme) => ({
    contain: {
        color: '#66bb6a',
        fontWeight: 'bold !important',

        '& *': {
            color: '#66bb6a',
            fontWeight: 'bold !important'
        },

        '& > span > strong': {
            color: '#66bb6a',
            fontWeight: 'bold !important'
        }
    }
}));

const UpdatedFormPropertyStyler: FunctionComponent<IUpdatedFormPropertyStyler> = ({ excludeBaseProperty, additionaProperties, children }) => {
    const { name } = useFieldState(subscription);
    const { classes } = useStyles();

    const { data: updatedFormProperties } = useContext(UpdatedFormPropertiesContext);

    const hasUpdate = useMemo(() => {
        if (!updatedFormProperties) {
            return false;
        }

        let properties: string[] = [];

        if (excludeBaseProperty !== true) {
            properties.push(name);
        }

        if (additionaProperties && additionaProperties.length > 0) {
            const parentPath = getParentPropertyPath(name);

            additionaProperties.map(x => {
                if (x.fn) {
                    return x.fn(parentPath) ?? [];
                }

                if (!x.name) {
                    return [];
                }

                let field = x.name;

                if (x.prependParentPath !== false) {
                    field = `${parentPath}.${x.name}`;
                }

                return [field];

            }).forEach(x => {
                properties = properties.concat(x);
            });
        }

        return properties.some(x => updatedFormProperties.some(y => pascalToCameCasePropertyPath(y.propertyPath) === x));
    }, [name, updatedFormProperties, excludeBaseProperty, additionaProperties]);

    if (hasUpdate) {
        return (
            <div className={classes.contain}>
                {children}
            </div>
        );
    }

    return (
        <>
            {children}
        </>
    );
};

export default UpdatedFormPropertyStyler;