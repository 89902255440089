import { faCheckCircle, faChevronDown, faChevronUp, faCircle, faCommentAltLines, faExclamationCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse, darken, lighten, ListItem, ListItemIcon, ListItemText, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import React, { ComponentType, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer, MedicalReviewStatus } from '../../api/dtos';
import { faSlash } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewSummaryListQueriesProps {
    patient?: IPatient;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    medicalReview?: IMedicalReview;
    reviewer?: IReviewer;
    expanded: boolean;
    toggleExpanded: (id: number) => void;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult }>;
    formTypes: IFormType[];
    action?: ComponentType<IMedicalReviewSummaryQueryActionProps>;
}

export interface IMedicalReviewSummaryQueryActionProps {
    patient?: IPatient;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    medicalReview?: IMedicalReview;
    reviewer?: IReviewer;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult }>;
    formTypes: IFormType[];
}

const useStyles = makeStyles()((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex',
            marginTop: '-1px'
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        reviews: {
            paddingLeft: theme.spacing(6)
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: '140px',
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: '1px',
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        },
        inProgress: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
        },
        complete: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            borderColor: getBorderColor(theme.palette.success.main, 0.8),
        },
        queryItem: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            marginTop: '-1px',
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        } as any,
        queryNew: {
            color: theme.palette.info.main
        },
        queryInProgress: {
            color: theme.palette.warning.main
        },
        queryComplete: {
            color: theme.palette.success.main
        },
        queryCancelled: {
            color: theme.palette.grey[500]
        },
        queryUnknown: {
            color: theme.palette.error.main
        },
        completedForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        divider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        totalForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        icon: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        text: {
            margin: 0,
            paddingLeft: theme.spacing(2)
        },
        queryIcon: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        queryCount: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    }
});


const MedicalReviewSummaryListQueries: FunctionComponent<IMedicalReviewSummaryListQueriesProps> = ({
    expanded,
    medicalReviewDefinition,
    medicalReview,
    reviewer,
    forms,
    formTypes,
    toggleExpanded,
    action,
    patient
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        const getColor = theme.palette.mode === 'light' ? darken : lighten;
        const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
        const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

        return {
            container: {
                display: 'flex',
                marginTop: '-1px'
            },
            item: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            },
            reviews: {
                paddingLeft: theme.spacing(6)
            },
            title: {
                display: 'flex',
                alignItems: 'center',
                paddingTop: '4px',
                paddingBottom: '4px',
            },
            titleMain: {
                marginRight: 'auto'
            },
            titleCount: {
                alignSelf: 'stretch',
                minWidth: '140px',
                display: 'flex',
                padding: theme.spacing(0, 2),
                borderWidth: '1px',
                borderStyle: 'solid',
                justifyContent: 'space-between',

                borderRadius: 0,

                color: getColor(theme.palette.grey[700], 0.6),
                backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
                borderColor: getBorderColor(theme.palette.grey[700], 0.8),
            },
            new: {
                color: getColor(theme.palette.info.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
                borderColor: getBorderColor(theme.palette.info.main, 0.8),
            },
            inProgress: {
                color: getColor(theme.palette.warning.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
                borderColor: getBorderColor(theme.palette.warning.main, 0.8),
            },
            complete: {
                color: getColor(theme.palette.success.main, 0.6),
                backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
                borderColor: getBorderColor(theme.palette.success.main, 0.8),
            },
            queryItem: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                marginTop: '-1px',
                borderLeft: `1px solid ${theme.palette.grey[300]}`,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            } as any,
            queryNew: {
                color: theme.palette.info.main
            },
            queryInProgress: {
                color: theme.palette.warning.main
            },
            queryComplete: {
                color: theme.palette.success.main
            },
            queryCancelled: {
                color: theme.palette.grey[500]
            },
            queryUnknown: {
                color: theme.palette.error.main
            },
            completedForms: {
                maxWidth: 40,
                minWidth: 40,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            divider: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            totalForms: {
                maxWidth: 40,
                minWidth: 40,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            icon: {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            },
            text: {
                margin: 0,
                paddingLeft: theme.spacing(2)
            },
            queryIcon: {
                maxWidth: 40,
                minWidth: 40,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            queryCount: {
                maxWidth: 40,
                minWidth: 40,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }
        }
    }, [theme]);

    const medicalReviewContext = useContext(MedicalReviewExtensionContext);

    const onClick = useCallback(() => {
        toggleExpanded(-1)

    }, [toggleExpanded]);

    const ActionComponent = action;

    const querySummary = useMemo(() => {
        let total = 0;
        let issued = 0;
        let responded = 0; // eslint-disable-line
        let closed = 0;
        let cancelled = 0;

        forms.forEach(form => {
            total += (form.formValidation?.metadata?.Queries as unknown as number) ?? 0;
            issued += (form.formValidation?.metadata?.IssuedQueries as unknown as number) ?? 0;
            responded += (form.formValidation?.metadata?.RespondedQueries as unknown as number) ?? 0; // eslint-disable-line
            closed += (form.formValidation?.metadata?.ClosedQueries as unknown as number) ?? 0;
            cancelled += (form.formValidation?.metadata?.CancelledQueries as unknown as number) ?? 0;
        });

        total = total - cancelled;

        let icon = (
            <Tooltip title="Unknown">
                <div>
                    <FontAwesomeIcon fixedWidth icon={faExclamationCircle} className={classes.queryUnknown} size="2x" />
                </div>
            </Tooltip>
        );

        if (medicalReview?.status === MedicalReviewStatus.Cancelled) {
            icon = (
                <Tooltip title="Cancelled">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faTimesCircle} className={classes.queryCancelled} size="2x" />
                    </div>
                </Tooltip>
            )
        }
        else if (medicalReview?.status === MedicalReviewStatus.New) {
            icon = (
                <Tooltip title="New">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCircle} className={classes.queryNew} size="2x" />
                    </div>
                </Tooltip>
            )
        }
        else if (total !== closed) {
            icon = (
                <Tooltip title="In Progress">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faMinusCircle} className={classes.queryInProgress} size="2x" />
                    </div>
                </Tooltip>
            );
        }
        else if (total === closed) {
            icon = (
                <Tooltip title="Complete">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} className={classes.queryComplete} size="2x" />
                    </div>
                </Tooltip>
            );
        }

        if (issued === 0) {
            return {
                icon,
                queries: null,
                count: null
            };
        }

        return {
            icon,
            queries: (
                <div className={classes.titleCount}>
                    <div className={classes.queryIcon}>
                        <span>
                            <FontAwesomeIcon icon={faCommentAltLines} size="lg" />
                        </span>
                    </div>
                    <div className={classes.divider}>
                    </div>
                    <div className={classes.queryCount}>
                        {issued}
                    </div>
                </div>
            ),
            count: (
                <div
                    className={classNames(
                        classes.titleCount,
                        { [classes.new]: total === 0 },
                        { [classes.inProgress]: closed !== total },
                        { [classes.complete]: closed === total }
                    )}
                >
                    <div
                        className={classes.completedForms}
                    >
                        {closed}
                    </div>
                    <div
                        className={classes.divider}
                    >
                        <FontAwesomeIcon fixedWidth icon={faSlash} flip="horizontal" />
                    </div>
                    <div
                        className={classes.totalForms}
                    >
                        {total}
                    </div>
                </div>
            )
        };
    }, [forms, medicalReview, classes])

    return (
        <>
            <Box
                sx={styles.container}
            >
                <ListItem
                    button
                    sx={styles.item}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Box sx={styles.title}>
                                <Typography variant="subtitle1" className={classes.titleMain}>
                                    <strong>Queries</strong>
                                </Typography>
                            </Box>
                        )}
                    />
                    {querySummary.count}
                </ListItem>
                {
                    !!ActionComponent && (
                        <ActionComponent
                            patient={patient}
                            medicalReviewDefinition={medicalReviewDefinition}
                            medicalReview={medicalReview}
                            forms={forms}
                            formTypes={formTypes}
                            reviewer={reviewer}
                        />
                    )
                }
            </Box>
            <Collapse
                in={expanded}
            >
                <Box
                    sx={styles.reviews}
                >
                    <ListItem
                        sx={styles.queryItem}
                        button
                        component={Link}
                        to={medicalReviewContext?.createMedicalReviewQueriesRouteFn(patient!, medicalReview!)}
                    >
                        <ListItemIcon
                            sx={styles.icon}
                        >
                            {querySummary.icon}
                        </ListItemIcon>
                        <ListItemText
                            className={classes.text}
                            primary={
                                <div className={classes.title}>
                                    <Typography variant="subtitle1" className={classes.titleMain}>
                                        Query Summary
                                    </Typography>
                                </div>
                            }
                        />
                        {querySummary.queries}
                    </ListItem>
                </Box>
            </Collapse>
        </>
    );
};

export default MedicalReviewSummaryListQueries;
