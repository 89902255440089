import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '../../styles/makeStyles';
import { Alert, AlertTitle, AlertProps } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { Theme } from '@mui/material';


interface IBasicAlertProps {
    title?: string;
    description?: ReactNode;
    icon?: IconProp;
    severity?: AlertProps['severity'];
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2)
    },
}));

const useAlertStyles = makeStyles()((theme: Theme) => ({
    message: {
        flex: '1 1 auto'
    },
}));

const BasicAlert: FunctionComponent<IBasicAlertProps> = ({
    title,
    description,
    icon,
    severity
}) => {
    const {classes} = useStyles();

    const {classes: alertClasses} = useAlertStyles();

    if (!title && !description) {
        return null;
    }

    return (
        <Alert
            icon={icon ? <FontAwesomeIcon fixedWidth icon={icon} /> : undefined}
            severity={severity}
            classes={alertClasses}
        >
            {title && <AlertTitle className={classes.title}>{title}</AlertTitle>}
            {description}
        </Alert>
    )
};

export default BasicAlert;