import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Box, List, Theme } from '@mui/material';
import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { IFormType, IFormDefinition, IValidationResult } from '@ngt/forms';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer } from '../../api/dtos';
import MedicalReviewSummaryListType, { IMedicalReviewSummaryFormTypeActionProps } from './MedicalReviewSummaryListType';
import MedicalReviewSummaryListQueries, { IMedicalReviewSummaryQueryActionProps } from './MedicalReviewSummaryListQueries';
import { BasicAlert, IPatient } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewSummaryListProps {
    patient?: IPatient;
    reviewer?: IReviewer
    medicalReview?: IMedicalReview;
    medicalReviewDefinition?: IMedicalReviewDefinition;
    formValidations?: IValidationResult[];
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    actions?: Record<number, ComponentType<IMedicalReviewSummaryFormTypeActionProps>>;
    queryAction?:ComponentType<IMedicalReviewSummaryQueryActionProps>;
}

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        padding: theme.spacing(2)
    },
}));


const MedicalReviewSummaryList: FunctionComponent<IMedicalReviewSummaryListProps> = ({
    medicalReview,
    medicalReviewDefinition,
    formDefinitions,
    formTypes,
    formValidations,
    actions,
    patient,
    reviewer,
    queryAction
}) => {
    const { classes } = useStyles();

    const [expanded, setExpanded] = useState<Record<number, boolean>>({})

    const combinedForms = useMemo(() => {
        return formValidations?.map(formValidation => {
            const formDefinition = formDefinitions?.find(d => d.id === formValidation.metadata.FormDefinitionId);

            const hasMultiple = (formValidations.filter(x => formDefinition?.id === x.metadata.FormDefinitionId)?.length ?? 0) > 1;

            return {
                formValidation,
                formDefinition: formDefinition!,
                hasMultiple
            }
        }).filter(x => !!x) ?? [];
    }, [formValidations, formDefinitions])

    const combinedTypes = useMemo(() => {
        return formTypes?.map(formType => {
            const forms = combinedForms?.filter(x => x.formDefinition?.type === formType.id) ?? [];

            if (forms.length === 0) {
                return null;
            }

            return {
                formType,
                forms: forms,
                action: actions?.[formType.id!]
            };
        }).filter(x => !!x) ?? [];

    }, [combinedForms, formTypes, actions]);

    useEffect(() => {
        setExpanded(currentExpanded => {
            const newExpanded: Record<number, boolean> = {}

            formTypes?.forEach(formType => {
                newExpanded[formType.id!] = currentExpanded[formType.id!] ?? true;
            })

            newExpanded[-1] = currentExpanded[-1] ?? true;

            return newExpanded;
        })
    }, [formTypes, setExpanded]);

    const toggleExpanded = useCallback((id: number) => {
        setExpanded(currentExpanded => ({
            ...currentExpanded,
            [id]: !currentExpanded[id]
        }))
    }, [setExpanded]);

    const noFormTypes = !combinedTypes?.length;

    if (noFormTypes) {
        return (
            <Box sx={{
                padding: 2
            }}>
                <BasicAlert
                    title="No Reports"
                    description="This medical review contains no reports."
                    icon={faExclamationTriangle}
                    severity="warning"
                />
            </Box>
        )
    }

    return (
        <List>
            {
                combinedTypes.map(combinedType => {
                    if (!combinedType) {
                        return null;
                    }

                    return (
                        <MedicalReviewSummaryListType
                            key={combinedType.formType?.id}
                            patient={patient}
                            medicalReview={medicalReview}
                            medicalReviewDefinition={medicalReviewDefinition}
                            reviewer={reviewer}
                            expanded={expanded[combinedType.formType?.id!]}
                            toggleExpanded={toggleExpanded}
                            action={combinedType.action}
                            forms={combinedType.forms}
                            formType={combinedType.formType}
                        />
                    )
                })
            }
            <MedicalReviewSummaryListQueries
                patient={patient}
                medicalReview={medicalReview}
                medicalReviewDefinition={medicalReviewDefinition}
                reviewer={reviewer}
                expanded={expanded[-1]}
                toggleExpanded={toggleExpanded}
                formTypes={formTypes!}
                forms={combinedForms}
                action={queryAction}
            />
        </List>
    );
};

export default MedicalReviewSummaryList;
