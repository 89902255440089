import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext, IValidationResult } from '@ngt/forms';
import { IPatient, PatientGetSingleById, PatientPostCreate, PatientPostSave, PatientPostValidate, PatientSingleResponse, PatientValidationResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TPatient extends IPatient = IPatient>(url: string, forms: Forms, id?: number, data?: TPatient, metadata?: Record<string, any>) => {
    if (!id) {
        return await create<TPatient>(forms, data, metadata);
    }

    return await get<TPatient>(forms, id)
}

const create = async <TPatient extends IPatient = IPatient>(forms: Forms, data?: TPatient, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new PatientPostCreate({ data, metadata }));

    return response;
}

const get = async <TPatient extends IPatient = IPatient>(forms: Forms, id: number) => {
    const response = await forms.serviceStackClient.get(new PatientGetSingleById({ id }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TPatient extends IPatient = IPatient>(forms: Forms, data: TPatient, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new PatientPostSave({ data, metadata }));

    return response;
}

const validate = async <TPatient extends IPatient = IPatient, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, data: TPatient, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new PatientPostValidate({ data, metadata }));

    return response;
}

const usePatientById = <TPatient extends IPatient = IPatient, TValidationResult extends IValidationResult = IValidationResult>(id?: number | null, createData?: TPatient, createMetadata?: Record<string, any>, load?: boolean, configuration?: SWRConfiguration<PatientSingleResponse, PatientSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!id && !createData && !createMetadata) {
            return null;
        }

        if (!id) {
            return [`${forms.baseRoute}/patient/single/id`, forms, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/patient/single/id`, forms, id, null, null]
    }, [forms, id, createData, createMetadata])

    const { data, error } = useSWR<PatientSingleResponse, PatientSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<PatientSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TPatient, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TPatient>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TPatient, metadata?: Record<string, any>) => {
        const response = await validate<TPatient, TValidationResult>(forms, validateData, metadata);

        return response?.validationResult;
    }, [boundMutate, forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TPatient | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default usePatientById;