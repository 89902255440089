import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormTypeCollectionResponse, FormTypeGetCollection } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms) => {
    const response = await forms.serviceStackClient.get(new FormTypeGetCollection());

    return response;
}

const useFormTypes = (configuration?: SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<FormTypeCollectionResponse, FormTypeCollectionResponse>([`${forms.baseRoute}/form-type/collection`, forms], fetcher, configuration);

    const boundMutate: BoundMutator<FormTypeCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/form-type/collection`, forms], data, shouldRevalidate);
    }, [forms])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useFormTypes;