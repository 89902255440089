import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionGetSingleById } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, id?: number) => {
    const response = await forms.serviceStackClient.get(new MedicalReviewDefinitionGetSingleById({ id }));

    return response;
}

const useMedicalReviewDefinitionById = (id?: number, configuration?: SWRConfiguration<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>(id ? [`${forms.baseRoute}/medical-review-definition/single/id`, forms, id] : null, fetcher, configuration);

    const boundMutate: BoundMutator<MedicalReviewDefinitionSingleResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/medical-review-definition/single/id`, forms, id] , data, shouldRevalidate);
    }, [forms, id])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useMedicalReviewDefinitionById;