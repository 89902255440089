/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the Forms system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, ReactNode, useContext } from 'react';

/*
 * Used to provide the MUI Theme to the rest of the app.
 */
import { ThemeProvider } from '@mui/material/styles';

/*
 * Used to provide the Date Functions required by MUI Date Pickers to the rest of the
 * app.
 */

import LuxonUtils from '@date-io/luxon';

import { Router } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for Typings
 */
import Forms from '../Forms';

/*
 * Used to provide the Forms object to the rest of the app.
 */
import FormsContext from '../contexts/FormsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormsProvider component.
 */
export interface IFormsLayoutProps {
    children?: ReactNode
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormsLayout: FunctionComponent<IFormsLayoutProps> = ({
    children
}) => {
    const forms = useContext(FormsContext);

    const compiledChildren = React.useMemo(() => {
        let c = children;

        forms.extensions?.reverse().forEach(extension => {
            if (extension.ContentComponent) {
                const Component = extension.ContentComponent;

                c = (
                    <Component>
                        {c}
                    </Component>
                );
            }
        })

        return c;
    }, [forms.extensions, children]);

    return (
        <>
            {compiledChildren}
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormsLayout;