/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { makeStyles, Theme } from '@mui/material/styles';

import { Slider as MuiSlider, SliderProps as MuiSliderProps, Box } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';
import { IInputRenderProps } from '@ngt/forms-core';
import FormLookupContext from '../../../contexts/form/FormLookupContext';
import SelectIcon from '../../selector/SelectIcon';
import { FormControl, FormControlProps, FormHelperText, InputLabel } from '@mui/material';
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type SliderProps = Partial<MuiSliderProps> & IInputRenderProps<number, Dtos.IValidationError>

export interface ISliderProps extends Omit<SliderProps, 'margin'> {
    nullOption?: boolean;
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
    label?: React.ReactNode;
    margin?: FormControlProps['margin'];
    fullWidth?: boolean;
}

interface ISliderIconProps {
    className?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

//const useStyles = makeStyles<Theme>((theme: Theme) => ({
//    text: {
//    }
//}));

//const useIconStyles = makeStyles<Theme>((theme: Theme) => ({
//    icon: {
//        top: 'calc(50% - 8px)'
//    }
//}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Slider: React.FunctionComponent<ISliderProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, touch, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    nullOption,
    margin,
    label,
    fullWidth,
    onBlur,
    onFocus,
    hasErrors,
    errors,
    disabled,
    ...rest
}) => {
    
    const onChangeCombined = React.useCallback((event: Event, value: number | Array<number>, activeThumb: number) => {

        if (onChange) {
            onChange(event, value, activeThumb);
        }

        onInputChange(value as any, true, true, true);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
        touch();
    }, [onBlur, onInputBlur]);
        
    return (
        <FormControl
            error={hasErrors}
            margin={margin}
            disabled={disabled}
            fullWidth={fullWidth === undefined ? true : fullWidth}
        >
            {label && <InputLabel>{label}</InputLabel>}
            <MuiSlider
                {...rest as any}
                onBlur={onBlurCombined}
                onFocus={onFocusCombined}
                name={name}
                value={value ?? ''}
                onChange={onChangeCombined}
            >

            </MuiSlider>
            {
                errors && errors.length > 0 && (
                    <FormHelperText>
                        {errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Slider;
