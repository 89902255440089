/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reducer registry class used to control adding reducers
 * and logic to the redux store at run time.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import produce, { immerable } from 'immer';
import { get, set } from 'lodash'
import getDeepKeys from './utilities/getDeepKeys';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get object property paths.
 */
//import getDeepKeys from './utilities/getDeepKeys';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This enum specifies when the form should be validated.
 */
export enum ValidateOn {
    /** When the form is submitted. */
    onSubmit,
    /** When focus is lost from a field. */
    onBlur,
    /** When a field is changed. */
    onChange
}

/** The function interface used for the form validation function. */
export interface IFormValidate<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>): Promise<Record<string, TError[]>>
}

/** The function interface used for the form allow submit function. */
export interface IFormAllowSubmit<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>): Promise<boolean>;
}

/** The function interface used for the form submit function. */
export interface IFormSubmit<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>): Promise<void | Record<string, TError[]>>
}

/** The function interface used for the form submit failed function. */
export interface IFormSubmitFailed<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>, submitException: any): Promise<void | Record<string, TError[]>>
}

/** The function interface used for the form validation failed (error thrown) function. */
export interface IFormSubmitValidationFailed<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>, validationException: any): Promise<void | Record<string, TError[]>>
}

/** The interface used for defining the configuration options of the form. */
export interface IFormManagerOptions<TFormValue extends object = any, TError = any> {
    /** The initial values that the form should use. */
    initialValues?: TFormValue | null;
    /** How the form should validate the fields. */
    onValidate?: IFormValidate<TFormValue, TError> | null;
    /** When the form should allow submission. */
    allowSubmit?: IFormAllowSubmit<TFormValue, TError> | null;
    /** What the form should do on submission. */
    onSubmit?: IFormSubmit<TFormValue, TError> | null;
    /** What the form should do if the validation fails. */
    onSubmitValidationFailed?: IFormSubmitValidationFailed<TFormValue, TError> | null;
    /** What the form should do if the submission fails. */
    onSubmitFailed?: IFormSubmitFailed<TFormValue, TError> | null;
    /** When the form should validate. */
    validateOn?: ValidateOn;
    /** 
     *  If the form should delay clearing errors on a reset and instead revalidate 
     *  the form to correct the error state of the form.
     *  
     *  If using asynchronous validation with errors not hidden by touched state 
     *  this could fix errors that flicker when reseting the form.
     */
    validateOnReset?: boolean;

    /** 
     *  This will ensure that no classes have been passed to the form manager.
     *  
     *  This option should only be used during development.
     */
    checkForClasses?: boolean;
}

/** The interface used for the form state. */
export interface IFormState<TFormValue extends object = any, TError = any> {
    /** The current value of the form. */
    value: TFormValue | null | undefined;
    /** The registered field property paths. */
    fields: string[];
    /** Which fields have been touched (focused + blurred or changed). */
    touched: Record<string, boolean>;
    /** Which fields have been changed */
    dirty: Record<string, boolean>;
    /** The initial value of the form. */
    initialValue: TFormValue | null | undefined;
    /** Which fields are currently focused. */
    focused: Record<string, boolean>;
    /** Which fields currently have errors. */
    errors: Record<string, TError[]>;
    /** If the form is currently validating. */
    validating: boolean;
    /** If the form is currently submitting. */
    submitting: boolean;
}

export interface IFormGetActions<TFormValue extends object = any, TError = any> {
    getValue: FormManager<TFormValue, TError>['getValue'];
    getTouched: FormManager<TFormValue, TError>['getTouched'];
    getDirty: FormManager<TFormValue, TError>['getDirty'];
    getFocused: FormManager<TFormValue, TError>['getFocused'];
    getErrors: FormManager<TFormValue, TError>['getErrors'];
    getInitialValue: FormManager<TFormValue, TError>['getInitialValue'];
    getSubmitting: FormManager<TFormValue, TError>['getSubmitting'];
    getValidating: FormManager<TFormValue, TError>['getValidating'];
    getFields: FormManager<TFormValue, TError>['getFields'];
}

export interface IFormSetActions<TFormValue extends object = any, TError = any> {
    setValue: FormManager<TFormValue, TError>['setValue'];
    setTouched: FormManager<TFormValue, TError>['setTouched'];
    setDirty: FormManager<TFormValue, TError>['setDirty'];
    setFocused: FormManager<TFormValue, TError>['setFocused'];
    setErrors: FormManager<TFormValue, TError>['setErrors'];
    setValidating: FormManager<TFormValue, TError>['setValidating'];
    setSubmitting: FormManager<TFormValue, TError>['setSubmitting'];
    setFields: FormManager<TFormValue, TError>['setFields'];
}

export interface IFormSubscribeActions<TFormValue extends object = any, TError = any> {
    subscribe: FormManager<TFormValue, TError>['subscribeToForm'];
    subscribeToField: FormManager<TFormValue, TError>['subscribeToField'];
}

export interface IFormProcessActions<TFormValue extends object = any, TError = any> {
    reset: FormManager<TFormValue, TError>['reset'];
    submit: FormManager<TFormValue, TError>['submit'];
    validate: FormManager<TFormValue, TError>['validate'];
}

export interface IFieldGetActions<TFormValue extends object = any, TError = any> {
    getFieldValue: FormManager<TFormValue, TError>['getFieldValue'];
    getFieldTouched: FormManager<TFormValue, TError>['getFieldTouched'];
    getFieldDirty: FormManager<TFormValue, TError>['getFieldDirty'];
    getFieldFocused: FormManager<TFormValue, TError>['getFieldFocused'];
    getFieldErrors: FormManager<TFormValue, TError>['getFieldErrors'];
    getFieldInitialValue: FormManager<TFormValue, TError>['getFieldInitialValue'];
}

export interface IFieldSetActions<TFormValue extends object = any, TError = any> {
    setFieldValue: FormManager<TFormValue, TError>['setFieldValue'];
    setFieldTouched: FormManager<TFormValue, TError>['setFieldTouched'];
    setFieldDirty: FormManager<TFormValue, TError>['setFieldDirty'];
    setFieldFocused: FormManager<TFormValue, TError>['setFieldFocused'];
    setFieldErrors: FormManager<TFormValue, TError>['setFieldErrors'];
}

export interface IFormActions<TFormValue extends object = any, TError = any> extends 
    IFormGetActions<TFormValue, TError>, 
    IFormSetActions<TFormValue, TError>,
    IFormSubscribeActions<TFormValue, TError>,
    IFormProcessActions<TFormValue, TError>,
    IFieldGetActions<TFormValue, TError>,
    IFieldSetActions<TFormValue, TError> {
    
}

/** The interface used to describe subscriptions to the form. */
export interface IFormSubscription extends Record<keyof IFormState, boolean> {

}

/** The interface used to describe a listener to the form (a subscription + event to run). */
export interface IFormListener<TFormValue extends object = any, TError = any> {
    /** When to notifiy the subscription. */
    subscription: IFormSubscription;
    /** What to do when notifying the subscription. */
    subscriber: IFormSubscriber<TFormValue, TError>;
}

/** The interface used for the field state. */
export interface IFieldState<TFieldValue = any, TError = any> {
    /** The current value of the field. */
    value: TFieldValue | null | undefined;
    /** Has the field been touched (focused + blurred). */
    touched: boolean;
    /** Is the field dirty (changed). */
    dirty: boolean;
    /** The initial value of the field. */
    initialValue: TFieldValue | null | undefined;
    /** Is the field focused. */
    focused: boolean;
    /** The current errors for the field. */
    errors: TError[];
}

/** The interface used to describe subscriptions to a field. */
export interface IFieldSubscription extends Record<keyof IFieldState, boolean> {

}

/** The function interface used to define a subscription event for the form. */
export interface IFormSubscriber<TFormValue extends object = any, TError = any> {
    (formState: IFormState<TFormValue, TError>, formActions: IFormActions<TFormValue, TError>): void;
}

/** The function interface used to define a subscription event for a field. */
export interface IFieldSubscriber<TFieldValue = any, TFormValue extends object = any, TError = any> {
    (fieldState: IFieldState<TFieldValue, TError>, formActions: IFormActions<TFormValue, TError>): void;
}

/** The interface used to describe a listener to a field (a subscription + event to run). */
export interface IFieldListener<TFieldValue = any, TFormValue extends object = any, TError = any> {
    /** The property path of the field to subscribe to. */
    propertyPath: string;
    /** When to notifiy the subscription. */
    subscription: IFieldSubscription;
    /** What to do when notifying the subscription. */
    subscriber: IFieldSubscriber<TFieldValue, TFormValue, TError>;
}

/** The function interface used to describe an unsubscribe event. */
export interface IUnsubscribe {
    (): void;
}

export interface IFormMutation<TFormValue extends object = any, TError = any, TValue = any> {
    (value: TValue, formGetActions: IFormGetActions<TFormValue, TError> & IFieldGetActions<TFormValue, TError>): TValue | undefined | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

const isDevelopment = process.env.NODE !== "production";

/**
 * This class handles the form state of a form
 */
export class FormManager<TFormValue extends object = any, TError = any> {
    private state: IFormState<TFormValue, TError> = {
        value: undefined,
        initialValue: undefined,
        touched: {},
        dirty: {},
        errors: {},
        fields: [],
        focused: {},
        submitting: false,
        validating: false
    }
    
    /** What to do on submission of the form. */
    private onSubmit?: IFormSubmit<TFormValue, TError> | null = null;

    /** What to do to validate the form. */
    private onValidate?: IFormValidate<TFormValue, TError> | null = null;

    /** What to do if the validation fails. */
    private onSubmitValidationFailed?: IFormSubmitValidationFailed<TFormValue, TError> | null = null;

    /** What to do if submission fails */
    private onSubmitFailed?: IFormSubmitFailed<TFormValue, TError> | null = null;

    /** The collection of listeners registered to the form. */ 
    private formListeners: IFormListener<TFormValue, TError>[] = [];

    /** The collection of listeners registered to form fields. */
    private fieldListeners: IFieldListener<any, TFormValue, TError>[] = [];

    /** When the form should validate. */
    private validateOn: ValidateOn = ValidateOn.onSubmit;

    /** When the form should allow submission */
    private allowSubmit?: IFormAllowSubmit<TFormValue, TError> | null = null;

    /**
     *  If the form should delay clearing errors on a reset and instead validate
     *  the form to correct the error state of the form.
     *
     *  If using asynchronous validation with errors not hidden by touched state
     *  this could fix errors that flicker when reseting the form.
     */
    private validateOnReset: boolean = false;

    

    /** 
     *  This will ensure that no classes have been passed to the form manager.
     *  
     *  This option should only be used during development.
     */
    private checkForClasses?: boolean;

    private validateInitialValue = (initialValue: TFormValue | null | undefined) => {
        

        if (initialValue) {
            if (typeof initialValue !== "object") {
                throw new Error('Initial Value must be an object.');
            }

            if (this.checkForClasses) {
                this.checkNoClassesUsed(initialValue);
            }
        }
    }

    private checkNoClassesUsed (value: any) {
        if (!value) {
            return;
        }

        if (typeof value !== "object") {
            return;
        }
        
        const keys = getDeepKeys(value);

        if (value.constructor.name !== 'Object' && 
            value.constructor.name !== 'Array') {

            throw new Error('Object cannot be a complex class (only Arrays and POJOs allowed)');
        }

        for (let key of keys) {
            const current = get(value, key);

            if (typeof current !== "object") {
                continue;
            }

            if (current !== 'Object' && 
                current !== 'Array') {

                throw new Error('Object cannot be a complex class (only Arrays and POJOs allowed): \'' + key + '\'');
            }
        }
    }

    /**
     * The constructor for the form.
     * @param options The configuration options for the form.
     */
    constructor(options?: IFormManagerOptions<TFormValue, TError>) {
        this.validateInitialValue(options?.initialValues);

        /*
         * Set the configuration options. 
         */
        this.state.initialValue = options?.initialValues;
        this.onSubmit = options?.onSubmit;
        this.onSubmitFailed = options?.onSubmitFailed;
        this.onSubmitValidationFailed = options?.onSubmitValidationFailed;
        this.onValidate = options?.onValidate;
        this.allowSubmit = options?.allowSubmit;
        this.validateOn = options?.validateOn ?? ValidateOn.onSubmit;
        this.validateOnReset = options?.validateOnReset ?? false;
        this.checkForClasses = options?.checkForClasses ?? process.env.NODE_ENV === 'development';

        /*
         * Reset the form.
         * 
         * This will run through and initialise the remaining values of the form.
         */
        this.reset(true);
    }

    /**
     * Set what the form should do on submission.
     * @param onSubmit What to do on submission.
     */
    public setOnSubmit = (onSubmit?: IFormSubmit<TFormValue, TError> | null) => {
        this.onSubmit = onSubmit;
    }

    /**
     * Set how the form determines if submission is allowed.
     * @param allowSubmit how to determine if submission is allowed.
     */
    public setAllowSubmit = (allowSubmit?: IFormAllowSubmit<TFormValue, TError> | null) => {
        this.allowSubmit = allowSubmit;
    }

    /**
     * Set how to validate the form.
     * @param onValidate How to validate the form.
     */
    public setOnValidate = (onValidate?: IFormValidate<TFormValue, TError> | null) => {
        this.onValidate = onValidate;
    }

    /**
     * Set when to validate the form.
     * @param validateOn When to validate the form.
     */
    public setValidateOn = (validateOn?: ValidateOn | null) => {
        this.validateOn = validateOn ?? ValidateOn?.onSubmit;
    }

    /**
     * Set the initial value of the form.
     * 
     * This will reset the form.
     * @param initialValue The initial value.
     * @param notify Whether to notify the form of the changes (default: true).
     */
    public setInitialValue = (initialValue?: TFormValue | IFormMutation<TFormValue, TError, TFormValue> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            let newInitialValue = undefined;

            if (initialValue instanceof Function) {
                newInitialValue = initialValue(draft.initialValue as any, this.getGetActions()) as any;
            }
            else {
                newInitialValue = initialValue as any;
            }

            this.validateInitialValue(newInitialValue);

            draft.initialValue = newInitialValue;
        });

        if (prev !== this.state) {
            this.reset(true);
        }

        if (notify !== false) {
            this.notify(prev);
        }
    }

    /**
     * Get the initial value of the form. 
     */
    public getInitialValue = () => {
        return this.state.initialValue;
    }

    /**
     * Reset the form to the initial state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
    public reset = (notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            draft.dirty = {};
            draft.focused = {};
            draft.touched = {};
            draft.value = draft.initialValue;
            draft.validating = false;
            draft.submitting = false;

            if (this.validateOnReset) {
                draft.errors = {};
            }
        })

        if (this.validateOnReset && prev.value !== this.state.value) {
            this.validate();
        }

        if (notify !== false) {
            this.notify(prev);
        }
    }


    private getPropertyPaths = <TValue extends object = any>(values: TValue) => {
        return getDeepKeys(values);
    }

    /**
     * Set the value of the form.
     * @param prev The previous value of the form.
     * @param next The new value of the form.
     */
    private getUpdatedPropertyPaths = (previous?: TFormValue | null, next?: TFormValue | null) => {
        const previousPaths: string[] = previous ? this.getPropertyPaths(previous) : [];
        const nextPaths: string[] = next ? this.getPropertyPaths(next) : [];

        const previousSet: Set<string> = new Set(previousPaths);
        const nextSet: Set<string> = new Set(nextPaths);

        const changedPaths: string[] = previousPaths
            .filter(previousPath => !nextSet.has(previousPath) || get(previous, previousPath) !== get(next, previousPath))
            .concat(nextPaths.filter(nextKey => !previousSet.has(nextKey)));

        return changedPaths;
    }

    /**
     * Set the value of the form.
     * @param prev The previous state of the form.
     * @param updateDirty Whether to update the dirty state of the fields (default: true).
     * @param updateTouched Whether to update the touched state of the fields (default: false).
     * @param updateFocused Whether to update the focused state of the fields (default: false).
     */
    private updateFieldStates(prev: IFormState<TFormValue, TError>, updateDirty?:boolean, updateTouched?: boolean, updateFocused?: boolean)
    {
        if (updateDirty === false && 
            !updateTouched && 
            !updateFocused) {
            return;
        }

        const updatedPropertyPaths = this.getUpdatedPropertyPaths(prev.value, this.state.value);

        if (!updatedPropertyPaths || updatedPropertyPaths.length === 0) {
            return;
        }

        this.state = produce(this.state, draft => {
            if (updateDirty !== false) {
                for (let updatedPropertyPath of updatedPropertyPaths) {
                    draft.dirty[updatedPropertyPath] = true;
                }
            }

            if (updateTouched) {
                for (let updatedPropertyPath of updatedPropertyPaths) {
                    draft.touched[updatedPropertyPath] = true;
                }
            }

            if (updateFocused) {
                let focusPaths: string[] = [ ];

                for (let updatedPropertyPath of updatedPropertyPaths) {
                    if (focusPaths.length === 0) {
                        focusPaths.push(updatedPropertyPath);
                        continue;
                    }
                    
                    const oldFocusPaths = focusPaths;
                    focusPaths = focusPaths.filter(x => updatedPropertyPath.startsWith(x));

                    if (oldFocusPaths.length !== focusPaths.length) {
                        if (focusPaths.length === 0) {
                            break;
                        }

                        continue;
                    }

                    focusPaths.push(updatedPropertyPath);
                }

                if (focusPaths.length === 0) {
                    draft.focused = {};
                }
                else {
                    const focusedPath = focusPaths[focusPaths.length - 1];

                
                    const focusedKeys = Object.keys(draft.focused);

                    if (!draft.focused[focusedPath] || focusedKeys.length !== 1) {
                        draft.focused = {
                            [focusedPath]: true
                        }
                    }
                }
            }

        });
    }

    /**
     * Set the value of the form.
     * @param value The value.
     * @param notify Whether to notify the form of the changes (default: true).
     * @param updateDirty Whether to update the dirty state of the fields (default: true).
     * @param updateTouched Whether to update the touched state of the fields (default: false).
     * @param updateFocused Whether to update the focused state of the fields (default: false).
     */
    public setValue = (value?: TFormValue | IFormMutation<TFormValue, TError, TFormValue> | null, notify?: boolean, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (value instanceof Function) {
                draft.value = value(draft.value as any, this.getGetActions()) as any;
            }
            else {
                draft.value = value as any;
            }
        });

        if (prev !== this.state) {
            this.updateFieldStates(prev, updateDirty, updateTouched, updateFocused);
        }

        if (notify !== false) {
            this.notify(prev);
        }

        /* Validate form if configured to validate on value change. */
        if (this.validateOn === ValidateOn.onChange && this.state.value !== prev.value) {
            this.validate()
        }
    }
    
    /**
     *  Get the current value of the form.
     */
    public getValue = () => {
        return this.state.value;
    }

    /**
     * Set the list of registered fields.
     * @param fields The registered fields.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFields = (fields?: string[] | IFormMutation<TFormValue, TError, string[]> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (fields instanceof Function) {
                draft.fields = fields(draft.fields as any, this.getGetActions()) as any;
            }
            else {
                draft.fields = fields as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }

    /**
     * Get the property paths of all fields currently registered on the form.
     */
    public getFields = () => {
        return this.state.fields;
    }

    /**
     * Set the submitting state of the form.
     * @param submitting The submitting state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setSubmitting = (submitting?: boolean | IFormMutation<TFormValue, TError, boolean> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (submitting instanceof Function) {
                draft.submitting = submitting(draft.fields as any, this.getGetActions()) as any;
            }
            else {
                draft.submitting = submitting as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get whether the form is submitting or not.
     */
    public getSubmitting = () => {
        return this.state.submitting;
    }

    /**
     * Set the validating state of the form.
     * @param validating The validating state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setValidating = (validating?: boolean | IFormMutation<TFormValue, TError, boolean> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (validating instanceof Function) {
                draft.validating = validating(draft.fields as any, this.getGetActions()) as any;
            }
            else {
                draft.validating = validating as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get whether the form is validating or not.
     */
    public getValidating = () => {
        return this.state.validating;
    }

    /**
     * Set the touched state of the form.
     * @param touched The touched state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setTouched = (touched?: Record<string, boolean> | IFormMutation<TFormValue, TError, Record<string, boolean>> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (touched instanceof Function) {
                draft.touched = touched(draft.touched as any, this.getGetActions()) as any;
            }
            else {
                draft.touched = touched as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get which form fields are touched.
     */
    public getTouched = () => {
        return this.state.touched;
    }

    /**
     * Set the dirty state of the form.
     * @param dirty The dirty state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setDirty = (dirty?: Record<string, boolean> | IFormMutation<TFormValue, TError, Record<string, boolean>> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (dirty instanceof Function) {
                draft.dirty = dirty(draft.dirty as any, this.getGetActions()) as any;
            }
            else {
                draft.dirty = dirty as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get which form fields are dirty.
     */
    public getDirty = () => {
        return this.state.dirty;
    }

    /**
     * Set the focused state of the form.
     * @param focused The focused state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFocused = (focused?: Record<string, boolean> | IFormMutation<TFormValue, TError, Record<string, boolean>> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (focused instanceof Function) {
                draft.focused = focused(draft.focused as any, this.getGetActions()) as any;
            }
            else {
                draft.focused = focused as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }

        if (this.validateOn === ValidateOn.onBlur && this.state.focused !== prev.focused) {
            this.validate()
        }
    }
    
    /**
     * Get which form fields are focused.
     */
    public getFocused = () => {
        return this.state.focused;
    }

    /**
     * Set the errors state of the form.
     * @param errors The errors state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setErrors = (errors?: Record<string, TError[]> | IFormMutation<TFormValue, TError, Record<string, TError[]>> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (errors instanceof Function) {
                draft.errors = errors(draft.fields as any, this.getGetActions()) as any;
            }
            else {
                draft.errors = errors as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /*
     * Get whether the form is validating or not.
     */
    public getErrors = () => {
        return this.state.errors;
    }

    public getState = () => {
        return this.state;
    }

    public getFormGetActions = (): IFormGetActions<TFormValue, TError> => {
        return {
            getValue: this.getValue,
            getTouched: this.getTouched,
            getDirty: this.getDirty,
            getFocused: this.getFocused,
            getErrors: this.getErrors,
            getInitialValue: this.getInitialValue,
            getSubmitting: this.getSubmitting,
            getValidating: this.getValidating,
            getFields: this.getFields
        }
    }

    public getFormSetActions = (): IFormSetActions<TFormValue, TError> => {
        return {
            setValue: this.setValue,
            setTouched: this.setTouched,
            setDirty: this.setDirty,
            setFocused: this.setFocused,
            setErrors: this.setErrors,
            setValidating: this.setValidating,
            setSubmitting: this.setSubmitting,
            setFields: this.setFields
        }
    }

    public getFormSubscribeActions = (): IFormSubscribeActions<TFormValue, TError> => {
        return {
            subscribe: this.subscribeToForm,
            subscribeToField: this.subscribeToField
        }
    }

    public getFormProcessActions = (): IFormProcessActions<TFormValue, TError> => {
        return {
            reset: this.reset,
            submit: this.submit,
            validate: this.validate
        }
    }

    public getFieldGetActions = (): IFieldGetActions<TFormValue, TError> => {
        return {
            getFieldValue: this.getFieldValue,
            getFieldTouched: this.getFieldTouched,
            getFieldDirty: this.getFieldDirty,
            getFieldFocused: this.getFieldFocused,
            getFieldErrors: this.getFieldErrors,
            getFieldInitialValue: this.getFieldInitialValue,
        }
    }

    public getFieldSetActions = (): IFieldSetActions<TFormValue, TError> => {
        return {
            setFieldValue: this.setFieldValue,
            setFieldTouched: this.setFieldTouched,
            setFieldDirty: this.setFieldDirty,
            setFieldFocused: this.setFieldFocused,
            setFieldErrors: this.setFieldErrors,
        }
    }

    public getGetActions = (): IFormGetActions<TFormValue, TError> & IFieldGetActions<TFormValue, TError> => {
        return {
            ...this.getFormGetActions(),
            ...this.getFieldGetActions()
        }
    };

    public getActions = (): IFormActions<TFormValue, TError> => {
        return {
            ...this.getFormGetActions(),
            ...this.getFormSetActions(),
            ...this.getFormSubscribeActions(),
            ...this.getFormProcessActions(),
            ...this.getFieldGetActions(),
            ...this.getFieldSetActions(),
        }
    };

    
    /**
     * Get the initial value for the provided field.
     * @param propertyPath The property path of the field.
     */
     public getFieldInitialValue = <TFieldValue>(propertyPath: string): TFieldValue | null | undefined => {
        return get(this.state.initialValue, propertyPath);
    }

    /**
     * Set the error state of the provided field.
     * @param propertyPath The property path of the field.
     * @param touched The touched state.
     * @param notify Whether to notify the form of the changes (default: true).
     * @param updateDirty Whether to update the dirty state of the fields (default: true).
     * @param updateTouched Whether to update the touched state of the fields (default: false).
     * @param updateFocused Whether to update the focused state of the fields (default: false).
     */
     public setFieldValue = <TFieldValue>(propertyPath: string, value?: TFieldValue | IFormMutation<TFormValue, TError, TFieldValue> | null, notify?: boolean, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean ) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            let newValue: TFieldValue | null | undefined = undefined;

            if (value instanceof Function) {
                newValue = value(get(draft.value, propertyPath) as any, this.getGetActions());
            }
            else {
                newValue = value;
            }

            

            if (this.checkForClasses) {
                this.checkNoClassesUsed(newValue);
            }

            set(draft.value as any, propertyPath, newValue);
        });

        if (prev !== this.state) {
            this.updateFieldStates(prev, updateDirty, updateTouched, false);
        }

        if (updateFocused) {
            this.setFieldFocused(propertyPath, true, false);
        }

        if (notify !== false) {
            this.notify(prev);
        }

        if (this.validateOn === ValidateOn.onChange && this.state.value !== prev.value) {
            this.validate()
        }
    }

    
    /**
     * Get the value for the provided field.
     * @param propertyPath The property path of the field.
     */
    public getFieldValue = <TFieldValue>(propertyPath: string): TFieldValue | null | undefined => {
        return get(this.state.value, propertyPath);
    }

    
    /**
     * Set the error state of the provided field.
     * @param propertyPath The property path of the field.
     * @param touched The touched state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFieldErrors = (propertyPath: string, errors?: TError[] | IFormMutation<TFormValue, TError, TError[]> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            if (errors instanceof Function) {
                draft.errors[propertyPath] = errors(draft.touched[propertyPath] as any, this.getGetActions()) as any;
            }
            else {
                draft.errors[propertyPath] = errors as any;
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get the errors for the provided field.
     * @param propertyPath The property path of the field.
     */
    public getFieldErrors = (propertyPath: string) => {
        return this.state.errors[propertyPath];
    }


    
    /**
     * Set the touched state of the provided field.
     * @param propertyPath The property path of the field.
     * @param touched The touched state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFieldTouched = (propertyPath: string, touched?: boolean | IFormMutation<TFormValue, TError, boolean> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            let value: boolean | null | undefined = null;

            if (touched instanceof Function) {
                value = touched(draft.touched[propertyPath] as any, this.getGetActions()) as any;
            }
            else {
                value = touched as any;
            }
            
            if (draft.touched[propertyPath] !== value) {
                if (value) {
                    draft.touched = {
                        [propertyPath]: true
                    }
                }
                else {
                    delete draft.touched[propertyPath];
                }
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get whether the provided field is touched or not.
     * @param propertyPath The property path of the field.
     */
    public getFieldTouched = (propertyPath: string) => {
        return !!this.state.touched[propertyPath];
    }


    
    /**
     * Set the dirty state of the provided field.
     * @param propertyPath The property path of the field.
     * @param dirty The dirty state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFieldDirty = (propertyPath: string, dirty?: boolean | IFormMutation<TFormValue, TError, boolean> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            let value: boolean | null | undefined = null;

            if (dirty instanceof Function) {
                value = dirty(draft.dirty[propertyPath] as any, this.getGetActions()) as any;
            }
            else {
                value = dirty as any;
            }
            
            if (draft.dirty[propertyPath] !== value) {
                if (value) {
                    draft.dirty = {
                        [propertyPath]: true
                    }
                }
                else {
                    delete draft.dirty[propertyPath];
                }
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }
    }
    
    /**
     * Get whether the provided field is dirty or not.
     * @param propertyPath The property path of the field.
     */
    public getFieldDirty = (propertyPath: string) => {
        return !!this.state.dirty[propertyPath];
    }

    /**
     * Set the focused state of the provided field.
     * @param propertyPath The property path of the field.
     * @param focused The focused state.
     * @param notify Whether to notify the form of the changes (default: true).
     */
     public setFieldFocused = (propertyPath: string, focused?: boolean | IFormMutation<TFormValue, TError, boolean> | null, notify?: boolean) => {
        const prev = this.state;

        this.state = produce(this.state, draft => {
            let value: boolean | null | undefined = null;

            if (focused instanceof Function) {
                value = focused(draft.focused[propertyPath] as any, this.getGetActions()) as any;
            }
            else {
                value = focused as any;
            }
            

            if (draft.focused[propertyPath] !== value) {
                if (value) {
                    draft.focused = {
                        [propertyPath]: true
                    }
                }
                else {
                    delete draft.focused[propertyPath];
                }
            }
            else if (value === true && Object.keys(draft.focused).length !== 1) {
                draft.focused = {
                    [propertyPath]: true
                }
            }
        });

        if (notify !== false) {
            this.notify(prev);
        }

        if (this.validateOn === ValidateOn.onBlur && this.state.focused !== prev.focused) {
            this.validate()
        }
    }
    
    /**
     * Get whether the provided field is focused or not.
     * @param propertyPath The property path of the field.
     */
    public getFieldFocused = (propertyPath: string) => {
        return !!this.state.focused[propertyPath];
    }

    

    public getFieldState = <TFieldValue = any>(propertyPath: string): IFieldState<TFieldValue, TError> => {
        return {
            dirty: this.getFieldDirty(propertyPath),
            errors: this.getFieldErrors(propertyPath),
            focused: this.getFieldFocused(propertyPath),
            initialValue: this.getFieldInitialValue(propertyPath),
            touched: this.getFieldTouched(propertyPath),
            value: this.getFieldValue(propertyPath)
        };
    }

    /**
     * Notify all listeners of changes to the form.
     * @param prev The previous form state.
     */
    public notify = (prev: IFormState<TFormValue, TError>) => {
        if (prev === this.state) {
            return;
        }

        const changes: IFormSubscription = {
            initialValue: prev.initialValue !== this.state.initialValue,
            dirty: prev.dirty !== this.state.dirty,
            errors: prev.errors !== this.state.errors,
            fields: prev.fields !== this.state.fields,
            focused: prev.focused !== this.state.focused,
            submitting: prev.submitting !== this.state.submitting,
            touched: prev.touched !== this.state.touched,
            validating: prev.validating !== this.state.validating,
            value: prev.value !== this.state.value
        };

        this.notifyForm(changes);
        this.notifyFields(prev, changes);
    }

    /**
     * Notify all form listeners of changes to the form.
     * @param changes The parts of the form state with changes.
     */
    private notifyForm = (changes: IFormSubscription) => {
        /* Find all listeners that need to be notified */
        const listeners = this.formListeners.filter(l => this.requiresFormUpdate(changes, l.subscription));

        /* Early exit if no listeners to notify */
        if (listeners.length === 0) {
            return;
        }

        /* send new form state to listeners */
        listeners.forEach(l => {
            l.subscriber(this.state, this.getActions());
        });
    }

    /**
     * Notify all form listeners of changes to the form.
     * @param prev The previous form state.
     * @param changes The parts of the form state with changes.
     */
    private notifyFields = (prev: IFormState<TFormValue, TError>, changes: IFormSubscription) => {
        /* Find all listeners that need to be notified */
        const listeners = this.fieldListeners.filter(l => this.requiresFieldUpdate(changes, l.subscription));

        /* Early exit if no listeners to notify */
        if (listeners.length === 0) {
            return;
        }

        /* send new form state to listeners */
        listeners.forEach(l => {
            if (
                (l.subscription.value && get(this.state.value, l.propertyPath) !==  get(prev.value, l.propertyPath)) ||
                (l.subscription.initialValue && get(this.state.initialValue, l.propertyPath) !==  get(prev.initialValue, l.propertyPath)) ||
                (l.subscription.dirty && this.state.dirty[l.propertyPath] !==  prev.dirty[l.propertyPath]) ||
                (l.subscription.touched && this.state.touched[l.propertyPath] !==  prev.touched[l.propertyPath]) ||
                (l.subscription.focused && this.state.focused[l.propertyPath] !==  prev.focused[l.propertyPath]) ||
                (l.subscription.errors && this.state.errors[l.propertyPath] !==  prev.errors[l.propertyPath])
            ) {
                l.subscriber(this.getFieldState(l.propertyPath), this.getActions());
            }
        });
    }
    
    /**
     * Determine whether the form changes should cause an update in a form subscription.
     * @param changes The parts of the form state with changes.
     * @param subscription The subscription to the form.
     */
    private requiresFormUpdate = (changes: IFormSubscription, subscription: IFormSubscription) => {
        const keys = Object.keys(changes) as Array<keyof IFormSubscription>;

        return keys.reduce((a, b) => {
            if (a) {
                return a;
            }

            return changes[b] && subscription[b];
        }, false);
    }
    
    /**
     * Determine whether the form changes should cause an update in a field subscription.
     * @param changes The parts of the form state with changes.
     * @param subscription The subscription to the form.
     */
    private requiresFieldUpdate = (changes: IFormSubscription, subscription: IFieldSubscription) => {
        const keys = Object.keys(changes) as Array<keyof IFormSubscription>;

        return keys.reduce((a, b) => {
            if (a) {
                return a;
            }

            return changes[b] && (subscription as any)[b];
        }, false);
    };

    /**
     * Add a form listener to the form.
     * @param subscriber Event to run when notifying the listener.
     * @param subscription What changes should notify the listener.
     */
    public subscribeToForm = (subscriber: IFormSubscriber<TFormValue, TError>, subscription?: Partial<IFormSubscription> | null): IUnsubscribe => {
        const subscriptionToUse = {
            dirty: true,
            errors: true,
            fields: true,
            focused: true,
            initialValue: true,
            touched: true,
            value: true,
            submitting: true,
            validating: true
        };

        if (subscription) {
            const keys = Object.keys(subscriptionToUse) as Array<keyof IFormSubscription>;

            keys.forEach((key) => {
                subscriptionToUse[key] = !!subscription[key]
            });
        }

        /* Create a listener from the provided data */
        const listener: IFormListener<TFormValue, TError> = {
            subscriber,
            subscription: subscriptionToUse
        }

        /* register the listener */
        this.formListeners.push(listener);

        /* return unsubscribe function */
        return () => {
            this.formListeners = this.formListeners.filter(l => l !== listener);
        };
    }

    /**
     * Add a field listener to the form.
     * @param propertyPath The fields property path.
     * @param subscriber The event to run when notifying the listener.
     * @param subscription What changes should notify the listener.
     */
    public subscribeToField = <TFieldValue = any>(propertyPath: string, subscriber: IFieldSubscriber<TFieldValue, TFormValue, TError>, subscription?: Partial<IFieldSubscription> | null): IUnsubscribe => {
        const subscriptionToUse = {
            dirty: true,
            errors: true,
            focused: true,
            initialValue: true,
            touched: true,
            value: true
        };

        if (subscription) {
            const keys = Object.keys(subscriptionToUse) as Array<keyof IFieldSubscription>;

            keys.forEach((key) => {
                subscriptionToUse[key] = !!subscription[key]
            });
        }
        
        /* Create a listener from the provided data */
        const listener: IFieldListener<TFieldValue, TFormValue, TError> = {
            propertyPath,
            subscriber,
            subscription: subscriptionToUse 
        }

        /* register the listener */
        this.fieldListeners.push(listener);

        /* return unsubscribe function */
        return () => {
            this.fieldListeners = this.fieldListeners.filter(l => l !== listener);
        };
    }
    
    /**
     * Validate the form. 
     */
    public validate = async () => {
        /* set validating state to true and notify the form and fields */
        this.setValidating(true, true);

        /* if a validation function exists run the validation */
        if (this.onValidate) {
            const errors = await this.onValidate(this.getState(), this.getActions());

            this.setErrors(errors);

            this.setValidating(false, true);

            return;
        }

        /* if no validation function clear errors */
        this.setErrors({});

        this.setValidating(false, true);
    }

    /**
     * Mark all fields as touched.
     * 
     * This is used to ensure that all fields are marked as touched when submitting.
     */
    private setAllTouched = () => {
        this.setTouched((current) => {
            this.state.fields.forEach(path => {
                current[path] = true;
            });

            return current;
        });
    }

    /**
     * Submit the form.
     */
     public submit = async () => {
        /* Early exit for if form is already submitting */
        if (this.getSubmitting()) {
            return;
        }

        /* set form submitting state */
        this.setSubmitting(true, true);

        let continueSubmit = true;
        let validationFailure = undefined;

        try {
            /* validate the form */
            await this.validate();

            /* set all fields to touched */
            this.setAllTouched();

            /* check to see if submission is allowed based on form state */
            if (this.allowSubmit) {
                /* use provided allow submit function to determine if submission is allowed */
                continueSubmit = await this.allowSubmit(this.getState(), this.getActions());
            }
            else {
                /* use default "if there are no errors" to determine if submission is allowed */
                continueSubmit = !this.hasErrors();
            }
        }
        catch (error) {
            continueSubmit = false;
            validationFailure = error ?? true;

            if (isDevelopment) {
                console.error(error)
            }
        }

        /* Early exit for if validation failed. */
        if (!continueSubmit) {

            /* set form submitting state to false */
            this.setSubmitting(false, true);


            /* Run validation failed event (if provided). */
            if (this.onSubmitValidationFailed) {
                try {
                    const result = await this.onSubmitValidationFailed(this.getState(), this.getActions(), validationFailure);

                    if (result) {
                        this.setErrors(result);
                    }
                }
                catch (error) {

                    if (isDevelopment) {
                        console.error(error)
                    }

                }
            }

            return;
        }

        /* Run submission event (if provided) */
        if (this.onSubmit) {
            try {
                const result = await this.onSubmit(this.getState(), this.getActions());

                if (result) {
                    this.setErrors(result);
                }
            }
            catch (error) {

                if (isDevelopment) {
                    console.error(error)
                }

                if (this.onSubmitFailed) {
                    try {
                        const result = await this.onSubmitFailed(this.getState(), this.getActions(), error ?? true);

                        if (result) {
                            this.setErrors(result);
                        }
                    }
                    catch (error) {
                        if (isDevelopment) {
                            console.error(error)
                        }
                    }
                }
            }
        }

        this.setSubmitting(false, true);
    }

    private hasErrors = () => {
        const errorPaths = Object.keys(this.state.errors);

        return errorPaths.some(path => this.state.errors[path]?.length > 0);
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormManager;