import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormTypeSingleResponse, FormTypeGetSingleByCode } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';
import { Forms } from '../../Forms';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, code: string) => {
    const response = await forms.serviceStackClient.get(new FormTypeGetSingleByCode({ code }));

    return response;
}

const useFormTypeByCode = (code?: string, configuration?: SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<FormTypeSingleResponse, FormTypeSingleResponse>(code ? [`${forms.baseRoute}/forms-type/single/code`, forms, code] : null, fetcher, configuration);

    const boundMutate: BoundMutator<FormTypeSingleResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/forms-type/single/code`, forms, code], data, shouldRevalidate);
    }, [forms, code])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useFormTypeByCode;