import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import {  FormDefinitionsContext, FormErrorHandler, FormTypesContext, GroupedFields, IFormDefinition, IFormType, InputOnlyField, FormContext  } from '@ngt/forms';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import { IQuery, IQueryRecipient, MedicalReviewPermission, MedicalReviewStatus, QueryStatus } from '../../api/dtos';
import QueryGrid from './QueryGrid';
import QueryRecipientsContext from '../../contexts/configuration/QueryRecipientsContext';
import {  FormContext as FormCoreContext, FormErrorBoundaryMode, useScopedField } from '@ngt/forms-core';
import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import CountDisplay from '../form/input/display/CountDisplay';
import useContextPermissions from '../../hooks/utility/useContextPermissions';
import { makeStyles } from '../../styles/makeStyles';


interface IContextFormQueryGridProps {
    headerRows?: boolean;
    name: string;
    FieldProps?: {
        errorMode?: FormErrorBoundaryMode;
    };
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent'
    },
    summary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    subtitle: {
        padding: theme.spacing(0)
    },
    content: {
        display: 'block',
        padding: theme.spacing(0)
    }
}));

const subscription = { value: true };

const medicalReviewStatusSubscription = {};

const ContextFormQueryGrid: FunctionComponent<IContextFormQueryGridProps> = ({
    headerRows,
    name,
    FieldProps: { errorMode } = {}
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        container: {
            borderLeft: 'none',
            borderRight: 'none',
            backgroundColor: 'transparent !important'
        },
        summary: {
            '& > .MuiAccordionSummary-content': {
                justifyContent: 'space-between'
            },
            '&.Mui-expanded': {
                minHeight: 48,

                '& > div.MuiAccordionSummary-content': {
                    margin: '12px 0'
                }
            }
        },
        subtitle: {
            padding: theme.spacing(0)
        },
        content: {
            display: 'block',
            padding: theme.spacing(0)
        }
    }), [theme]);

    const { state: { value: queries }, context: { setValue: saveQueries } } = useScopedField<IQuery[]>(name, subscription);
    const { context: { setValue: saveStatus } } = useScopedField<MedicalReviewStatus>('medicalReviewStatus', medicalReviewStatusSubscription);
    const { data: queryRecipients } = useContext(QueryRecipientsContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);
    const { submit, reset } = useContext(FormCoreContext) ?? {}
    const { mutate } = useContext(FormContext) ?? {}

    const { data: [canView, canIssue, canRespond, canClose, canCancel] } = useContextPermissions([MedicalReviewPermission.ViewQuery, MedicalReviewPermission.IssueQuery, MedicalReviewPermission.RespondToQuery, MedicalReviewPermission.CloseQuery, MedicalReviewPermission.CancelQuery]);


    const onIssue = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {

        console.log(query);

        const newQueries = [...(queries ?? [])];

        if (relatedQuery) {
            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = { ...relatedQuery, status: QueryStatus.Closed };

            const newIndex = index + 1;
            if (newIndex < 0 || newIndex > newQueries.length - 1) {
                if(query){
                    newQueries.push(query);
                }
            }
            else {
                if(query){
                    newQueries.splice(newIndex, 0, query);
                }
            }
        }
        else {
            if(query){
                newQueries.push(query);
            }
        }

        saveQueries(newQueries);

        saveStatus(MedicalReviewStatus.InProgress);

        await submit?.();

    }, [queries, saveQueries, submit, saveStatus]);

    const onUpdate = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if (query) {
                newQueries[index] = query;
            }

            await saveQueries(newQueries);

            saveStatus(MedicalReviewStatus.InProgress);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);

    const onRespond = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if(query){
                newQueries[index] = query;
            }

            await saveQueries(newQueries);

            saveStatus(MedicalReviewStatus.InProgress);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);


    const onClose = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            if(query){
                newQueries[index] = query;
            }
            
            await saveQueries(newQueries);

            saveStatus(MedicalReviewStatus.InProgress);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);


    const onFailure = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery | null | undefined, recipient: IQueryRecipient, formType: IFormType, formDefinition: IFormDefinition) => {
        reset?.();
    }, [reset]);

    return (
        <FormErrorHandler
            errorMode={errorMode}
        >
            <GroupedFields>
                <Accordion sx={styles.container} elevation={0} square>
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={styles.summary}
                    >
                        <Typography variant="h2" className={classes.subtitle}>
                            Queries
                        </Typography>
                        <InputOnlyField
                            name={name}
                            component={CountDisplay}
                            entity="Query"
                        />
                    </AccordionSummary>
                    <AccordionDetails sx={styles.content}>
                        <QueryGrid
                            disabled={medicalReview?.status === MedicalReviewStatus.Cancelled || medicalReview?.status === MedicalReviewStatus.Complete}
                            onIssue={onIssue}
                            onUpdate={onUpdate}
                            onRespond={onRespond}
                            onClose={onClose}
                            onCancel={onClose}
                            medicalReview={medicalReview!}
                            queries={queries ?? []}
                            queryRecipients={queryRecipients ?? []}
                            formTypes={formTypes ?? []}
                            formDefinitions={formDefinitions ?? []}
                            headerRows={headerRows}
                            onFailure={onFailure}
                            hideSection
                            hideReport
                            allowInsert
                            canView={canView}
                            canIssue={canIssue}
                            canRespond={canRespond}
                            canClose={canClose}
                            canCancel={canCancel}
                        />
                    </AccordionDetails>
                </Accordion>
            </GroupedFields>
        </FormErrorHandler>
    )
};

export default ContextFormQueryGrid;
