/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected formDefinition.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass formDefinition context to the rest of the app.
 */
import FormDefinitionsContext from '../../../contexts/configuration/FormDefinitionsContext';

/*
 * Used to load master groups
 */
import useFormDefinitions from '../../../hooks/configuration/useFormDefinitions';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormDefinitionsResolverProps {
    resolveBeforeLoad?: boolean | null;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormDefinitionsResolver: FunctionComponent<IFormDefinitionsResolverProps> = ({
    resolveBeforeLoad,
    children
}) => {
    const context = useFormDefinitions();

    return (
        <FormDefinitionsContext.Provider value={context}>
            {
                !resolveBeforeLoad || !context?.loading ?
                    children :
                    <RouteLoading />
            }
        </FormDefinitionsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormDefinitionsResolver;