import { classNames } from '@servicestack/client';
import * as React from 'react';
import { FunctionComponent, ForwardRefExoticComponent } from 'react';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';

type NavLinkProps = Omit<RouterNavLinkProps, 'className'> & React.RefAttributes<HTMLAnchorElement>

export interface INavLink extends NavLinkProps {
    className?: string;
    activeClassName?: string;
    activeStyle?: React.CSSProperties;
}

const NavLink: FunctionComponent<INavLink> = ({className, activeClassName, activeStyle, ...props}) => {
    return (
        <RouterNavLink
            {...props}
            className={({ isActive }) => (isActive ? classNames(activeClassName, className) : className) ?? className}
        />
    );
}

export default NavLink;