import React, { FunctionComponent, useContext } from 'react';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import ReviewerContext from '../../contexts/data/ReviewerContext';
import PatientInformation from './PatientInformation';

interface IContextPatientInformationProps {
}



const ContextPatientInformation: FunctionComponent<IContextPatientInformationProps> = () => {

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: reviewer } = useContext(ReviewerContext);

    return (
        <PatientInformation
            patient={patient}
            institution={institution}
            reviewer={reviewer}
            medicalReview={medicalReview}
        />
    );
};

export default ContextPatientInformation;
