import * as React from 'react';

const useValueChangedLog = <T>(value: T,
    variableName: string) => {
    const [name] = React.useState(variableName);

    const [changeCount, setChangeCount] = React.useState(0);

    React.useEffect(() => {
        if (changeCount === 0) {
            console.group(`Value Change: ${name}`);
            console.log(`Initialised:`);

            if (value instanceof Array) {
                console.table(value);
            }
            else {
                console.log(value);
            }

            console.groupEnd()
        } else {
            console.group(`Value Change: ${name}`);
            console.log(`Change ${changeCount}:`);

            if (value instanceof Array) {
                console.table(value);
            }
            else {
                console.log(value);
            }

            console.groupEnd()
        }

        setChangeCount(changeCount + 1);
    }, [value]);
};

export default useValueChangedLog;