/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { FunctionComponent, ReactNode, useCallback, MouseEvent } from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import FieldProvider from '../../../FieldProvider';
import FormLabel from '../../../FormLabel';
import { Box, TableCell} from '@mui/material';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IGroupedSubformGridHeaderProps extends IGroupedSubformGridHeaderStyleProps {
    name: string;
    content?: ReactNode;
    className?: string;
    sortDirection?: SortDirection;
    updateSorting: (name: string, include: boolean) => void;
}

interface IGroupedSubformGridHeaderStyleProps {
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    canSort?: boolean;
}

export enum SortDirection {
    Ascending = 1,
    Descending = 2
}

type widths = string | number | undefined

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const GroupedSubformGridHeader: FunctionComponent<IGroupedSubformGridHeaderProps> = ({
    name,
    content,
    width,
    minWidth,
    maxWidth,
    className,
    sortDirection,
    canSort,
    updateSorting
}) => {

    const onClick = useCallback((e: MouseEvent) => {
        updateSorting(name, e.shiftKey)
    }, [updateSorting, name])

    return (
        <FieldProvider name={`[0].${name}`} autoRegister={false}>
            <TableCell
                sx={{
                    width: width ? width : undefined,
                    minWidth: minWidth ? minWidth : undefined,
                    maxWidth: maxWidth ? maxWidth : undefined,
                    cursor: canSort ? 'pointer' : undefined,
            
                    '& > *': {
                        fontWeight: 'bold'
                    }
                }}
                className={classNames({ [className ?? '']: !!className })} 
                onClick={canSort ? onClick : undefined}
            >
                <Box 
                    sx={{
                        display: 'flex'
                    }}
                >
                    {
                        content ?
                            content :
                            <FormLabel />
                    }
                    {
                        canSort && sortDirection === SortDirection.Ascending && (
                            <Box sx={{ml: 1}}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </Box>
                        )
                    }
                    {
                        canSort && sortDirection === SortDirection.Descending && (
                            <Box sx={{ml: 1}}>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Box>
                        )
                    }
                </Box>
            </TableCell>
        </FieldProvider>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default GroupedSubformGridHeader;
