/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams } from 'react-router-dom';
import { FormDefinitionCollectionResponse, FormTypeCollectionResponse } from '@ngt/forms';
import MedicalReviewByPatientStudyNumberAndRepeatResolver from '../resolver/MedicalReviewByPatientStudyNumberAndRepeatResolver';
import { SWRConfiguration } from 'swr/dist/types';
import { MedicalReviewDefinitionSingleResponse, MedicalReviewSingleResponse, MedicalReviewValidationCollectionResponse, ReviewerSingleResponse } from '../../api/dtos';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { useContext } from 'react';
import { CoordinatingGroupSingleResponse, CountrySingleResponse, InstitutionSingleResponse, MasterGroupSingleResponse, PatientSingleResponse, TrialsExtensionContext } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IMedicalReviewByPatientStudyNumberAndRepeatRouteProps {
    patientStudyNumber?: string;
    medicalReviewRepeat?: number;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewConfiguration?: Partial<SWRConfiguration<MedicalReviewSingleResponse, MedicalReviewSingleResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    reviewerConfiguration?: Partial<SWRConfiguration<ReviewerSingleResponse, ReviewerSingleResponse>>;
    medicalReviewDefinitionConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;
    medicalReviewFormValidationsConfiguration?: Partial<SWRConfiguration<MedicalReviewValidationCollectionResponse, MedicalReviewValidationCollectionResponse>>;

    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const MedicalReviewByPatientStudyNumberAndRepeatRoute: FunctionComponent<IMedicalReviewByPatientStudyNumberAndRepeatRouteProps> = ({
    patientStudyNumber,
    medicalReviewRepeat,
    resolveBeforeLoad,
    children,


    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionsConfiguration,
    medicalReviewConfiguration,
    medicalReviewDefinitionConfiguration,
    reviewerConfiguration,
    formTypesConfiguration,
    medicalReviewFormValidationsConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);
    const paramMedicalReviewRepeatStr = params[medicalReviewExtensionContext.routeParameters.medicalReviewRepeat];
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];

    let paramMedicalReviewRepeat = null;

    try {
        if (paramMedicalReviewRepeatStr) {
            paramMedicalReviewRepeat = parseInt(paramMedicalReviewRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid Form Repeat in route: ${paramMedicalReviewRepeatStr}`)
    }

    return (
        <MedicalReviewByPatientStudyNumberAndRepeatResolver
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? null}
            medicalReviewRepeat={paramMedicalReviewRepeat ?? medicalReviewRepeat ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formDefinitionsConfiguration={formDefinitionsConfiguration}
            formTypesConfiguration={formTypesConfiguration}
            institutionConfiguration={institutionConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            medicalReviewConfiguration={medicalReviewConfiguration}
            medicalReviewDefinitionConfiguration={medicalReviewDefinitionConfiguration}
            patientConfiguration={patientConfiguration}
            reviewerConfiguration={reviewerConfiguration}
            medicalReviewFormValidationsConfiguration={medicalReviewFormValidationsConfiguration}
        >
            {children}
        </MedicalReviewByPatientStudyNumberAndRepeatResolver>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewByPatientStudyNumberAndRepeatRoute;