/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, useMemo } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import CollapsibleTable from '../../table/CollapsibleTable';
import { GridColDef } from '@mui/x-data-grid';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormFocused component.
 */
export interface IFormFocusedProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: GridColDef[] = [
    {
        field: 'propertyName',
        headerName: 'Property Path',
        flex: 1
    },
    {
        field: 'focused',
        headerName: 'Focused',
        flex: 1
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormFocused: FunctionComponent<IFormFocusedProps> = () => {
    const { focused } = useFormState({ focused: true });

    const data = useMemo(() => {
        return Object.keys(focused).map((key, i) => ({ id: i,  propertyName: key, focused: focused[key] }))
    }, [focused])

    return (
        <CollapsibleTable
            title="Focused Fields"
            entityName="fields"
            loading={false}
            rows={data}
            columns={columns}
            autoHeight
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormFocused;