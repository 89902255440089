/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';

/*
 * Used to apply styles and page layout
 */
import { Container, Paper, AppBar, Grid, Button, Typography, Link, Toolbar, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, SwipeableDrawer, Theme, ThemeOptions, useTheme, SxProps, Box, darken } from '@mui/material';
import { makeStyles } from '../../styles/makeStyles';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for typings
 */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * Used for the logout icon.
 */
import { faSignOut } from '@fortawesome/pro-duotone-svg-icons/faSignOut';

/**
 * Used for the logout icon.
 */
import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons/faAddressCard';

/**
 * Used for the logout icon.
 */
import { faBars } from '@fortawesome/pro-solid-svg-icons';

/*
 * Used for conditional classes.
 */
import classNames from 'classnames';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IAuthSession } from '@servicestack/client';
import { useIsMobile, useUser, NavLink } from '@ngt/forms'
import TrialsExtensionContext from '../../contexts/TrialsExtensionContext';
import { useContext, useMemo } from 'react';
import LayoutStateContext from '../../contexts/layout/LayoutStateContext';
import { styled } from '@mui/system';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMenuItem {
    icon: IconDefinition;
    label: React.ReactNode;
    url: string;
    external: boolean;
    newTab: boolean;
}

/**
 * This interface defines the properties for the Header component.
 */
export interface IHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];
    children?: ReactNode;
}

interface IDesktopHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];

    user?: IAuthSession | null;

    trialName: string;
    organisationName: string;
    organisationLogo: string | null;
    children?: ReactNode;
}

interface IMobileHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];

    user?: IAuthSession | null;

    trialName: string;
    organisationName: string;
    organisationLogo: string | null;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useMobileStyles = makeStyles()((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navLinkActive: {
        background: theme.palette.grey[200]
    },
    infoContainer: {
        padding: theme.spacing(3)
    },
    logo: {
        maxWidth: 240
    }
}));


const useDesktopStyles = makeStyles()((theme: Theme) => ({
    container: {
        padding: theme.spacing(3),
        maxWidth: '1280px !important'
    },
    logo: {
        maxWidth: '100%',
        height: 'auto'
    },
    rightColumn: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    displayName: {
        fontWeight: 'bold'
    },
    navigationBar: {
        minHeight: 5
    },
    navLink: {
        borderRadius: `0 !important`,
        textTransform: 'none',
        fontWeight: 'normal !important'
    },
    navLinkActive: {
        background: `${theme.palette.primary.light} !important`,
        padding: '8px 11px',
        color: 'inherit',
        textDecoration: 'none',

        '&:hover': {
            background: `${darken(theme.palette.primary.main, 0.04)} !important`,
        }
    },
    border: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: theme.spacing(1),
        borderBottomStyle: 'solid'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const Img = styled('img')();

const MobileHeader: FunctionComponent<IMobileHeaderProps> = ({
    organisationLogo,
    organisationName,
    trialName,
    children,
    menuItems,
    user
}) => {
    const {classes} = useMobileStyles();
    
    const theme = useTheme();

    const mobilestyles = useMemo(() => {
        return {
            margin: {
                marginLeft: theme.spacing(2)
            } as SxProps
        } 
    }, [theme]);

    const [open, setOpen] = useState(false);


    const onOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen])

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={onOpen}
                    >
                        <FontAwesomeIcon fixedWidth icon={faBars} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        variantMapping={{
                            h6: 'span'
                        }}
                    >
                        {trialName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={open}
                onClose={onClose}
                onOpen={onOpen}
            >
                <div
                    className={classes.infoContainer}
                >
                    {
                        !!organisationLogo ?
                            <img
                                className={classes.logo}
                                src={organisationLogo}
                                alt={organisationName}
                            /> :
                            <Typography
                                variant="h6"
                                className={classes.title}
                                variantMapping={{
                                    h6: 'span'
                                }}
                            >
                                {organisationName}
                            </Typography>

                    }
                </div>
                <Divider />
                <List
                    disablePadding
                >
                    <ListItem
                        component="a"
                        color="inherit"
                    //className={classes.navLink}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faAddressCard} fixedWidth />
                        </ListItemIcon>
                        <ListItemText
                            primary={user?.displayName}
                        />
                    </ListItem>
                    <Divider />
                    {
                        !!menuItems && menuItems.map((menuItem, i) => {

                            if (menuItem.external || menuItem.newTab) {
                                return (
                                    <React.Fragment
                                        key={i}
                                    >
                                        <ListItem
                                            component="a"
                                            button
                                            href={menuItem.url}
                                            color="inherit"
                                            target={menuItem.newTab ? "_blank" : undefined}
                                        //className={classes.navLink}
                                        >
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={menuItem.label}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment
                                    key={i}
                                >
                                    <ListItem
                                        component={NavLink}
                                        button
                                        to={menuItem.url}
                                        color="inherit"
                                        //className={classes.navLink}
                                        activeClassName={classes.navLinkActive}
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={menuItem.label}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })
                    }
                    <ListItem
                        component="a"
                        button
                        href="/auth/logout"
                        color="inherit"
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOut} fixedWidth />
                        </ListItemIcon>
                        <ListItemText
                            primary="Logout"
                        />
                    </ListItem>
                    <Divider />
                </List>
            </SwipeableDrawer>
        </>
    );
}

const DesktopHeader: React.FunctionComponent<IDesktopHeaderProps> = ({
    organisationLogo,
    organisationName,
    trialName,
    children,
    menuItems,
    user
}) => {
    const { fullWidth } = useContext(LayoutStateContext)
    const {classes} = useDesktopStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            container: {
                padding: theme.spacing(3),
                maxWidth: !fullWidth ? '1280px !important' : '100%'
            } as SxProps,
            logo: {
                maxWidth: '100%',
                height: 'auto'
            } as SxProps,
            rightColumn: {
                textAlign: 'right',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between'
            } as SxProps,
            title: {
                color: theme.palette.primary.main,
                fontWeight: 'bold'
            } as SxProps,
            displayName: {
                fontWeight: 'bold'
            } as SxProps,
            navigationBar: {
                minHeight: `5px !important`
            } as SxProps,
            navLink: {
                borderRadius: 0,
                textTransform: 'none',
                fontWeight: 'normal'
            } as SxProps,
            navLinkActive: {
                background: theme.palette.primary.light
            } as SxProps,
            border: {
                borderBottomColor: theme.palette.primary.main,
                borderBottomWidth: theme.spacing(1),
                borderBottomStyle: 'solid'
            } as SxProps
        } 
    }, [theme, fullWidth]);

    let header = (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
            >
                {
                    !!organisationLogo ?
                        <Img
                            sx={styles.logo}
                            src={organisationLogo}
                            alt={organisationName}
                        /> :
                        <Typography
                            variant="h1"
                            sx={styles.title}
                            variantMapping={{
                                h1: 'span'
                            }}
                        >
                            {organisationName}
                        </Typography>
                }
            </Grid>
            <Grid
                sx={{
                    textAlign: 'right',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between'
                }}
                item
                xs={12}
                md={8}
            >
                <Typography
                    variant="h1"
                    sx={styles.title}
                    variantMapping={{
                        h1: 'span'
                    }}
                >
                    {trialName}
                </Typography>
                <Typography
                    sx={styles.displayName}
                >
                    {user?.displayName}
                </Typography>
                {
                    !!user && (
                        <Link
                            component="a"
                            href="/auth/logout"
                            sx={{
                                textDecoration: 'none',
                                fontSize: '0.875rem',
                                '&:hover':{
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Logout <FontAwesomeIcon icon={faSignOut} fixedWidth />
                        </Link>
                    )
                }
            </Grid>
        </Grid>
    );

    let navigation = (
        <>
            {
                menuItems?.map((menuItem, i) => {

                    if (menuItem.external || menuItem.newTab) {
                        return (
                            <Button
                                key={i}
                                component="a"
                                href={menuItem.url}
                                color="inherit"
                                target={menuItem.newTab ? "_blank" : undefined}
                                sx={styles.navLink}
                                startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                                size="large"
                            >
                                {menuItem.label}
                            </Button>
                        );
                    }

                    return (
                        <Button
                            key={i}
                            component={NavLink}
                            to={menuItem.url}
                            color="inherit"
                            sx={styles.navLink}
                            startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                            size="large"
                            activeClassName={classes.navLinkActive}
                        >
                            {menuItem.label}
                        </Button>
                    );
                })
            }
        </>
    );

    if (fullWidth) {
        header = (
            <Box
                sx={styles.container}
            >
                {header}
            </Box>
        );
    }
    else {
        header = (
            <Container
                sx={styles.container}
                disableGutters
            >
                {header}
            </Container>
        );

        navigation = (
            <Container
                sx={{          
                    maxWidth: '1280px !important'
                }}
                disableGutters
            >
                {navigation}
            </Container>
        )
    }

    return (
        <AppBar
            position="static"
        >
            <Paper
                elevation={0}
                square
                sx={menuItems ? undefined : styles.border}
            >
                {header}
            </Paper>
            {
                !!menuItems && (
                    <Toolbar
                        sx={styles.navigationBar}
                        disableGutters
                    >
                        {navigation}
                    </Toolbar>
                )
            }
        </AppBar>
    )
};

/**
 * This component provides the basic layout for the patient management system.
 * @param param0 component properties.
 */
const Header: React.FunctionComponent<IHeaderProps> = ({
    menuItems,
    children
}) => {
    const user = useUser();

    const { trialName, organisationName, organisationLogo } = React.useContext(TrialsExtensionContext)

    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <MobileHeader
                organisationLogo={organisationLogo ?? null}
                organisationName={organisationName ?? 'Unknown Organisation'}
                trialName={trialName ?? 'Unknown Trial'}
                menuItems={menuItems}
                user={user}
            />
        );
    }

    return (
        <DesktopHeader
            organisationLogo={organisationLogo ?? null}
            organisationName={organisationName ?? 'Unknown Organisation'}
            trialName={trialName ?? 'Unknown Trial'}
            menuItems={menuItems}
            user={user}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Header;