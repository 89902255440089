import { IGroupedFieldProps as IBaseGroupedFieldProps, GroupedField as BaseGroupedField } from '@ngt/forms';
import { InputComponentType, OmitInputRender } from '@ngt/forms-core';
import UpdatedFormPropertyStyler, { IUpdatedFormPropertyStyler } from '../../UpdatedFormPropertyStyler';
import * as React from 'react';

export interface IGroupedFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TForm extends object = any
    > extends IBaseGroupedFieldProps<TComponent, TValue, TForm> {
    UpdatedFormPropertyStylerProps?: IUpdatedFormPropertyStyler;
}

const GroupedField = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    WrappingComponent,
    UpdatedFormPropertyStylerProps,
    ...props
}: IGroupedFieldProps<TComponent, TValue> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {

    const component = React.useCallback(({ children }: any) => {
        let content = children;

        if (WrappingComponent) {
            content = <WrappingComponent>
                {content}
            </WrappingComponent>
        }

        return <UpdatedFormPropertyStyler {...UpdatedFormPropertyStylerProps}>
            {content}
        </UpdatedFormPropertyStyler>

    }, [UpdatedFormPropertyStylerProps, WrappingComponent])


    return (
        <BaseGroupedField {...(props as any)} WrappingComponent={component} />
    );
};

export default GroupedField;