/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { IFieldState, IFieldSubscription, IFormMutation } from '../FormManager';

import useScopedField from './useScopedField';

import { IInputActions } from '../components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useScopedInput = <TFieldValue = any, TFormValue extends object = any, TError = any, TInputValue = any>(path: string, subscription?: Partial<IFieldSubscription>, autoRegister?: boolean, parse?: (inputValue: TInputValue | null | undefined) => TFieldValue | null | undefined, convert?: (fieldValue: TFieldValue | null | undefined) => TInputValue | null | undefined): { state: IFieldState<TFieldValue, TError>, actions: IInputActions<TFieldValue, TFormValue, TError>} => {
    const scopedField = useScopedField<TFieldValue, TFormValue, TError>(path, subscription, autoRegister);

    const actions: IInputActions<TFieldValue, TFormValue, TError> = React.useMemo(() => {
        return {
            blur: () => {
                scopedField.context.setFocused(false);
            },
            clean: () => {
                scopedField.context.setDirty(false);
            },
            dirty: () => {
                scopedField.context.setDirty(true);
            },
            focus: () => {
                scopedField.context.setFocused(true);
            },
            touch: () => {
                scopedField.context.setTouched(true);
            },
            untouch: () => {
                scopedField.context.setTouched(false);
            },
            update: (newValue?: TFieldValue | IFormMutation<TFormValue, TError, TFieldValue> | null, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean) => {
                if (parse) {
                    if (newValue === undefined) {
                        scopedField.context.setValue(parse(newValue as any), updateDirty, updateTouched, updateFocused);
                    }
                    else if (newValue instanceof Function) {
                        scopedField.context.setValue((draft, formGetActions) => { return parse(newValue(draft, formGetActions) as any) }, updateDirty, updateTouched, updateFocused);
                    }
                    else {
                        scopedField.context.setValue(parse(newValue as any), updateDirty, updateTouched, updateFocused);
                    }
                }
                else {
                    scopedField.context.setValue(newValue, updateDirty, updateTouched, updateFocused);
                }
            }
        }
    }, [scopedField.context.setValue, scopedField.context.setFocused, scopedField.context.setTouched, scopedField.context.setDirty, parse, convert]);

    const scopedInput = React.useMemo(() => { 
        const state: IFieldState<TFieldValue, TError> = convert ?
            { ...scopedField.state, value: convert(scopedField.state.value) } as any :
            scopedField.state;

        return {
            state,
            actions
        };
    }, [scopedField.state, actions])

    return scopedInput;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScopedInput;