/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */
/*
 * --------------------------------------------------------------------------------
 * This file contains the table component
 * --------------------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
/**
 * React
 */
import * as React from 'react';
import { DataGrid, DataGridProps, GridCallbackDetails } from '@mui/x-data-grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ITableProps<Type extends object = any> extends Omit<DataGridProps, 'rows'> {
    rows: Type[];
    initialPageSize?: number;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */
const Table = <Type extends object>({
    rowsPerPageOptions,
    pageSize,
    onPageSizeChange,
    initialPageSize,
    paginationMode,
    ...props
}: ITableProps<Type>) => {
    const [pSize, setPSize] = React.useState<number | undefined>();
    React.useEffect(() => {
        if (pageSize) {
            setPSize(pageSize);
        }
        else if (rowsPerPageOptions != null && rowsPerPageOptions.length > 0) {
            setPSize(currentPSize => {
                if (currentPSize && rowsPerPageOptions?.includes(currentPSize)) {
                    return currentPSize;
                }
                if (initialPageSize !== undefined && rowsPerPageOptions?.includes(initialPageSize)) {
                    return initialPageSize;
                }
                return rowsPerPageOptions[0];
            })
        }
        else {
            setPSize(undefined);
        }
    }, [pageSize, rowsPerPageOptions, setPSize, initialPageSize]);
    const onPSizeChange = React.useCallback((pageSize: number, details: GridCallbackDetails) => {
        onPageSizeChange?.(pageSize, details)
        setPSize(pageSize);
    }, [setPSize, onPageSizeChange])
    return (
        <DataGrid
            {...props}
            rowsPerPageOptions={rowsPerPageOptions ?? [25, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={paginationMode === "server" ? onPSizeChange : onPageSizeChange}
            paginationMode={paginationMode}
        />
    );
};
/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default Table;